import { batch } from "@preact/signals-react";
import { MempoolPricePercentile } from "sections/market/types";
import { lastMempoolPricePercentileDataSignal } from "signals/lastMempoolPricePercentileSignals";

const messageHandler = (data: MempoolPricePercentile) => {
    batch(() => {
        lastMempoolPricePercentileDataSignal.value = data as MempoolPricePercentile;
      })
}

const reset = () => {}

export default {
    messageHandler,
    reset
}