import { RootState } from 'state/store'

import { unserializeGasPrice } from './helpers'
import { createSelector } from '@reduxjs/toolkit'

export const selectShowModal = (state: RootState) => state.app.showModal
export const selectShowPositionModal = (state: RootState) => state.app.showPositionModal

export const selectGasSpeed = (state: RootState) => state.app.gasSpeed

export const selectGasPrice = (state: RootState) => unserializeGasPrice(state.app.gasPrice)

export const selectTransaction = (state: RootState) => state.app.transaction

export const selectAckedOrdersWarning = (state: RootState) => state.app.acknowledgedOrdersWarning

export const selectShowBanner = (state: RootState) => state.app.showBanner

type TransactionStatus = {
    status: string;
    type: string;
    error?: string;
}

export const selectTransactionError = createSelector(
	(state: RootState) => state.app.transaction,
	(transaction) => {
		if (transaction?.status === "Failed") {
            const { status, type, error } = transaction
            const transactionStatus: TransactionStatus = {
                status,
                type
            }
            const regex = /reason="([^"]+)"/;
            const match = error?.match(regex);

            if (match) {
                transactionStatus.error = match[1]
            } else {
                transactionStatus.error = error
            }

            return transactionStatus
        } else {
            return null
        }
    }
)
