import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from 'state/types'
import logError from 'utils/logError'
import { notifyError } from 'components/ErrorNotifier'
import { AppThunk } from 'state/store'
import { Candle, ContractTransactionsData, CumulativeGasSpendChartData, GasSpentTableData, TopContractsChartData, TopTransactionTypesChart, UserInfo } from './types'
import axios from 'axios'
import { PERIOD_IN_SECONDS, TIME_RANGE_OPTIONS as DEFAULT_OPTIONS, Period } from 'constants/dashboard'
import differenceInDays from 'date-fns/differenceInDays'
import { ethers } from 'ethers';
import { reduce, forEach, isEmpty, pick } from 'lodash'
import { staticMainnetProvider } from 'utils/network'
import { truncateAddress } from '@kwenta/sdk/utils'
import { isAddress } from 'ethers/lib/utils'
import { normalizeAddressForApi } from 'state/explorer/actions'
import { generateQueryText } from 'utils/queries'

const TIME_RANGE_OPTIONS = Object.values(pick(DEFAULT_OPTIONS, [Period.ONE_WEEK, Period.ONE_MONTH, Period.SIX_MONTHS, Period.ONE_YEAR]))

type DailyTransactionDetails = {
    gas_used: number,
    avg_gas_price: number,
    gas_spent_eth: number,
    gas_spent_usd: number,
    gas_price_sum: number
    txn_count: number,
    to_date: string,
    from_date: string
}

type DailyTransactionsResponse = {
    total_gas_spent_eth: number,
    total_gas_spent_usd: number,
    avg_gas_spent_eth: number,
    avg_gas_spent_usd: number,
    avg_gas_price: number,
    total_txn_no: number,
    total_gas_used: number,
    eth_perc_on_gas: number,
    txn_details: DailyTransactionDetails[]
    overspend_gwei: number
    overspend_usd: number
}

type ContractTransactionsResponse = {
    tx_fee_native: string,
    avg_gas_price_gwei: string,
    gas_used: string,
    bucket_timestamp: string,
    to_address_hash: string,
    address: string | null,
    name: string | null,
    latest_name: string | null,
}

type RequestObject = {
    query_bucket: string,
    query_interval: string,
    query_interval_length: string,
    wallet_addr?: string
}

type UserTxnsRequestObject = {
    query_bucket: string,
    query_duration: string,
    wallet_addr?: string
}

type MarketPriceData = {
    time: string,
    p_max_gas_price: number,
    p_min_gas_price: number,
    p_ninety_five_max_gas_price: number
    max_gas_price: number,
    ninety_five_max_gas_price: number
    seventy_five_gas_price: number,
    median_gas_price: number,
    twenty_five_gas_price: number,
    five_min_gas_price: number,
    min_gas_price: number,
    bucket_gas_used: number,
    std_gas_price: number,
    avg_gas_price: number
}

type TopContractsData = {
    block_period: string,
    gas_price_gwei: number,
    gas_price_usd: number,
    gas_used: number,
    tx_fee_native: number,
    tx_fee_usd: number,
    tx_made: number,
    tx_receiver: string,
    wallet_name: string
}

type TopTransactionTypeData = {
    block_period: string,
    four_bytes: string,
    gas_price_gwei: number,
    gas_price_usd: number,
    gas_used: number,
    is_native_txn: boolean,
    tx_fee_native: number,
    tx_fee_usd: number,
    tx_made: number,
    tx_type: string
}



// type UserInfo = {
//     avgGasSpent: number,
//     totalGasSpent: number,
//     transactionCount: number,
//     avgGasPrice: number
// }

type DashboardChartData = {
    marketData: Candle[],
}

const getENSName = async (address: string) => {
    if (address === 'others') return address
    const ensName = await staticMainnetProvider.lookupAddress(address)
    return ensName ?? address !== 'others' ? truncateAddress(address) : address
}

const timeRangeToPeriodMap: Record<number, string> = {
    [PERIOD_IN_SECONDS.ONE_WEEK]: 'week',
    [PERIOD_IN_SECONDS.ONE_MONTH]: 'month',
    [PERIOD_IN_SECONDS.SIX_MONTHS]: 'year',
    [PERIOD_IN_SECONDS.ONE_YEAR]: 'year',
}

const getDaysTillToday = (data: Candle[] | CumulativeGasSpendChartData[]) => {
    if (data.length === 0) return 0
    const date1 = new Date(data.at(-1)!?.timestamp);
	const date2 = new Date();
    const difference = differenceInDays(date2, date1);

    return difference - 1
}

export const fetchCumulativeGasSpendData = createAsyncThunk<
    {
        walletAddress: string,
        userInfo: UserInfo | null,
        dailyTransactionDetails: CumulativeGasSpendChartData[],
    },
    {
        selectedDashboardTimeRange: number,
        address: string
    },
    ThunkConfig
>('dashboard/fetchCumulativeGasSpendData', async ({ selectedDashboardTimeRange, address}) => {
    try {
        let requestObject: UserTxnsRequestObject = {} as UserTxnsRequestObject
        const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === selectedDashboardTimeRange)

        requestObject = {
            query_bucket: 'day',
            query_duration: timeRangeObj?.requestValue ?? '1w',
            wallet_addr: address.replace('0x', '')
        }
        const response = await axios.get<DailyTransactionsResponse>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/wallet/get-user-txns?graph-interval=${requestObject.query_bucket}&query-duration=${requestObject.query_duration}&wallet-addr=${requestObject.wallet_addr}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data: DailyTransactionsResponse = response.data
        const requestData = data?.txn_details?.length > 0 ? data.txn_details : []

        const userInfo = {
            avgGasSpentEth: data.avg_gas_spent_eth,
            avgGasSpentUsd: data.avg_gas_spent_usd,
            totalGasSpentEth: data.total_gas_spent_eth,
            totalGasSpentUsd: data.total_gas_spent_usd,
            transactionCount: data.total_txn_no,
            totalGasUsed: data.total_gas_used,
            percentageOnGas: data.eth_perc_on_gas,
            avgGasPrice: data.avg_gas_price / 1e9,
            overspendGwei: data.overspend_gwei,
            overspendUsd: data.overspend_usd
        }

        const dailyTransactionDetails = requestData.map(dailyData => {
            return {
                timestamp: dailyData.from_date,
                gasSpendEth: dailyData.gas_spent_eth,
                gasSpendUsd: dailyData.gas_spent_usd,
                transactionCount: dailyData.txn_count,
                gasUsed: dailyData.gas_used,
                avgGasPrice: dailyData.avg_gas_price / 1e9
            }
        })

        return {
            walletAddress: address ?? '',
            userInfo,
            dailyTransactionDetails: dailyTransactionDetails ?? [] as CumulativeGasSpendChartData[]
        }
    } catch (err) {
        console.log('Fetching table data unsuccessful', err)
        return {
            walletAddress: address ?? '',
            userInfo: null,
            dailyTransactionDetails: [] as CumulativeGasSpendChartData[],
        }
        // logError(err)
        // notifyError('Failed to fetch chart data', err)
        // throw err
    } 
})

export const fetchContractTransactionsData = createAsyncThunk<
{
    walletAddress: string,
    gasSpentTable: GasSpentTableData,
    protocolBreakdown: ContractTransactionsData,
    topProtocolNames: string[]
},
{
    selectedDashboardTimeRange: number,
    address: string
},
ThunkConfig
>('dashboard/fetchContractTransactionsData', async ({ selectedDashboardTimeRange, address}) => {
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === selectedDashboardTimeRange)
    const requestObject = {
        "query-duration": timeRangeObj?.requestValue ?? '1w',
       "wallet-addr": normalizeAddressForApi(address),
       limit: 100
    }

    try {
        const response = await axios.get<any>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/wallet/get-gas-spend-table?${generateQueryText(requestObject)}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let gasSpentTable = {}

        if (response.data.gas_spend_table) {
            gasSpentTable = reduce(response.data.gas_spend_table, (acc, value, key) => {
                const name = isAddress(`${key}`) ? truncateAddress(key) : key
                return {
                    ...acc,
                    [name]: value
                }
            }, {})
        }
        let protocolBreakdown = {}
        let topProtocolNames: string[] = []
        if (response.data.daily_graph) {
            const dailyGraph = response.data.daily_graph
            const protocolMaping = reduce(dailyGraph, (acc: { [key: string]: number}, value: { [key: string]: number}, key: string) => {
                const dayData = {...value}
                const totalGasUnit = dayData.total_gas_units
                delete dayData.total_gas_units
                forEach(dayData, (percentage, protocol) => {
                    const gasUnit = percentage * totalGasUnit
                    if (acc[protocol]) {
                        acc[protocol] += gasUnit
                    } else {
                        acc[protocol] = gasUnit
                    }
                })
                return acc
            }, {})
            const list: any = []
            Object.keys(protocolMaping).forEach(key => {
                list.push({ name: key, value: protocolMaping[key] })
            })
            list.sort((a: any, b: any) => b.value - a.value)
            const topProtocols = list.slice(0, 5)
            topProtocolNames = topProtocols.map((prot: any) => prot.name)

            protocolBreakdown = reduce(dailyGraph, (acc: { [key: string]: any}, value: { [key: string]: number}, key: string) => {
                let topSum = 0
                // const timestamp = new Date(key).valueOf()
                acc[key] = {}
                topProtocols.forEach((prot: any) => {
                    if (value[prot.name]) {
                        topSum += value[prot.name]
                        acc[key][prot.name] = value[prot.name]
                    }
                })
                if (topProtocolNames.includes('others')) {
                    acc[key]["others"] = (acc[key]["others"] ?? 0) + (1 - topSum)
                } else if (list.length > 5) {
                    acc[key]["Others"] = 1 - topSum
                    if (!topProtocolNames.includes('Others')) topProtocolNames.push('Others')
                } 
                return acc
            }, {})
        }

        return {
            walletAddress: address,
            protocolBreakdown,
            topProtocolNames,
            gasSpentTable
        }
    } catch (err) {
        console.log('Fetching table data unsuccessful', err)
        return {
            walletAddress: address,
            gasSpentTable: {},
            protocolBreakdown: {},
            topProtocolNames: [],
        }
    } 
})

const fetchMarketPriceData = async (selectedDashboardTimeRange: number) => {

    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === selectedDashboardTimeRange)

    if (!timeRangeObj) return []

    const requestObject = {
        "graph-interval": 'day',
        "query-duration": timeRangeObj?.requestValue ?? '1w',
    }

    const response = await axios.get<MarketPriceData[]>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/market/get-market-gas-prices?graph-interval=${requestObject["graph-interval"]}&query-duration=${requestObject["query-duration"]}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const data: MarketPriceData[] = response.data
    if (data) {
        const responseData = data.map(dailyData => {
            return {
                timestamp: dailyData.time.split('T')[0],
                low: dailyData.min_gas_price,
                high: dailyData.ninety_five_max_gas_price,
                open: dailyData.twenty_five_gas_price,
                close: dailyData.seventy_five_gas_price,
                avg: dailyData.avg_gas_price,
                min: dailyData.min_gas_price,
                max: dailyData.max_gas_price,
                stdGasPrice: dailyData.std_gas_price
            }
        })

        const referenceDate = new Date()
        const resp =  responseData.filter(d => {
            const currentDate = new Date(d.timestamp)
            return differenceInDays(referenceDate, currentDate) > 0
        }).sort((a,b) => new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf())
        return resp
    }

    return []
}

export const fetchDashboardData = (selectedDashboardTimeRange: number, address: string/*, isUserWallet: boolean = false*/): AppThunk => (
    dispatch,
	getState
) => {
    if (selectedDashboardTimeRange === Number.POSITIVE_INFINITY) {
        selectedDashboardTimeRange = PERIOD_IN_SECONDS.ONE_YEAR
    }
    const userData = getState().dashboard.userData[address]
    dispatch(fetchMarketData({ selectedDashboardTimeRange }))
    dispatch(fetchCumulativeGasSpendData({ selectedDashboardTimeRange, address }))
    dispatch(fetchContractTransactionsData({ selectedDashboardTimeRange, address }))
    
}

export const fetchMarketData = createAsyncThunk<
    DashboardChartData | undefined,
    {selectedDashboardTimeRange: number, address?: string},
    ThunkConfig
>('dashboard/fetchMarketData', async ({selectedDashboardTimeRange}, { getState, dispatch }) => {
    const timeRangeDays = selectedDashboardTimeRange / (60 * 60 * 24)
    try {
        const marketData = await fetchMarketPriceData(selectedDashboardTimeRange)
        return {
            marketData
        }
    } catch (err) {
        logError(err)
        notifyError('Failed to fetch chart data', err)
        throw err
    }
})

// export const fetchUserData = createAsyncThunk<
//     {
//         walletAddress: string,
//         userInfo: UserInfo | null,
//         dailyTransactionDetails: CumulativeGasSpendChartData[],
//         gasSpentTable: GasSpentTableData,
//         protocolBreakdown: ContractTransactionsData,
//         topProtocolNames: string[]
//     },
//     {
//         selectedDashboardTimeRange: number,
//         address: string},
//     ThunkConfig
// >('dashboard/fetchUserData', async ({ selectedDashboardTimeRange, address}, { getState, dispatch }) => {
//     try {
//         const [cumulativeGasSpendData, contractTransactionsData ] = await Promise.all([
//             fetchCumulativeGasSpendData({selectedDashboardTimeRange, address}),
//             fetchContractTransactionsData(selectedDashboardTimeRange, address)
//         ])

//         return {
//             walletAddress: address,
//             ...cumulativeGasSpendData,
//             ...contractTransactionsData
//         }
//     } catch (err) {
//         console.log('Fetching table data unsuccessful', err)
//         return {
//             walletAddress: address,
//             userInfo: null,
//             dailyTransactionDetails: [],
//             gasSpentTable: {},
//             protocolBreakdown: {},
//             topProtocolNames: [],
//         }
//     } 
// })
// export const fetchBlockProtocolStat = createAsyncThunk<
//     {
        
//     },
//     {
//         selectedDashboardTimeRange: number,
//         address: string},
//     ThunkConfig
// >('dashboard/fetchUserData', async ({ selectedDashboardTimeRange, address}, { getState, dispatch }) => {
//     const timeRangeDays = selectedDashboardTimeRange / (60 * 60 * 24)
//     try {
//         const [cumulativeGasSpendData, contractTransactionsData ] = await Promise.all([
//             fetchCumulativeGasSpendData(timeRangeDays, address),
//             fetchContractTransactionsData(selectedDashboardTimeRange, address)
//         ])

//         return {
//             walletAddress: address,
//             ...cumulativeGasSpendData,
//             ...contractTransactionsData
//         }
//     } catch (err) {
//         console.log('Fetching table data unsuccessful', err)
//         return {
//             walletAddress: address,
//             userInfo: null,
//             dailyTransactionDetails: [],
//             gasSpentTable: {},
//             protocolBreakdown: {},
//             topProtocolNames: [],
//         }
//     } 
// })

export const fetchCurrentGasPrice = createAsyncThunk<
	number,
	boolean | undefined,
	ThunkConfig
>('prices/fetchCurrentGasPrice', async (_) => {
	try {
        const gasPrice = await staticMainnetProvider.getGasPrice();
		return Number(ethers.utils.formatUnits(gasPrice, 'gwei'))
	} catch (err) {
		console.error('Failed to fetch historical prices', err)
		throw err
	}
})
