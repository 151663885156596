import { formatsETH, truncateString } from '@kwenta/sdk/utils'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import styled, { useTheme } from 'styled-components'
import { useAppSelector } from 'state/hooks'
import {
	selectMarketInfo
} from 'state/futures/selectors'

import ErrorIcon from 'assets/svg/app/error.svg'
import Wei from '@synthetixio/wei'

const ErrorReasonRegex = /\(reason="(.*?)",/;

function ToastContent({ message, errorDetails }: { message: string; errorDetails?: string }) {
	const [expanded, setExpanded] = useState(false)
	if (!errorDetails) return <>{message}</>
	return (
		<div>
			<div>{message}</div>
			<TextButton onClick={() => setExpanded(!expanded)}>
				{expanded ? 'Hide' : 'Show'} Details
			</TextButton>
			{expanded ? <ErrorDetails>{errorDetails}</ErrorDetails> : null}
		</div>
	)
}

export const notifyError = (message: string, error?: Error, options?: any) => {
	const friendlyError = formatError(error?.message, options)
	const truncated = truncateString(message)
	toast.error(<ToastContent message={truncated} errorDetails={friendlyError} />, {
		position: toast.POSITION.TOP_RIGHT,
		toastId: message,
		containerId: 'errors',
	})
}

export default function ErrorNotifier() {
	const theme = useTheme()
	return (
		<StyledToastContainer
			icon={() => <ErrorIcon fill={theme.colors.selectedTheme.red} />}
			enableMultiContainer
			containerId="errors"
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick={false}
			rtl={false}
			pauseOnFocusLoss
			pauseOnHover
		/>
	)
}

export const ERROR_MESSAGES = {
	ORDER_PENDING: 'Previous order is pending, please wait for it to finish processing or cancel it.',
	INSUFFICIENT_MARGIN: (minInitialMargin?: Wei | number) => `Insufficient margin, minimum ${formatsETH(minInitialMargin ?? 0.1)} margin required.`,
	INSUFFICIENT_ETH_BAL: 'Insufficient eth balance for gas cost',
	CANNOT_CANCEL_ORDER_YET: 'Cannot cancel the order yet',
	ORDER_TOO_OLD: 'Order expired, please cancel',
	PRICE_IMPACT_EXCEEDED: 'Price exceeded desired fill price',
}

// TODO: Format more errors, especially transaction failures
export const formatError = (message?: string, options?: { [key: string]: any}) => {
	if (!message) return ''
	let lowerCaseMessage = message.toLowerCase()
	const match = lowerCaseMessage.match(ErrorReasonRegex)
	if (match) {
		lowerCaseMessage = match[1]
	}
	
	if (lowerCaseMessage.includes('insufficient funds for intrinsic transaction cost'))
		return ERROR_MESSAGES.INSUFFICIENT_ETH_BAL
	if (lowerCaseMessage.includes('insufficient margin')) return ERROR_MESSAGES.INSUFFICIENT_MARGIN(options?.minInitialMargin)
	if (lowerCaseMessage.includes('previous order exists')) return ERROR_MESSAGES.ORDER_PENDING
	if (lowerCaseMessage.includes('cannot cancel yet')) return ERROR_MESSAGES.CANNOT_CANCEL_ORDER_YET
	if (lowerCaseMessage.includes('order too old')) return ERROR_MESSAGES.ORDER_TOO_OLD
	if (lowerCaseMessage.includes('price impact exceeded'))
		return ERROR_MESSAGES.PRICE_IMPACT_EXCEEDED
	if (lowerCaseMessage.includes('network request failed')) return 'Network request failed, please try it later'
	if (lowerCaseMessage.includes('no previous order')) return 'Order already executed or cancelled'
	if (lowerCaseMessage.includes('transaction failed')) return 'Transaction failed'
	
	return lowerCaseMessage
}

const StyledToastContainer = styled(ToastContainer)`
	.Toastify__toast-container {
		border-radius: 4px;
	}
	.Toastify__toast {
		border: ${(props) => props.theme.colors.selectedTheme.border};
		background: ${(props) => props.theme.colors.selectedTheme.button.fill};
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
	}
	.Toastify__toast-body {
		font-family: ${(props) => props.theme.fonts.regular};
		font-size: 13px;
		line-height: 13px;
		overflow-wrap: break-word;
		word-break: break-word;
	}
	.Toastify__progress-bar {
		background: ${(props) => props.theme.colors.selectedTheme.red};
	}
	.Toastify__close-button > svg {
		fill: white;
	}
`

// TODO: Use re-useable component once merged with component refactor

const TextButton = styled.div`
	text-decoration: underline;
	font-size: 13px;
	margin-top: 6px;
	line-height: 11px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	background-color: transparent;
	border: none;
	cursor: pointer;
`

const ErrorDetails = styled.div`
	margin-top: 8px;
	font-size: 11px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
`
