import { themes } from 'styles/theme'

export const getDesignTokens = (mode: 'dark' | 'light') => ({
	typography: {
		fontFamily: [
		  'Inter', 'Helvetica', 'Arial', 'sans-serif',
		].join(','),
		allVariants: { /* fontWeight: 400 */ },
	},
	palette: {
		mode,
		colors: themes[mode].colors,
	},
})
