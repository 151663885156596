import { CurrencyKey } from './currency'

export const PROD_HOSTNAME = 'kwenta.eth.limo'

export const EXTERNAL_LINKS = {
	Trading: {
		Legacy: 'https://legacy.kwenta.io/exchange',
		PerpsV1: 'https://v1.kwenta.eth.limo',
		DexAG: 'https://dex.ag/',
		Uniswap: 'https://uniswap.exchange/',
		OneInch: `https://1inch.exchange/`,
		OneInchApi: {
			ethereum: 'https://api.1inch.io/v5.0/1/',
			optimism: 'https://api.1inch.io/v5.0/10/',
		},
		OneInchLink: (from: CurrencyKey, to: CurrencyKey) => `https://1inch.exchange/#/${from}/${to}`,
		OptimismTokenBridge: 'https://gateway.optimism.io',
	},
	Options: {
		Trade: 'https://options.kwenta.eth.limo/#/trade',
	},
	Synthetix: {
		Home: 'https://www.synthetix.io',
		Litepaper: 'https://docs.synthetix.io/litepaper/',
	},
	Social: {
		Twitter: 'https://twitter.com/ETHGASofficial',
		Mirror: 'https://mirror.xyz/kwenta.eth',
		Discord: 'https://discord.gg/24hBhsWATv',
		GitHub: 'https://github.com/kwenta/kwenta',
		Telegram: 'https://t.me/ethgasofficial',
		Linkedin: 'https://www.linkedin.com/company/ethgas',
		Email: 'mailto:info@ethgas.com'
	},
	TokenLists: {
		Zapper: 'https://zapper.fi/api/token-list',
	},
	Docs: {
		DocsRoot: 'https://docs.kwenta.io/',
		FeeReclamation: 'https://docs.kwenta.io/resources/fee-reclamation',
		HowToTrade: 'https://docs.kwenta.io/products/futures',
		Governance: 'https://gov.kwenta.eth.limo',
		DaoRoles: 'https://gov.kwenta.eth.limo/sections/2',
		HowToUse: 'https://docs.kwenta.io/onboard/how-to-start-using-kwenta',
		Perpetuals: 'https://docs.kwenta.io/products/futures',
		Spot: 'https://docs.kwenta.io/products/swaps ',
		DevDao: 'https://docs.kwenta.io/dao/contribute/devdao-contribute',
		MarketingDao: 'https://gov.kwenta.eth.limo/sections/2/#marketingdao-grants-council-trial',
		Faq: 'https://docs.kwenta.io/resources/faq',
		CrossMarginFaq: 'https://docs.kwenta.io/using-kwenta/smart-margin/trading-on-kwenta/faq',
		Staking: 'https://docs.kwenta.io/using-kwenta/staking-kwenta',
		TradingRewardsV2: 'https://mirror.xyz/kwenta.eth/7k-5UYXXcCNJ_DRRWvYBsK5zDm5UA945My4QrInhxoI',
		RewardsGuide: 'https://mirror.xyz/kwenta.eth/8KyrISnjOcuAX_VW-GxVqxpcbWukB_RlP5XWWMz-UGk',
	},
	Optimism: {
		Home: 'https://optimism.io/',
	},
	Trade: {
		PerpsV2: 'https://kwenta.eth.limo/market/?accountType=cross_margin&asset=sETH',
		Spot: 'https://kwenta.eth.limo/exchange/',
		V1: 'https://v1.kwenta.eth.limo/dashboard',
	},
	Governance: {
		Kips: 'https://gov.kwenta.eth.limo/all-kips',
		Vote: 'https://snapshot.org/#/kwenta.eth',
	},
	Competition: {
		LearnMore: 'https://mirror.xyz/kwenta.eth/s_PO64SxvuwDHz9fdHebsYeQAOOc73D3bL2q4nC6LvU',
	},
	Staking: {
		Localhost: 'http://localhost:3001',
		Apptest: 'http://apptest.ethgas.com:3001',
		App: 'https://staking.ethgas.com'
	},
	Company: {
		TermsOfServices: 'https://www.ethgas.com/terms-of-service',
		PrivacyPolicy: 'https://www.ethgas.com/privacy-policy',
		Whitepaper: 'https://www.ethgas.com/whitepaper',
		Glossary: 'https://www.ethgas.com/glossary',
	},
	Explorer: {

	}
}

export const STAKING_LINKS: {[key: string]: string} = {
	'localhost': EXTERNAL_LINKS.Staking.Localhost,
	'apptest.ethgas.com': EXTERNAL_LINKS.Staking.Apptest,
	'app.ethgas.com': EXTERNAL_LINKS.Staking.App
}
