export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["AwaitingExecution"] = "AwaitingExecution";
    TransactionStatus["Executed"] = "Executed";
    TransactionStatus["Confirmed"] = "Confirmed";
    TransactionStatus["Failed"] = "Failed";
})(TransactionStatus || (TransactionStatus = {}));
export const NetworkIdByName = {
    mainnet: 1,
    goerli: 5,
    'goerli-ovm': 420,
    'mainnet-ovm': 10,
    kovan: 42,
    'kovan-ovm': 69,
    'mainnet-fork': 31338,
    'infdev-zksync': 270,
    'zksync-testnet': 280,
    'zksync-sepolia': 300
};
export const NetworkNameById = {
    1: 'mainnet',
    5: 'goerli',
    42: 'kovan',
    10: 'mainnet-ovm',
    69: 'kovan-ovm',
    420: 'goerli-ovm',
    31338: 'mainnet-fork',
    270: 'infdev-zksync',
    280: 'zksync-testnet',
    300: 'zksync-sepolia'
};
