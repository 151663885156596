import { headers } from 'next/headers';
import { Chain, connectorsForWallets, getDefaultWallets } from '@rainbow-me/rainbowkit'
import { inf_zksync_mainnet } from "./inf_zksync_mn";
import { inf_zksync_testnet } from "./inf_zksync";
import { zksyncSepolia } from "./zksyncSepolia";
import {
	braveWallet,
	coinbaseWallet,
	injectedWallet,
	ledgerWallet,
	metaMaskWallet,
	rainbowWallet,
	trustWallet,
	walletConnectWallet,
	
} from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createClient } from 'wagmi'
import {
	arbitrum,
	avalanche,
	bsc,
	mainnet,
	polygon,
	optimism,
	goerli,
	optimismGoerli,
	zkSyncTestnet
} from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'

import BinanceIcon from 'assets/png/rainbowkit/binance.png'
import Frame from 'components/Rainbowkit/Frame'
import Safe from 'components/Rainbowkit/Gnosis'
import Tally from 'components/Rainbowkit/Tally'
import { BLAST_NETWORK_LOOKUP, STALL_TIMEOUT } from 'constants/network'

const bscWithIcon: Chain = {
	...bsc,
	iconUrl: async () => BinanceIcon,
}
const WALLETCONNECT_V2_ID = process.env.NEXT_PUBLIC_WALLETCONNECT_V2_ID ?? ""
const CURRENT_ENV = process.env.NEXT_PUBLIC_ENVIRONMENT ?? "localhost"

type ChainItem = {
	domain: string,
	chain: Chain
}

export const chain = {
	// optimism,
	// mainnet,
	// arbitrum,
	// polygon,
	// avalanche,
	// bsc: bscWithIcon,
	// goerli,
	// optimismGoerli,
	zkSyncTestnet,
	zksyncSepolia,
	inf_zksync_testnet,
	inf_zksync_mainnet,
}

const chainConfig: ChainItem[] = [
	{
		domain: 'apptest, localhost, beta',
		chain: zksyncSepolia
	},
	{
		domain: 'localhost',
		chain: inf_zksync_testnet
	},
	{
		domain: 'apptest, localhost, beta',
		chain: inf_zksync_mainnet
	}
]

const { chains, provider } = configureChains(chainConfig.filter(c => c.domain.includes(CURRENT_ENV)).map(c => c.chain), [
	infuraProvider({
		apiKey: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID!,
		stallTimeout: STALL_TIMEOUT,
		priority: process.env.NEXT_PUBLIC_PROVIDER_ID === 'INFURA' ? 0 : 2,
	}),
	//improve ways to read the JSON RPC
	jsonRpcProvider({
		rpc: (networkChain) => ({
			http: !BLAST_NETWORK_LOOKUP[networkChain.id]
			? networkChain.rpcUrls.default.http[0]
				//? 'https://sepolia.era.zksync.dev'
				//: 'http://internal-dev-eth-node-kdfr9834.infinity.exchange:545',
				: networkChain.rpcUrls.default.http[0],
				//: 'https://sepolia.era.zksync.dev',
		}),
		stallTimeout: STALL_TIMEOUT,
		priority: 1,
	}),
	publicProvider({ stallTimeout: STALL_TIMEOUT, priority: 5 }),
])

const connectors = connectorsForWallets([
	{
		groupName: 'Popular',
		wallets: [
			metaMaskWallet({ chains, projectId: WALLETCONNECT_V2_ID }),
			coinbaseWallet({ appName: 'ETH-GAS', chains }),
			walletConnectWallet({ chains, projectId: WALLETCONNECT_V2_ID }),
		],
	}
])

export const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider,
})

export const activeChainIds = [
	// chain.optimism.id,
	// chain.mainnet.id,
	// chain.optimismGoerli.id,
	// chain.goerli.id,
	// chain.zkSyncTestnet.id,
	chain.zksyncSepolia.id,
	chain.inf_zksync_testnet.id,
	chain.inf_zksync_mainnet.id,
]

export { chains }
