var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
// @ts-ignore TODO: remove once types are added
import getFormattedSwapData from '@kwenta/synthswap';
import { wei } from '@synthetixio/wei';
import axios from 'axios';
import { Contract as EthCallContract } from 'ethcall';
import { ethers } from 'ethers';
import { get, keyBy } from 'lodash';
import * as sdkErrors from '../common/errors';
import { getEthGasPrice } from '../common/gas';
import { ATOMIC_EXCHANGES_L1, CRYPTO_CURRENCY_MAP, ETH_ADDRESS, ETH_COINGECKO_ADDRESS, ADDITIONAL_MARKETS, ATOMIC_EXCHANGE_SLIPPAGE, CG_BASE_API_URL, DEFAULT_BUFFER, FILTERED_TOKENS, PROTOCOLS, DEFAULT_1INCH_SLIPPAGE, KWENTA_REFERRAL_ADDRESS, SYNTH_SWAP_OPTIMISM_ADDRESS, } from '../constants/exchange';
import { KWENTA_TRACKING_CODE } from '../constants/futures';
import { UNIT_BIG_NUM, ZERO_WEI } from '../constants/number';
import erc20Abi from '../contracts/abis/ERC20.json';
import { getSynthsForNetwork } from '../data/synths';
import { synthToAsset } from '../utils/exchange';
import { getProxySynthSymbol, getReasonFromCode } from '../utils/synths';
import { getTransactionPrice, normalizeGasLimit } from '../utils/transactions';
export default class ExchangeService {
    constructor(sdk) {
        this.tokensMap = {};
        this.tokenList = [];
        this.sdk = sdk;
    }
    get exchangeRates() {
        return this.sdk.prices.currentPrices.onChain;
    }
    getTxProvider(baseCurrencyKey, quoteCurrencyKey) {
        var _a, _b;
        if (!baseCurrencyKey || !quoteCurrencyKey)
            return undefined;
        if (((_a = this.synthsMap) === null || _a === void 0 ? void 0 : _a[baseCurrencyKey]) &&
            ((_b = this.synthsMap) === null || _b === void 0 ? void 0 : _b[quoteCurrencyKey]))
            return 'synthetix';
        if (this.tokensMap[baseCurrencyKey] && this.tokensMap[quoteCurrencyKey])
            return '1inch';
        return 'synthswap';
    }
    getTradePrices(txProvider, quoteCurrencyKey, baseCurrencyKey, quoteAmountWei, baseAmountWei) {
        return __awaiter(this, void 0, void 0, function* () {
            const coinGeckoPrices = yield this.getCoingeckoPrices(quoteCurrencyKey, baseCurrencyKey);
            const [quotePriceRate, basePriceRate] = yield Promise.all([quoteCurrencyKey, baseCurrencyKey].map((currencyKey) => this.getPriceRate(currencyKey, txProvider, coinGeckoPrices)));
            let quoteTradePrice = quoteAmountWei.mul(quotePriceRate || 0);
            let baseTradePrice = baseAmountWei.mul(basePriceRate || 0);
            if (this.sUSDRate) {
                quoteTradePrice = quoteTradePrice.div(this.sUSDRate);
                baseTradePrice = baseTradePrice.div(this.sUSDRate);
            }
            return { quoteTradePrice, baseTradePrice };
        });
    }
    getSlippagePercent(quoteCurrencyKey, baseCurrencyKey, quoteAmountWei, baseAmountWei) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            if (txProvider === '1inch') {
                const { quoteTradePrice: totalTradePrice, baseTradePrice: estimatedBaseTradePrice, } = yield this.getTradePrices(txProvider, quoteCurrencyKey, baseCurrencyKey, quoteAmountWei, baseAmountWei);
                if (totalTradePrice.gt(0) && estimatedBaseTradePrice.gt(0)) {
                    return totalTradePrice.sub(estimatedBaseTradePrice).div(totalTradePrice).neg();
                }
            }
            return undefined;
        });
    }
    getBaseFeeRate(baseCurrencyKey, quoteCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.SystemSettings) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const [sourceCurrencyFeeRate, destinationCurrencyFeeRate] = yield Promise.all([
                this.sdk.context.contracts.SystemSettings.exchangeFeeRate(ethers.utils.formatBytes32String(baseCurrencyKey)),
                this.sdk.context.contracts.SystemSettings.exchangeFeeRate(ethers.utils.formatBytes32String(quoteCurrencyKey)),
            ]);
            return sourceCurrencyFeeRate && destinationCurrencyFeeRate
                ? wei(sourceCurrencyFeeRate.add(destinationCurrencyFeeRate))
                : wei(0);
        });
    }
    getExchangeFeeRate(quoteCurrencyKey, baseCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const exchangeFeeRate = yield this.sdk.context.contracts.Exchanger.feeRateForExchange(ethers.utils.formatBytes32String(quoteCurrencyKey), ethers.utils.formatBytes32String(baseCurrencyKey));
            return wei(exchangeFeeRate);
        });
    }
    getExchangeMaxDynamicFee() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const exchangeMaxDynamicFee = yield ((_a = this.sdk.context.contracts.SystemSettings) === null || _a === void 0 ? void 0 : _a.exchangeMaxDynamicFee());
            return exchangeMaxDynamicFee;
        });
    }
    getRate(baseCurrencyKey, quoteCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const baseCurrencyTokenAddress = this.getTokenAddress(baseCurrencyKey);
            const quoteCurrencyTokenAddress = this.getTokenAddress(quoteCurrencyKey);
            const [[quoteRate, baseRate], coinGeckoPrices] = yield Promise.all([
                this.getPairRates(quoteCurrencyKey, baseCurrencyKey),
                this.getCoingeckoPrices(quoteCurrencyKey, baseCurrencyKey),
            ]);
            const base = baseRate.lte(0)
                ? this.getCoingeckoPricesForCurrencies(coinGeckoPrices, baseCurrencyTokenAddress)
                : baseRate;
            const quote = quoteRate.lte(0)
                ? this.getCoingeckoPricesForCurrencies(coinGeckoPrices, quoteCurrencyTokenAddress)
                : quoteRate;
            return base.gt(0) && quote.gt(0) ? quote.div(base) : wei(0);
        });
    }
    getOneInchTokenList() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(this.oneInchApiUrl + 'tokens');
            const tokensMap = response.data.tokens || {};
            const chainId = this.sdk.context.isL2 ? 10 : 1;
            const tokens = Object.values(tokensMap).map((t) => (Object.assign(Object.assign({}, t), { chainId, tags: [] })));
            return {
                tokens,
                tokensMap: keyBy(tokens, 'symbol'),
                symbols: tokens.map((token) => token.symbol),
            };
        });
    }
    getFeeReclaimPeriod(currencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const maxSecsLeftInWaitingPeriod = (yield this.sdk.context.contracts.Exchanger.maxSecsLeftInWaitingPeriod(this.sdk.context.walletAddress, ethers.utils.formatBytes32String(currencyKey)));
            return Number(maxSecsLeftInWaitingPeriod);
        });
    }
    getDynamicFeeRateForExchange(fromAsset, toAsset) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const dynamicFeeRateForExchange = yield this.sdk.context.contracts.Exchanger.dynamicFeeRateForExchange(ethers.utils.formatBytes32String(fromAsset), ethers.utils.formatBytes32String(toAsset));
            return dynamicFeeRateForExchange;
        });
    }
    swapSynthSwap(fromToken, toToken, fromAmount, metaOnly) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.sdk.context.networkId !== 10)
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            const sUSD = this.tokensMap['sUSD'];
            const oneInchFrom = this.tokensMap[fromToken.symbol] ? sUSD.address : fromToken.address;
            const oneInchTo = this.tokensMap[toToken.symbol] ? sUSD.address : toToken.address;
            const fromSymbolBytes = ethers.utils.formatBytes32String(fromToken.symbol);
            const sUSDBytes = ethers.utils.formatBytes32String('sUSD');
            let synthAmountEth = fromAmount;
            if (this.tokensMap[fromToken.symbol]) {
                if (!this.sdk.context.contracts.Exchanger) {
                    throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
                }
                const fromAmountWei = wei(fromAmount).toString(0, true);
                const amounts = yield this.sdk.context.contracts.Exchanger.getAmountsForExchange(fromAmountWei, fromSymbolBytes, sUSDBytes);
                const usdValue = amounts.amountReceived.sub(amounts.fee);
                synthAmountEth = ethers.utils.formatEther(usdValue);
            }
            const params = yield this.getOneInchSwapParams(oneInchFrom, oneInchTo, synthAmountEth, fromToken.decimals);
            const formattedData = getFormattedSwapData(params, SYNTH_SWAP_OPTIMISM_ADDRESS);
            const SynthSwap = this.sdk.context.contracts.SynthSwap;
            if (!SynthSwap) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const contractFunc = metaOnly === 'meta_tx'
                ? SynthSwap.populateTransaction
                : metaOnly === 'estimate_gas'
                    ? SynthSwap.estimateGas
                    : SynthSwap;
            if (this.tokensMap[toToken.symbol]) {
                const symbolBytes = ethers.utils.formatBytes32String(toToken.symbol);
                if (formattedData.functionSelector === 'swap') {
                    return metaOnly
                        ? contractFunc.swapInto(symbolBytes, formattedData.data)
                        : this.sdk.transactions.createContractTxn(SynthSwap, 'swapInto', [
                            symbolBytes,
                            formattedData.data,
                        ]);
                }
                else {
                    return metaOnly
                        ? contractFunc.uniswapSwapInto(symbolBytes, fromToken.address, params.fromTokenAmount, formattedData.data)
                        : this.sdk.transactions.createContractTxn(SynthSwap, 'uniswapSwapInto', [
                            symbolBytes,
                            fromToken.address,
                            params.fromTokenAmount,
                            formattedData.data,
                        ]);
                }
            }
            else {
                if (formattedData.functionSelector === 'swap') {
                    return metaOnly
                        ? contractFunc.swapOutOf(fromSymbolBytes, wei(fromAmount).toString(0, true), formattedData.data)
                        : this.sdk.transactions.createContractTxn(SynthSwap, 'swapOutOf', [
                            fromSymbolBytes,
                            wei(fromAmount).toString(0, true),
                            formattedData.data,
                        ]);
                }
                else {
                    const usdValue = ethers.utils.parseEther(synthAmountEth).toString();
                    return metaOnly
                        ? contractFunc.uniswapSwapOutOf(fromSymbolBytes, toToken.address, wei(fromAmount).toString(0, true), usdValue, formattedData.data)
                        : this.sdk.transactions.createContractTxn(SynthSwap, 'uniswapSwapOutOf', [
                            fromSymbolBytes,
                            toToken.address,
                            wei(fromAmount).toString(0, true),
                            usdValue,
                            formattedData.data,
                        ]);
                }
            }
        });
    }
    swapOneInchMeta(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = yield this.getOneInchSwapParams(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals);
            const { from, to, data, value } = params.tx;
            return this.sdk.context.signer.populateTransaction({
                from,
                to,
                data,
                value: ethers.BigNumber.from(value),
            });
        });
    }
    swapOneInch(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = yield this.getOneInchSwapParams(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals);
            const { from, to, data, value } = params.tx;
            return this.sdk.transactions.createEVMTxn({ from, to, data, value });
        });
    }
    swapOneInchGasEstimate(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = yield this.getOneInchSwapParams(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals);
            return params.tx.gas;
        });
    }
    getNumEntries(currencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.Exchanger) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { numEntries } = yield this.sdk.context.contracts.Exchanger.settlementOwing(this.sdk.context.walletAddress, ethers.utils.formatBytes32String(currencyKey));
            return numEntries ? Number(numEntries.toString()) : 0;
        });
    }
    getAtomicRates(currencyKey) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.ExchangeRates) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { value } = yield this.sdk.context.contracts.ExchangeRates.effectiveAtomicValueAndRates(ethers.utils.formatBytes32String(currencyKey), UNIT_BIG_NUM, ethers.utils.formatBytes32String('sUSD'));
            return (_a = wei(value)) !== null && _a !== void 0 ? _a : wei(0);
        });
    }
    approveSwap(quoteCurrencyKey, baseCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            const quoteCurrencyContract = this.getQuoteCurrencyContract(quoteCurrencyKey);
            const approveAddress = yield this.getApproveAddress(txProvider);
            if (quoteCurrencyContract) {
                const { hash } = yield this.sdk.transactions.createContractTxn(quoteCurrencyContract, 'approve', [approveAddress, ethers.constants.MaxUint256]);
                return hash;
            }
            return undefined;
        });
    }
    handleSettle(baseCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2) {
                throw new Error(sdkErrors.REQUIRES_L2);
            }
            const numEntries = yield this.getNumEntries(baseCurrencyKey);
            if (numEntries > 12) {
                const destinationCurrencyKey = ethers.utils.formatBytes32String(baseCurrencyKey);
                const { hash } = yield this.sdk.transactions.createSynthetixTxn('Exchanger', 'settle', [
                    this.sdk.context.walletAddress,
                    destinationCurrencyKey,
                ]);
                return hash;
            }
            return undefined;
        });
    }
    // TODO: Refactor handleExchange
    handleExchange(quoteCurrencyKey, baseCurrencyKey, quoteAmount, baseAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            const quoteCurrencyTokenAddress = this.getTokenAddress(quoteCurrencyKey);
            const baseCurrencyTokenAddress = this.getTokenAddress(baseCurrencyKey);
            const quoteDecimals = this.getTokenDecimals(quoteCurrencyKey);
            let tx = null;
            if (txProvider === '1inch' && !!this.tokensMap) {
                tx = yield this.swapOneInch(quoteCurrencyTokenAddress, baseCurrencyTokenAddress, quoteAmount, quoteDecimals);
            }
            else if (txProvider === 'synthswap') {
                // @ts-ignore TODO: Fix variable types
                tx = yield this.swapSynthSwap(this.allTokensMap[quoteCurrencyKey], this.allTokensMap[baseCurrencyKey], quoteAmount);
            }
            else {
                const isAtomic = this.checkIsAtomic(baseCurrencyKey, quoteCurrencyKey);
                const exchangeParams = this.getExchangeParams(quoteCurrencyKey, baseCurrencyKey, wei(quoteAmount), wei(baseAmount).mul(wei(1).sub(ATOMIC_EXCHANGE_SLIPPAGE)), isAtomic);
                const shouldExchange = !!exchangeParams &&
                    !!this.sdk.context.walletAddress &&
                    !!this.sdk.context.contracts.Synthetix;
                if (shouldExchange) {
                    const { hash } = yield this.sdk.transactions.createSynthetixTxn('Synthetix', isAtomic ? 'exchangeAtomically' : 'exchangeWithTracking', exchangeParams);
                    return hash;
                }
            }
            return tx === null || tx === void 0 ? void 0 : tx.hash;
        });
    }
    getTransactionFee(quoteCurrencyKey, baseCurrencyKey, quoteAmount, baseAmount) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const gasPrices = yield getEthGasPrice(this.sdk.context.networkId, this.sdk.context.provider);
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            const ethPriceRate = this.getExchangeRatesForCurrencies(this.exchangeRates, 'sETH', 'sUSD');
            const gasPrice = gasPrices.fast;
            if (txProvider === 'synthswap' || txProvider === '1inch') {
                const gasInfo = yield this.getGasEstimateForExchange(txProvider, quoteCurrencyKey, baseCurrencyKey, quoteAmount);
                return getTransactionPrice(gasPrice, BigNumber.from((gasInfo === null || gasInfo === void 0 ? void 0 : gasInfo.limit) || 0).mul(3), ethPriceRate, (_a = gasInfo === null || gasInfo === void 0 ? void 0 : gasInfo.l1Fee) !== null && _a !== void 0 ? _a : ZERO_WEI);
            }
            else {
                if (!this.sdk.context.contracts.Synthetix) {
                    throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
                }
                const isAtomic = this.checkIsAtomic(baseCurrencyKey, quoteCurrencyKey);
                const exchangeParams = this.getExchangeParams(quoteCurrencyKey, baseCurrencyKey, wei(quoteAmount || 0), wei(baseAmount || 0).mul(wei(1).sub(ATOMIC_EXCHANGE_SLIPPAGE)), isAtomic);
                const method = isAtomic ? 'exchangeAtomically' : 'exchangeWithTracking';
                const txn = {
                    to: this.sdk.context.contracts.Synthetix.address,
                    data: this.sdk.context.contracts.Synthetix.interface.encodeFunctionData(
                    // @ts-ignore TODO: Fix types
                    method, exchangeParams),
                    value: ethers.BigNumber.from(0),
                };
                const [baseGasLimit, optimismLayerOneFee] = yield Promise.all([
                    this.sdk.transactions.estimateGas(txn),
                    this.sdk.transactions.getOptimismLayerOneFees(txn),
                ]);
                const gasLimit = wei(baseGasLimit !== null && baseGasLimit !== void 0 ? baseGasLimit : 0, 9)
                    .mul(1 + DEFAULT_BUFFER)
                    .toBN().mul(3);
                return getTransactionPrice(gasPrice, gasLimit, ethPriceRate, optimismLayerOneFee);
            }
        });
    }
    getFeeCost(quoteCurrencyKey, baseCurrencyKey, quoteAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            const coinGeckoPrices = yield this.getCoingeckoPrices(quoteCurrencyKey, baseCurrencyKey);
            const [exchangeFeeRate, quotePriceRate] = yield Promise.all([
                this.getExchangeFeeRate(quoteCurrencyKey, baseCurrencyKey),
                this.getPriceRate(quoteCurrencyKey, txProvider, coinGeckoPrices),
            ]);
            const feeAmountInQuoteCurrency = wei(quoteAmount).mul(exchangeFeeRate);
            return feeAmountInQuoteCurrency.mul(quotePriceRate);
        });
    }
    getApproveAddress(txProvider) {
        return txProvider !== '1inch' ? SYNTH_SWAP_OPTIMISM_ADDRESS : this.getOneInchApproveAddress();
    }
    checkAllowance(quoteCurrencyKey, baseCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            const [quoteCurrencyContract, approveAddress] = yield Promise.all([
                this.getQuoteCurrencyContract(quoteCurrencyKey),
                this.getApproveAddress(txProvider),
            ]);
            if (!!quoteCurrencyContract) {
                const allowance = (yield quoteCurrencyContract.allowance(this.sdk.context.walletAddress, approveAddress));
                return wei(ethers.utils.formatEther(allowance));
            }
        });
    }
    getCurrencyName(currencyKey) {
        var _a, _b;
        return (_b = (_a = this.allTokensMap) === null || _a === void 0 ? void 0 : _a[currencyKey]) === null || _b === void 0 ? void 0 : _b.name;
    }
    getOneInchQuote(baseCurrencyKey, quoteCurrencyKey, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const sUSD = this.tokensMap['sUSD'];
            const decimals = this.getTokenDecimals(quoteCurrencyKey);
            const quoteTokenAddress = this.getTokenAddress(quoteCurrencyKey);
            const baseTokenAddress = this.getTokenAddress(baseCurrencyKey);
            const txProvider = this.getTxProvider(baseCurrencyKey, quoteCurrencyKey);
            const synth = this.tokensMap[quoteCurrencyKey] || this.tokensMap[baseCurrencyKey];
            const synthUsdRate = synth ? this.getPairRates(synth, 'sUSD') : null;
            if (!quoteCurrencyKey || !baseCurrencyKey || !sUSD || !amount.length || wei(amount).eq(0)) {
                return '';
            }
            if (txProvider === '1inch') {
                const estimatedAmount = yield this.quoteOneInch(quoteTokenAddress, baseTokenAddress, amount, decimals);
                return estimatedAmount;
            }
            if (this.tokensMap[quoteCurrencyKey]) {
                const usdAmount = wei(amount).div(synthUsdRate);
                const estimatedAmount = yield this.quoteOneInch(sUSD.address, baseTokenAddress, usdAmount.toString(), decimals);
                return estimatedAmount;
            }
            else {
                const estimatedAmount = yield this.quoteOneInch(quoteTokenAddress, sUSD.address, amount, decimals);
                return wei(estimatedAmount).mul(synthUsdRate).toString();
            }
        });
    }
    getPriceRate(currencyKey, txProvider, coinGeckoPrices) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const tokenAddress = this.getTokenAddress(currencyKey, true).toLowerCase();
            if (txProvider !== 'synthetix') {
                const sUSDRate = this.exchangeRates['sUSD'];
                const price = coinGeckoPrices && coinGeckoPrices[tokenAddress];
                if (price && (sUSDRate === null || sUSDRate === void 0 ? void 0 : sUSDRate.gt(0))) {
                    return wei((_a = price.usd) !== null && _a !== void 0 ? _a : 0).div(sUSDRate);
                }
                else {
                    return wei(0);
                }
            }
            else {
                return this.checkIsAtomic(currencyKey, 'sUSD')
                    ? yield this.getAtomicRates(currencyKey)
                    : this.getExchangeRatesForCurrencies(this.exchangeRates, currencyKey, 'sUSD');
            }
        });
    }
    getRedeemableDeprecatedSynths() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { SynthRedeemer } = this.sdk.context.contracts;
            const { SynthRedeemer: Redeemer } = this.sdk.context.multicallContracts;
            if (!SynthRedeemer || !Redeemer) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const { walletAddress } = this.sdk.context;
            const synthDeprecatedFilter = SynthRedeemer.filters.SynthDeprecated();
            const deprecatedSynthsEvents = yield SynthRedeemer.queryFilter(synthDeprecatedFilter);
            const deprecatedProxySynthsAddresses = (_a = deprecatedSynthsEvents.map((e) => { var _a; return (_a = e.args) === null || _a === void 0 ? void 0 : _a.synth; }).filter(Boolean)) !== null && _a !== void 0 ? _a : [];
            const calls = [];
            for (const addr of deprecatedProxySynthsAddresses) {
                calls.push(getProxySynthSymbol(addr));
                calls.push(Redeemer.balanceOf(addr, walletAddress));
            }
            const redeemableSynthData = (yield this.sdk.context.multicallProvider.all(calls));
            let totalUSDBalance = wei(0);
            const cryptoBalances = [];
            for (let i = 0; i < redeemableSynthData.length; i += 2) {
                const usdBalance = wei(redeemableSynthData[i + 1]);
                if (usdBalance.gt(0)) {
                    totalUSDBalance = totalUSDBalance.add(usdBalance);
                    cryptoBalances.push({
                        currencyKey: redeemableSynthData[i],
                        proxyAddress: deprecatedProxySynthsAddresses[i],
                        balance: wei(0),
                        usdBalance,
                    });
                }
            }
            return { balances: cryptoBalances, totalUSDBalance };
        });
    }
    validCurrencyKeys(quoteCurrencyKey, baseCurrencyKey) {
        return [quoteCurrencyKey, baseCurrencyKey].map((currencyKey) => {
            return (!!currencyKey &&
                (!!this.synthsMap[currencyKey] || !!this.tokensMap[currencyKey]));
        });
    }
    getCoingeckoPrices(quoteCurrencyKey, baseCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            const quoteCurrencyTokenAddress = this.getTokenAddress(quoteCurrencyKey, true).toLowerCase();
            const baseCurrencyTokenAddress = this.getTokenAddress(baseCurrencyKey).toLowerCase();
            const tokenAddresses = [quoteCurrencyTokenAddress, baseCurrencyTokenAddress];
            return this.batchGetCoingeckoPrices(tokenAddresses);
        });
    }
    batchGetCoingeckoPrices(tokenAddresses, include24hrChange = false) {
        return __awaiter(this, void 0, void 0, function* () {
            const platform = this.sdk.context.isL2 ? 'optimistic-ethereum' : 'ethereum';
            const response = yield axios.get(`${CG_BASE_API_URL}/simple/token_price/${platform}?contract_addresses=${tokenAddresses
                .join(',')
                .replace(ETH_ADDRESS, ETH_COINGECKO_ADDRESS)}&vs_currencies=usd${include24hrChange ? '&include_24hr_change=true' : ''}`);
            return response.data;
        });
    }
    get sUSDRate() {
        return this.exchangeRates['sUSD'];
    }
    getExchangeParams(quoteCurrencyKey, baseCurrencyKey, sourceAmount, minAmount, isAtomic) {
        const sourceCurrencyKey = ethers.utils.formatBytes32String(quoteCurrencyKey);
        const destinationCurrencyKey = ethers.utils.formatBytes32String(baseCurrencyKey);
        const sourceAmountBN = sourceAmount.toBN();
        if (isAtomic) {
            return [
                sourceCurrencyKey,
                sourceAmountBN,
                destinationCurrencyKey,
                KWENTA_TRACKING_CODE,
                minAmount.toBN(),
            ];
        }
        else {
            return [
                sourceCurrencyKey,
                sourceAmountBN,
                destinationCurrencyKey,
                this.sdk.context.walletAddress,
                KWENTA_TRACKING_CODE,
            ];
        }
    }
    getSynthsMap() {
        return this.synthsMap;
    }
    get synthsMap() {
        return getSynthsForNetwork(this.sdk.context.networkId);
    }
    getOneInchTokens() {
        return __awaiter(this, void 0, void 0, function* () {
            const { tokensMap, tokens } = yield this.getOneInchTokenList();
            this.tokensMap = tokensMap;
            this.tokenList = tokens;
            this.allTokensMap = Object.assign(Object.assign({}, this.synthsMap), tokensMap);
            return { tokensMap: this.tokensMap, tokenList: this.tokenList };
        });
    }
    getSynthSuspensions() {
        return __awaiter(this, void 0, void 0, function* () {
            const { SystemStatus } = this.sdk.context.multicallContracts;
            const synthsMap = this.sdk.exchange.getSynthsMap();
            if (!SystemStatus) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const calls = [];
            for (let synth in synthsMap) {
                calls.push(SystemStatus.synthExchangeSuspension(formatBytes32String(synth)));
            }
            const responses = (yield this.sdk.context.multicallProvider.all(calls));
            let ret = {};
            let i = 0;
            for (let synth in synthsMap) {
                const [isSuspended, reason] = responses[i];
                const reasonCode = Number(reason);
                ret[synth] = {
                    isSuspended: responses[i][0],
                    reasonCode,
                    reason: isSuspended ? getReasonFromCode(reasonCode) : null,
                };
                i++;
            }
            return ret;
        });
    }
    checkIsAtomic(baseCurrencyKey, quoteCurrencyKey) {
        if (this.sdk.context.isL2 || !baseCurrencyKey || !quoteCurrencyKey) {
            return false;
        }
        return [baseCurrencyKey, quoteCurrencyKey].every((currency) => ATOMIC_EXCHANGES_L1.includes(currency));
    }
    getTokenDecimals(currencyKey) {
        return get(this.allTokensMap, [currencyKey, 'decimals'], undefined);
    }
    getQuoteCurrencyContract(quoteCurrencyKey) {
        if (this.allTokensMap[quoteCurrencyKey]) {
            const quoteTknAddress = this.getTokenAddress(quoteCurrencyKey, true);
            return this.createERC20Contract(quoteTknAddress);
        }
        return null;
    }
    get oneInchApiUrl() {
        return `https://api.1inch.io/v5.0/${this.sdk.context.isL2 ? 10 : 1}/`;
    }
    getOneInchQuoteSwapParams(quoteTokenAddress, baseTokenAddress, amount, decimals) {
        return {
            fromTokenAddress: quoteTokenAddress,
            toTokenAddress: baseTokenAddress,
            amount: wei(amount, decimals).toString(0, true),
        };
    }
    getOneInchSwapParams(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = this.getOneInchQuoteSwapParams(quoteTokenAddress, baseTokenAddress, amount, quoteDecimals);
            const res = yield axios.get(this.oneInchApiUrl + 'swap', {
                params: {
                    fromTokenAddress: params.fromTokenAddress,
                    toTokenAddress: params.toTokenAddress,
                    amount: params.amount,
                    fromAddress: this.sdk.context.walletAddress,
                    slippage: DEFAULT_1INCH_SLIPPAGE,
                    PROTOCOLS,
                    referrerAddress: KWENTA_REFERRAL_ADDRESS,
                    disableEstimate: true,
                },
            });
            return res.data;
        });
    }
    quoteOneInch(quoteTokenAddress, baseTokenAddress, amount, decimals) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = this.getOneInchQuoteSwapParams(quoteTokenAddress, baseTokenAddress, amount, decimals);
            const response = yield axios.get(this.oneInchApiUrl + 'quote', {
                params: {
                    fromTokenAddress: params.fromTokenAddress,
                    toTokenAddress: params.toTokenAddress,
                    amount: params.amount,
                    disableEstimate: true,
                    PROTOCOLS,
                },
            });
            return ethers.utils
                .formatUnits(response.data.toTokenAmount, response.data.toToken.decimals)
                .toString();
        });
    }
    swapSynthSwapGasEstimate(fromToken, toToken, fromAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.swapSynthSwap(fromToken, toToken, fromAmount, 'estimate_gas');
        });
    }
    getPairRates(quoteCurrencyKey, baseCurrencyKey) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.checkIsAtomic(baseCurrencyKey, quoteCurrencyKey)
                ? yield Promise.all([
                    this.getAtomicRates(quoteCurrencyKey),
                    this.getAtomicRates(baseCurrencyKey),
                ])
                : this.getExchangeRatesTupleForCurrencies(this.sdk.prices.currentPrices.onChain, quoteCurrencyKey, baseCurrencyKey);
        });
    }
    getOneInchApproveAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(this.oneInchApiUrl + 'approve/spender');
            return response.data.address;
        });
    }
    getGasEstimateForExchange(txProvider, quoteCurrencyKey, baseCurrencyKey, quoteAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isL2)
                return null;
            const quoteCurrencyTokenAddress = this.getTokenAddress(quoteCurrencyKey);
            const baseCurrencyTokenAddress = this.getTokenAddress(baseCurrencyKey);
            const quoteDecimals = this.getTokenDecimals(quoteCurrencyKey);
            if (txProvider === 'synthswap') {
                const [gasEstimate, metaTx] = yield Promise.all([
                    this.swapSynthSwapGasEstimate(this.allTokensMap[quoteCurrencyKey], this.allTokensMap[baseCurrencyKey], quoteAmount),
                    this.swapSynthSwap(this.allTokensMap[quoteCurrencyKey], this.allTokensMap[baseCurrencyKey], quoteAmount, 'meta_tx'),
                ]);
                // @ts-ignore TODO: Fix types from metaTx
                const l1Fee = yield this.sdk.transactions.getOptimismLayerOneFees(Object.assign(Object.assign({}, metaTx), { gasPrice: 0, gasLimit: Number(gasEstimate) }));
                return { limit: normalizeGasLimit(Number(gasEstimate)), l1Fee };
            }
            else if (txProvider === '1inch') {
                const [estimate, metaTx] = yield Promise.all([
                    this.swapOneInchGasEstimate(quoteCurrencyTokenAddress, baseCurrencyTokenAddress, quoteAmount, quoteDecimals),
                    this.swapOneInchMeta(quoteCurrencyTokenAddress, baseCurrencyTokenAddress, quoteAmount, quoteDecimals),
                ]);
                const l1Fee = yield this.sdk.transactions.getOptimismLayerOneFees(Object.assign(Object.assign({}, metaTx), { gasPrice: 0, gasLimit: Number(estimate) }));
                return { limit: normalizeGasLimit(Number(estimate)), l1Fee };
            }
        });
    }
    isCurrencyETH(currencyKey) {
        return currencyKey === CRYPTO_CURRENCY_MAP.ETH;
    }
    getTokenAddress(currencyKey, coingecko) {
        if (currencyKey != null) {
            if (this.isCurrencyETH(currencyKey)) {
                return coingecko ? ETH_COINGECKO_ADDRESS : ETH_ADDRESS;
            }
            else {
                return get(this.allTokensMap, [currencyKey, 'address'], null);
            }
        }
        else {
            return null;
        }
    }
    getCoingeckoPricesForCurrencies(coingeckoPrices, baseAddress) {
        if (!coingeckoPrices || !baseAddress) {
            return wei(0);
        }
        const base = (baseAddress === ETH_ADDRESS ? ETH_COINGECKO_ADDRESS : baseAddress).toLowerCase();
        if (!coingeckoPrices[base]) {
            return wei(0);
        }
        return wei(coingeckoPrices[base].usd);
    }
    getExchangeRatesForCurrencies(rates, base, quote) {
        base = ADDITIONAL_MARKETS.has(base) ? synthToAsset(base) : base;
        return !rates || !base || !quote || !rates[base] || !rates[quote]
            ? wei(0)
            : rates[base].div(rates[quote]);
    }
    getExchangeRatesTupleForCurrencies(rates, base, quote) {
        base = ADDITIONAL_MARKETS.has(base) ? synthToAsset(base) : base;
        const baseRate = !rates || !base || !rates[base] ? wei(0) : rates[base];
        const quoteRate = !rates || !quote || !rates[quote] ? wei(0) : rates[quote];
        return [baseRate, quoteRate];
    }
    // TODO: This is temporary.
    // We should consider either having another service for this
    // It does not quite fit into the synths service.
    // One idea is to create a "tokens" service that handles everything
    // related to 1inch tokens.
    getTokenBalances(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.isMainnet)
                return {};
            const filteredTokens = this.tokenList.filter((t) => !FILTERED_TOKENS.includes(t.address.toLowerCase()));
            const symbols = filteredTokens.map((token) => token.symbol);
            const tokensMap = keyBy(filteredTokens, 'symbol');
            const calls = [];
            for (const { address, symbol } of filteredTokens) {
                if (symbol === CRYPTO_CURRENCY_MAP.ETH) {
                    calls.push(this.sdk.context.multicallProvider.getEthBalance(walletAddress));
                }
                else {
                    const tokenContract = new EthCallContract(address, erc20Abi);
                    calls.push(tokenContract.balanceOf(walletAddress));
                }
            }
            const data = (yield this.sdk.context.multicallProvider.all(calls));
            const tokenBalances = {};
            data.forEach((value, index) => {
                var _a;
                if (value.lte(0))
                    return;
                const token = tokensMap[symbols[index]];
                tokenBalances[symbols[index]] = {
                    balance: wei(value, (_a = token.decimals) !== null && _a !== void 0 ? _a : 18),
                    token,
                };
            });
            return tokenBalances;
        });
    }
    createERC20Contract(tokenAddress) {
        return new ethers.Contract(tokenAddress, erc20Abi, this.sdk.context.provider);
    }
}
