import { KwentaStatus, OperationalStatus } from '@kwenta/sdk/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ThunkConfig } from 'state/types'
import { logError } from 'zrender/lib/core/util'

export const checkSynthetixStatus = createAsyncThunk<boolean, void, ThunkConfig>(
	'app/checkSynthetixStatus',
	(_, { extra: { sdk } }) => {
		try {
			return sdk.system.getSynthetixStatus()
		} catch (e) {
			logError(e)
			return false
		}
	}
)

export const fetchKwentaStatus = createAsyncThunk<KwentaStatus, void, ThunkConfig>(
	'app/fetchKwentaStatus',
	(_, { extra: { sdk } }) => {
		try {
			return sdk.system.getKwentaStatus()
		} catch (e) {
			logError(e)
			return {
				status: OperationalStatus.Offline,
				message: '',
				lastUpdatedAt: new Date().getTime()
			}
		}
	}
)
