var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { buildQueryString } from "./string";
import { ENS_ENDPOINT_MAINNET } from "../constants";
import { isArray } from "lodash";
export const queryENSNames = function (addresses) {
    return __awaiter(this, void 0, void 0, function* () {
        const resolvers = yield getENSResolver(addresses.map((a) => a.toLowerCase()));
        if (isArray(resolvers) && resolvers.length > 0) {
            const domains = yield getENSDomains(resolvers);
            const domainAddressList = domains.map((d) => d.address);
            addresses.map((a) => {
                if (!domainAddressList.includes(a)) {
                    domains.push({ address: a, name: null, labelName: null });
                }
            });
            return domains;
        }
        //@ts-ignore
        return addresses.map((a) => ({ address: a, name: null, labelName: null }));
    });
};
export const getENSResolver = function (addresses) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const resolverQuery = buildQueryString({
            queryField: 'resolvers',
            where: { addr_: { id_in: true } },
            queryValue: 'addresses',
            selectionSet: ['id', `addr { id }`]
        });
        try {
            const resolvers = yield axios.post(ENS_ENDPOINT_MAINNET, {
                query: resolverQuery,
                variables: { addresses: addresses.map((a) => a.toLowerCase()) },
            });
            return (_c = (_b = (_a = resolvers === null || resolvers === void 0 ? void 0 : resolvers.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.resolvers) !== null && _c !== void 0 ? _c : [];
        }
        catch (e) {
            console.log('getENSResolver error', e);
            return [];
        }
    });
};
export const getENSDomains = function (resolvers) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const domainQuery = buildQueryString({
            queryField: 'domains',
            where: { resolver_: { id_in: true } },
            queryValue: 'resolvers',
            selectionSet: ['name', 'labelName', `resolver { addr { id }}`]
        });
        try {
            const domains = yield axios.post(ENS_ENDPOINT_MAINNET, {
                query: domainQuery,
                variables: { resolvers: resolvers.map((r) => r.id) },
            });
            return (_c = (_b = (_a = domains === null || domains === void 0 ? void 0 : domains.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.domains.map((domain) => ({ address: domain.resolver.addr.id, name: domain.name, labelName: domain.labelName }))) !== null && _c !== void 0 ? _c : [];
        }
        catch (e) {
            console.log('getENSDomains error', e);
            return [];
        }
    });
};
export const queryAddressesByENSName = function (search) {
    return __awaiter(this, void 0, void 0, function* () {
        const domains = yield getAddressByENSName(search);
        return domains;
    });
};
// let getAddressByENSNameController:AbortController; // DEV AbortController not working
let getAddressByENSNameSeq = 0;
export const getAddressByENSName = function (ensName) {
    var _a, _b, _c, _d, _e;
    return __awaiter(this, void 0, void 0, function* () {
        if (((_b = (_a = ensName.split('.').at(0)) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) < 3)
            return []; // valid name is at least 3 characters long
        if (ensName.split('.').filter(part => part.length > 0).length === 1)
            ensName += '.eth'; // auto append `.eth`
        if (ensName.split('.').filter(part => part.length > 0).length < 2)
            return []; // only lookup valid ENS name
        const domainQuery = buildQueryString({
            queryField: 'domains',
            where: { name: true },
            queryValue: 'ensName',
            queryValueType: 'String',
            selectionSet: ['name', 'labelName', `resolver { addr { id }}`],
            first: 5,
        });
        try {
            // console.log('cancel old requet')
            // getAddressByENSNameController?.abort('new search'); // abort previous
            const currentSeq = ++getAddressByENSNameSeq;
            yield (() => new Promise((resolve) => setTimeout(resolve, 300)))(); // 300ms delay
            if (currentSeq !== getAddressByENSNameSeq) {
                // console.log('canceled cause of seq'); 
                return [];
            }
            // getAddressByENSNameController = new AbortController();
            // console.log('save new requet')
            const domains = yield axios.post(ENS_ENDPOINT_MAINNET, {
                query: domainQuery,
                variables: { ensName },
                // signal: getAddressByENSNameController.signal,
            });
            // console.log('ENS NAMES - DOMAINS', domains?.data?.data?.domains, name)
            return (_e = (_d = (_c = domains === null || domains === void 0 ? void 0 : domains.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.domains.filter((domain) => { var _a; return !!((_a = domain.resolver) === null || _a === void 0 ? void 0 : _a.addr); }).map((domain) => ({ address: domain.resolver.addr.id, name: domain.name, labelName: domain.labelName }))) !== null && _e !== void 0 ? _e : [];
        }
        catch (e) {
            console.log('getENSDomains error', e);
            return [];
        }
    });
};
