import { computed, signal } from "@preact/signals-react";
import { MempoolPricePercentile } from "sections/market/types";

export const lastMempoolPricePercentileDataSignal = signal<MempoolPricePercentile | null>(null);

export const mempoolPricePercentileUsingPrevBlockSignal = computed(() => lastMempoolPricePercentileDataSignal.value?.usingPrevBlockData)
export const maxFeePerGasSinceLastBlockSignal = computed(() => lastMempoolPricePercentileDataSignal.value?.maxFeePerGasSinceLastBlock)
export const baseFeePerGasSignal = computed(() => lastMempoolPricePercentileDataSignal.value?.nextBaseFeePerGas)
export const usingPrevBlockDataSignal = computed(() => lastMempoolPricePercentileDataSignal.value?.usingPrevBlockData)
export const blockTimeRemainingSignal = computed(() => 12 - (lastMempoolPricePercentileDataSignal.value?.secondsSinceLastBlock ?? 0))
export const remainingSecondsSignal = computed(() => (lastMempoolPricePercentileDataSignal.value?.secondsSinceLastBlock ?? 0) * 4)
export const pendingTxCountSinceLastBlockSignal = computed(() => lastMempoolPricePercentileDataSignal.value?.pendingTxCountSinceLastBlock ?? 0)