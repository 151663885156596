import differenceInSeconds from 'date-fns/differenceInSeconds';
import formatDate from 'date-fns/format';
import getISOWeeksInYear from 'date-fns/getISOWeeksInYear';
import subHours from 'date-fns/subHours';
import { strPadLeft } from './string';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
export const formatTimeRageLabel = (start, end) => {
    const startYear = getYear(start);
    const endYear = getYear(end);
    const startMonth = getMonth(start);
    const endMonth = getMonth(end);
    if (startYear === endYear) {
        if (startMonth === endMonth) {
            return formatDate(start, 'dd') + ' - ' + formatDate(end, 'dd MMM yyyy');
        }
        else {
            return formatDate(start, 'dd MMM') + ' - ' + formatDate(end, 'dd MMM yyyy');
        }
    }
    else {
        return formatDate(start, 'dd MMM yyyy') + ' - ' + formatDate(end, 'dd MMM yyyy');
    }
};
export const formatAxisTimeLabel = (dateString, timeRange) => {
    if (!isValid(parseISO(dateString))) {
        return '';
    }
    const date = new Date(dateString);
    switch (timeRange) {
        case 3600: return formatDate(date, 'd MMM HH:mm').toUpperCase();
        case 86400: return formatDate(date, 'd MMM HH:mm').toUpperCase();
        default: return formatDate(date, "d MMM ''yy").toUpperCase();
    }
};
export const formatTxTimestamp = (timestamp) => formatDate(timestamp, 'MMM d, yy | HH:mm');
export const toJSTimestamp = (timestamp) => timestamp * 1000;
export const formatShortDate = (date) => formatDate(date, 'yyyy-MM-dd');
export const formatChartTime = (date) => formatDate(date, 'do, ha');
export const formatChartDate = (date) => formatDate(date, 'M/d');
export const formatShortDateUTC = (date) => {
    const dateString = new Date(date).toISOString();
    return dateString.substring(0, 10);
};
export const formatShortDateWithTime = (date) => formatDate(date, 'MMM d, yyyy h:mm a');
export const formatDateWithTime = (date) => formatDate(date, 'd MMM yyyy H:mm');
export const secondsToTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds - minutes * 60;
    return `${strPadLeft(minutes, '0', 2)}:${strPadLeft(secondsLeft, '0', 2)}`;
};
export const WEEKS_IN_YEAR = getISOWeeksInYear(new Date());
export const calculateTimestampForPeriod = (periodInHours) => Math.trunc(subHours(new Date().getTime(), periodInHours).getTime());
export const formatDateWithoutYear = (date) => formatDate(date, 'MMMM dd');
export const formatShortDateWithoutYear = (date) => formatDate(date, 'M/dd');
export const calculatedTimeDifference = (dateLeft, dateRight) => differenceInSeconds(dateLeft, dateRight);
export const keepDoublePlaceholder = (num) => (num < 9 ? `0${num}` : num);
export const formatTimer = (seconds) => {
    const numMinutes = Math.floor(seconds / 60);
    const numSeconds = seconds % 60;
    return `${numMinutes}:${String(numSeconds).padStart(2, '0')}`;
};
export const truncateTimestamp = (timestamp, delta) => {
    return Math.floor(timestamp / delta) * delta;
};
export const formatTruncatedDuration = (delta) => {
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const daysStr = days > 0 ? days + 'd' : '0d';
    const hoursStr = hours > 0 ? hours + 'h' : '0h';
    const minsStr = minutes > 0 ? minutes + 'm' : '0m';
    return days > 10 ? `${daysStr}:${hoursStr}`.trim() : `${daysStr}:${hoursStr}:${minsStr}`.trim();
};
export const getNextSunday = (date) => {
    const nextSunday = new Date();
    nextSunday.setDate(date.getDate() + (7 - date.getDay()));
    nextSunday.setHours(0, 0, 0, 0);
    return nextSunday;
};
