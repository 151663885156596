export const DEFAULT_USER_DATA = {
    cumulativeGasSpendChart: [],
    gasSpentTableData: {},
    contractTransactions: {},
    topProtocolNames: [],
    userInfo: {}
}

export const DEFAULT_USER_INFO = {
    avgGasSpentEth: 0,
    avgGasSpentUsd: 0,
    totalGasSpentEth: 0,
    totalGasSpentUsd: 0,
    transactionCount: 0,
    totalGasUsed: 0,
    percentageOnGas: 0,
    avgGasPrice: 0,
    group: '',
    type: '',
    overspendGwei: 0,
    overspendUsd: 0
}