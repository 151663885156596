var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String, parseBytes32String } from '@ethersproject/strings';
import { wei } from '@synthetixio/wei';
import { Contract as EthCallContract } from 'ethcall';
import { ethers } from 'ethers';
import { defaultAbiCoder } from 'ethers/lib/utils';
import request, { gql } from 'graphql-request';
import { orderBy } from 'lodash';
import { UNSUPPORTED_NETWORK } from '../common/errors';
import { KWENTA_TRACKING_CODE, ORDERS_FETCH_SIZE, SL_TP_MAX_SIZE } from '../constants/futures';
import { Period, PERIOD_IN_HOURS, PERIOD_IN_SECONDS } from '../constants/period';
import { ZERO_BIG_NUM } from '../constants/number';
import { getContractsByNetwork, getPerpsV2MarketMulticall } from '../contracts';
import PerpsMarketABI from '../contracts/abis/PerpsV2Market.json';
import SmartMarginAccountABI from '../contracts/abis/SmartMarginAccount.json';
import PerpsV2MarketInternal from '../contracts/PerpsV2MarketInternalV2';
import { SmartMarginAccount__factory, PerpsV2Market__factory } from '../contracts/types';
import { queryCrossMarginAccounts, querySmartMarginTransfers, queryFuturesTrades, queryIsolatedMarginTransfers, queryPositionHistory, queryTrades, queryCompletePositionHistory, queryFundingRateHistory, } from '../queries/futures';
import { AccountExecuteFunctions, ConditionalOrderTypeEnum, } from '../types/futures';
import { calculateTimestampForPeriod } from '../utils/date';
import { appAdjustedLeverage, calculateFundingRate, calculateVolumes, encodeConditionalOrderParams, encodeModidyMarketMarginParams, encodeSubmitOrderParams, formatDelayedOrder, formatPotentialTrade, getFuturesEndpoint, getMarketName, mapConditionalOrderFromContract, mapFuturesPosition, mapFuturesPositions, mapTrades, marketsForNetwork, MarketKeyByAsset, encodeCloseOrderParams, } from '../utils/futures';
import { getFuturesAggregateStats } from '../utils/subgraph';
import { getReasonFromCode } from '../utils/synths';
export default class FuturesService {
    constructor(sdk) {
        this.internalFuturesMarkets = {};
        this.getSkewAdjustedPrice = (price, marketAddress, marketKey) => __awaiter(this, void 0, void 0, function* () {
            const marketContract = new EthCallContract(marketAddress, PerpsMarketABI);
            const { PerpsV2MarketSettings } = this.sdk.context.multicallContracts;
            if (!PerpsV2MarketSettings)
                throw new Error(UNSUPPORTED_NETWORK);
            const [marketSkew, skewScale] = yield this.sdk.context.multicallProvider.all([
                marketContract.marketSkew(),
                PerpsV2MarketSettings.skewScale(formatBytes32String(marketKey)),
            ]);
            console.log("getSkewAdjustedPrice ==> price");
            console.log(price);
            console.log("getSkewAdjustedPrice ==> marketSkew");
            console.log(marketSkew);
            console.log("getSkewAdjustedPrice ==> skewScale");
            console.log(skewScale);
            const skewWei = wei(marketSkew);
            const scaleWei = wei(skewScale);
            console.log("getSkewAdjustedPrice ==> skewWei");
            console.log(skewWei);
            console.log("getSkewAdjustedPrice ==> scaleWei");
            console.log(scaleWei);
            let result = price.mul(skewWei.div(scaleWei).add(1));
            console.log("getSkewAdjustedPrice ==> result");
            console.log(result);
            return result;
        });
        this.sdk = sdk;
    }
    get futuresGqlEndpoint() {
        return getFuturesEndpoint(this.sdk.context.networkId);
    }
    getMarkets(networkOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("inside getMarkets");
            const enabledMarkets = marketsForNetwork((networkOverride === null || networkOverride === void 0 ? void 0 : networkOverride.networkId) || this.sdk.context.networkId, this.sdk.context.logError);
            console.log("getMarkets ==> enabledMarkets : ");
            console.log(enabledMarkets);
            const contracts = networkOverride && (networkOverride === null || networkOverride === void 0 ? void 0 : networkOverride.networkId) !== this.sdk.context.networkId
                ? getContractsByNetwork(networkOverride.networkId, networkOverride.provider)
                : this.sdk.context.contracts;
            console.log("getMarkets ==> contracts : ");
            console.log(contracts);
            const { SystemStatus } = contracts;
            const { ExchangeRates, PerpsV2MarketData, PerpsV2MarketSettings, } = this.sdk.context.multicallContracts;
            if (!SystemStatus || !ExchangeRates || !PerpsV2MarketData || !PerpsV2MarketSettings) {
                console.log("getMarkets ==> Unsupported Network for??? ");
                throw new Error(UNSUPPORTED_NETWORK);
            }
            const futuresData = yield this.sdk.context.multicallProvider.all([
                PerpsV2MarketData.allProxiedMarketSummaries(),
                PerpsV2MarketSettings.minInitialMargin(),
                PerpsV2MarketSettings.minKeeperFee(),
            ]);
            console.log("getMarkets ==> futuresData : ");
            console.log(futuresData);
            const { markets, minInitialMargin, minKeeperFee } = {
                markets: futuresData[0],
                minInitialMargin: futuresData[1],
                minKeeperFee: futuresData[2],
            };
            console.log("getMarkets ==> markets : ");
            console.log(markets);
            const filteredMarkets = markets.filter((m) => {
                const marketKey = parseBytes32String(m.key);
                const market = enabledMarkets.find((market) => {
                    return marketKey === market.key;
                });
                return !!market;
            });
            const marketKeys = filteredMarkets.map((m) => {
                return m.key;
            });
            const parametersCalls = marketKeys.map((key) => PerpsV2MarketSettings.parameters(key));
            let marketParameters = [];
            if (this.sdk.context.isMainnet) {
                marketParameters = yield this.sdk.context.multicallProvider.all(parametersCalls);
            }
            else {
                const firstResponses = yield this.sdk.context.multicallProvider.all(parametersCalls.slice(0, 20));
                const secondResponses = yield this.sdk.context.multicallProvider.all(parametersCalls.slice(20, parametersCalls.length));
                marketParameters = [
                    ...firstResponses,
                    ...secondResponses,
                ];
            }
            const { suspensions, reasons } = yield SystemStatus.getFuturesMarketSuspensions(marketKeys);
            const futuresMarkets = filteredMarkets.map(({ market, key, asset, currentFundingRate, currentFundingVelocity, feeRates, marketDebt, marketSkew, maxLeverage, marketSize, price, }, i) => ({
                market,
                marketKey: parseBytes32String(key),
                marketName: getMarketName(parseBytes32String(asset)),
                asset: parseBytes32String(asset),
                assetHex: asset,
                currentFundingRate: wei(currentFundingRate).div(24),
                currentFundingVelocity: wei(currentFundingVelocity).div(24 * 24),
                feeRates: {
                    makerFee: wei(feeRates.makerFee),
                    takerFee: wei(feeRates.takerFee),
                    makerFeeDelayedOrder: wei(feeRates.makerFeeDelayedOrder),
                    takerFeeDelayedOrder: wei(feeRates.takerFeeDelayedOrder),
                    makerFeeOffchainDelayedOrder: wei(feeRates.makerFeeOffchainDelayedOrder),
                    takerFeeOffchainDelayedOrder: wei(feeRates.takerFeeOffchainDelayedOrder),
                },
                openInterest: {
                    shortPct: wei(marketSize).eq(0)
                        ? 0
                        : wei(marketSize).sub(marketSkew).div('2').div(marketSize).toNumber(),
                    longPct: wei(marketSize).eq(0)
                        ? 0
                        : wei(marketSize).add(marketSkew).div('2').div(marketSize).toNumber(),
                    shortUSD: wei(marketSize).eq(0)
                        ? wei(0)
                        : wei(marketSize).sub(marketSkew).div('2').mul(price),
                    longUSD: wei(marketSize).eq(0)
                        ? wei(0)
                        : wei(marketSize).add(marketSkew).div('2').mul(price),
                    long: wei(marketSize).add(marketSkew).div('2'),
                    short: wei(marketSize).sub(marketSkew).div('2'),
                },
                marketDebt: wei(marketDebt),
                marketSkew: wei(marketSkew),
                contractMaxLeverage: wei(maxLeverage),
                appMaxLeverage: appAdjustedLeverage(wei(maxLeverage)),
                marketSize: wei(marketSize),
                marketLimitUsd: wei(marketParameters[i].maxMarketValue).mul(wei(price)),
                marketLimitNative: wei(marketParameters[i].maxMarketValue),
                minInitialMargin: wei(minInitialMargin),
                keeperDeposit: wei(minKeeperFee),
                isSuspended: suspensions[i],
                marketClosureReason: getReasonFromCode(reasons[i]),
                settings: {
                    maxMarketValue: wei(marketParameters[i].maxMarketValue),
                    skewScale: wei(marketParameters[i].skewScale),
                    delayedOrderConfirmWindow: wei(marketParameters[i].delayedOrderConfirmWindow, 0).toNumber(),
                    offchainDelayedOrderMinAge: wei(marketParameters[i].offchainDelayedOrderMinAge, 0).toNumber(),
                    offchainDelayedOrderMaxAge: wei(marketParameters[i].offchainDelayedOrderMaxAge, 0).toNumber(),
                    minDelayTimeDelta: wei(marketParameters[i].minDelayTimeDelta, 0).toNumber(),
                    maxDelayTimeDelta: wei(marketParameters[i].maxDelayTimeDelta, 0).toNumber(),
                },
            }));
            return futuresMarkets;
        });
    }
    // TODO: types
    // TODO: Improve the API for fetching positions
    getFuturesPositions(address, // Cross margin or EOA address
    futuresMarkets) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketDataContract = this.sdk.context.multicallContracts.PerpsV2MarketData;
            if (!this.sdk.context.isL2 || !marketDataContract) {
                throw new Error(UNSUPPORTED_NETWORK);
            }
            const positionCalls = [];
            const liquidationCalls = [];
            for (const { address: marketAddress, marketKey } of futuresMarkets) {
                positionCalls.push(marketDataContract.positionDetailsForMarketKey(formatBytes32String(marketKey), address));
                const marketContract = new EthCallContract(marketAddress, PerpsMarketABI);
                liquidationCalls.push(marketContract.canLiquidate(address));
            }
            // TODO: Combine these two?
            const positionDetails = (yield this.sdk.context.multicallProvider.all(positionCalls));
            const canLiquidateState = (yield this.sdk.context.multicallProvider.all(liquidationCalls));
            // map the positions using the results
            const positions = yield Promise.all(positionDetails.map((position, ind) => __awaiter(this, void 0, void 0, function* () {
                const canLiquidate = canLiquidateState[ind];
                const marketKey = futuresMarkets[ind].marketKey;
                const asset = futuresMarkets[ind].asset;
                console.log("DF - fetch getFuturesPositions ==> position : ", position);
                const result = mapFuturesPosition(position, canLiquidate, asset, marketKey);
                console.log("DF - fetch getFuturesPositions ==> result : ", result);
                return result;
            })));
            return positions;
        });
    }
    getMarketFundingRatesHistory(marketAsset, periodLength = PERIOD_IN_SECONDS.TWO_WEEKS) {
        return __awaiter(this, void 0, void 0, function* () {
            const minTimestamp = Math.floor(Date.now() / 1000) - periodLength;
            return queryFundingRateHistory(this.sdk, marketAsset, minTimestamp);
        });
    }
    getAverageFundingRates(markets, prices, period) {
        return __awaiter(this, void 0, void 0, function* () {
            const fundingRateInputs = markets.map(({ asset, market, currentFundingRate }) => {
                const price = prices[asset];
                return {
                    marketAddress: market,
                    marketKey: MarketKeyByAsset[asset],
                    price: price,
                    currentFundingRate: currentFundingRate,
                };
            });
            const fundingRateQueries = fundingRateInputs.map(({ marketAddress, marketKey }) => {
                return gql `
					# last before timestamp
					${marketKey}_first: fundingRateUpdates(
						first: 1
						where: { market: "${marketAddress}", timestamp_lt: $minTimestamp }
						orderBy: sequenceLength
						orderDirection: desc
					) {
						timestamp
						funding
					}

					# first after timestamp
					${marketKey}_next: fundingRateUpdates(
						first: 1
						where: { market: "${marketAddress}", timestamp_gt: $minTimestamp }
						orderBy: sequenceLength
						orderDirection: asc
					) {
						timestamp
						funding
					}

					# latest update
					${marketKey}_latest: fundingRateUpdates(
						first: 1
						where: { market: "${marketAddress}" }
						orderBy: sequenceLength
						orderDirection: desc
					) {
						timestamp
						funding
					}
				`;
            });
            const periodLength = PERIOD_IN_SECONDS[period];
            const minTimestamp = Math.floor(Date.now() / 1000) - periodLength;
            const marketFundingResponses = yield request(this.futuresGqlEndpoint, gql `
			query fundingRateUpdates($minTimestamp: BigInt!) {
				${fundingRateQueries.reduce((acc, curr) => {
                return acc + curr;
            })}
			}
		`, { minTimestamp: minTimestamp });
            const periodTitle = period === Period.ONE_HOUR ? '1H Funding Rate' : 'Funding Rate';
            const fundingRateResponses = fundingRateInputs.map(({ marketKey, currentFundingRate, price }) => {
                if (!price)
                    return null;
                const marketResponses = [
                    marketFundingResponses[`${marketKey}_first`],
                    marketFundingResponses[`${marketKey}_next`],
                    marketFundingResponses[`${marketKey}_latest`],
                ];
                const responseFilt = marketResponses
                    .filter((value) => value.length > 0)
                    .map((entry) => entry[0])
                    .sort((a, b) => a.timestamp - b.timestamp);
                const fundingRate = responseFilt && !!currentFundingRate
                    ? calculateFundingRate(minTimestamp, periodLength, responseFilt, price, currentFundingRate)
                    : currentFundingRate !== null && currentFundingRate !== void 0 ? currentFundingRate : null;
                const fundingPeriod = responseFilt && !!currentFundingRate ? periodTitle : 'Inst. Funding Rate';
                const fundingRateResponse = {
                    asset: marketKey,
                    fundingTitle: fundingPeriod,
                    fundingRate: fundingRate,
                };
                return fundingRateResponse;
            });
            return fundingRateResponses.filter((funding) => !!funding);
        });
    }
    getDailyVolumes() {
        return __awaiter(this, void 0, void 0, function* () {
            const minTimestamp = Math.floor(calculateTimestampForPeriod(PERIOD_IN_HOURS.ONE_DAY) / 1000);
            const response = yield getFuturesAggregateStats(this.futuresGqlEndpoint, {
                first: 999999,
                where: {
                    period: `${PERIOD_IN_SECONDS.ONE_HOUR}`,
                    timestamp_gte: `${minTimestamp}`,
                },
            }, {
                id: true,
                marketKey: true,
                asset: true,
                volume: true,
                trades: true,
                timestamp: true,
                period: true,
                feesCrossMarginAccounts: true,
                feesKwenta: true,
                feesSynthetix: true,
            });
            return response ? calculateVolumes(response) : {};
        });
    }
    getCrossMarginAccounts(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getCrossMarginAccounts ==> ");
            const address = walletAddress !== null && walletAddress !== void 0 ? walletAddress : this.sdk.context.walletAddress;
            console.log("getCrossMarginAccounts ==> address ");
            console.log(address);
            return yield queryCrossMarginAccounts(this.sdk, address);
        });
    }
    getIsolatedMarginTransfers(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const address = walletAddress !== null && walletAddress !== void 0 ? walletAddress : this.sdk.context.walletAddress;
            return queryIsolatedMarginTransfers(this.sdk, address);
        });
    }
    getCrossMarginTransfers(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const address = walletAddress !== null && walletAddress !== void 0 ? walletAddress : this.sdk.context.walletAddress;
            return querySmartMarginTransfers(this.sdk, address);
        });
    }
    getCrossMarginAccountBalance(crossMarginAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getCrossMarginAccountBalance ==> this.sdk.context.provider");
            console.log(this.sdk.context.provider);
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.provider);
            console.log("getCrossMarginAccountBalance ==> crossMarginAccountContract.address");
            console.log(crossMarginAccountContract.address);
            const freeMargin = yield crossMarginAccountContract.freeMargin();
            return wei(freeMargin);
        });
    }
    getCrossMarginBalanceInfo(walletAddress, crossMarginAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.provider);
            const { SUSD } = this.sdk.context.contracts;
            if (!SUSD)
                throw new Error(UNSUPPORTED_NETWORK);
            console.log("getCrossMarginBalanceInfo ==> crossMarginAddress");
            console.log(crossMarginAddress);
            console.log("getCrossMarginBalanceInfo ==> crossMarginAccountContract.address");
            console.log(crossMarginAccountContract.address);
            // TODO: EthCall
            const [freeMargin, keeperEthBal, walletEthBal, allowance] = yield Promise.all([
                crossMarginAccountContract.freeMargin(),
                this.sdk.context.provider.getBalance(crossMarginAddress),
                this.sdk.context.provider.getBalance(walletAddress),
                SUSD.allowance(walletAddress, crossMarginAccountContract.address),
            ]);
            return {
                freeMargin: wei(freeMargin),
                keeperEthBal: wei(keeperEthBal),
                walletEthBal: wei(walletEthBal),
                allowance: wei(allowance),
            };
        });
    }
    getConditionalOrders(account) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("getConditionalOrders ==> ");
            const crossMarginAccountMultiCall = new EthCallContract(account, SmartMarginAccountABI);
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(account, this.sdk.context.provider);
            const orders = [];
            const orderIdBigNum = yield crossMarginAccountContract.conditionalOrderId();
            const orderId = orderIdBigNum.toNumber();
            // Limit to the latest 500
            const start = orderId > ORDERS_FETCH_SIZE ? orderId - ORDERS_FETCH_SIZE : 0;
            const orderCalls = Array(orderId)
                .fill(0)
                .map((_, i) => crossMarginAccountMultiCall.getConditionalOrder(start + i));
            console.log("getConditionalOrders ==> orderCalls");
            console.log(orderCalls);
            const contractOrders = (yield this.sdk.context.multicallProvider.all(orderCalls));
            console.log("getConditionalOrders ==> contractOrders");
            console.log(contractOrders);
            for (let i = 0; i < orderId; i++) {
                const contractOrder = contractOrders[i];
                // Checks if the order is still pending
                // Orders are never removed but all values set to zero so we check a zero value on price to filter pending
                if (contractOrder && contractOrder.targetPrice.gt(0)) {
                    const order = mapConditionalOrderFromContract(Object.assign(Object.assign({}, contractOrder), { id: start + i }), account);
                    orders.push(order);
                }
            }
            return orderBy(orders, ['id'], 'desc');
        });
    }
    // Perps V2 read functions
    getDelayedOrder(account, marketAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.provider);
            const order = yield market.delayedOrders(account);
            return formatDelayedOrder(account, marketAddress, order);
        });
    }
    getDelayedOrders(account, marketAddresses) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketContracts = marketAddresses.map(getPerpsV2MarketMulticall);
            const orders = (yield this.sdk.context.multicallProvider.all(marketContracts.map((market) => market.delayedOrders(account))));
            return orders.map((order, ind) => {
                return formatDelayedOrder(account, marketAddresses[ind], order);
            });
        });
    }
    getIsolatedTradePreview(marketAddress, marketKey, orderType, inputs) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.provider);
            console.log("getIsolatedTradePreview ==> inputs.sizeDelta");
            console.log(inputs.sizeDelta);
            console.log("getIsolatedTradePreview ==> inputs.price");
            console.log(inputs.price);
            inputs.sizeDelta = inputs.sizeDelta.div(1e18);
            const details = yield market.postTradeDetails(inputs.sizeDelta.toBN(), inputs.price.toBN(), orderType, this.sdk.context.walletAddress);
            const skewAdjustedPrice = yield this.getSkewAdjustedPrice(inputs.price, marketAddress, marketKey);
            return formatPotentialTrade(details, skewAdjustedPrice, inputs.sizeDelta, inputs.leverageSide);
        });
    }
    getCrossMarginTradePreview(crossMarginAccount, marketKey, marketAddress, tradeParams) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketInternal = this.getInternalFuturesMarket(marketAddress, marketKey);
            //marketInternal._onChainData.assetPrice = marketInternal._onChainData.assetPrice.div(1e9);
            console.log("getCrossMarginTradePreview ==> marketInternal");
            console.log(marketInternal);
            console.log("getCrossMarginTradePreview ==> tradeParams.sizeDeltaA");
            console.log(tradeParams.sizeDelta);
            console.log("getCrossMarginTradePreview ==> tradeParams.marginDelta");
            console.log(tradeParams.marginDelta);
            //tradeParams.sizeDelta = tradeParams.sizeDelta.div("1000000000").div("10");
            //tradeParams.sizeDelta = tradeParams.sizeDelta.div("1000000000").div("1000000000");
            tradeParams.sizeDelta = tradeParams.sizeDelta.div("1e18");
            //tradeParams.orderPrice = tradeParams.orderPrice.mul(1e9);
            //tradeParams.marginDelta = tradeParams.marginDelta.div("1e18");
            //tradeParams.orderPrice = tradeParams.orderPrice.mul(1e9);
            //tradeParams.marginDelta = tradeParams.marginDelta.mul("1000000000").mul("1000000000").mul("10");
            console.log("getCrossMarginTradePreview ==> tradeParams.orderPrice");
            console.log(tradeParams.orderPrice);
            //tradeParams.orderPrice = tradeParams.orderPrice.div("1e18");
            console.log("getCrossMarginTradePreview ==> tradeParams.sizeDeltaB");
            console.log(tradeParams.sizeDelta);
            const preview = yield marketInternal.getTradePreview(crossMarginAccount, tradeParams.sizeDelta.toBN(), tradeParams.marginDelta.toBN(), tradeParams.orderPrice.toBN(), tradeParams.takerFeeDelayedOrder.toBN(), tradeParams.makerFeeDelayedOrder.toBN());
            console.log("getCrossMarginTradePreview ==> preview");
            console.log(preview);
            const skewAdjustedPrice = yield this.getSkewAdjustedPrice(tradeParams.orderPrice, marketAddress, marketKey);
            console.log("getCrossMarginTradePreview ==> skewAdjustedPrice");
            console.log(skewAdjustedPrice);
            return formatPotentialTrade(preview, skewAdjustedPrice, tradeParams.sizeDelta, tradeParams.leverageSide);
        });
    }
    getCrossMarginKeeperBalance(account) {
        return __awaiter(this, void 0, void 0, function* () {
            const bal = yield this.sdk.context.provider.getBalance(account);
            return wei(bal);
        });
    }
    getCrossMarginMinimumMargin(marketKey, marketAddress, sizeDelta, tradePrice) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const marketInternal = this.getInternalFuturesMarket(marketAddress, marketKey);
            const exchangeMaxDynamicFee = yield ((_a = this.sdk.context.contracts.SystemSettings) === null || _a === void 0 ? void 0 : _a.exchangeMaxDynamicFee());
            return yield marketInternal.getMinimumMargin(sizeDelta, tradePrice, exchangeMaxDynamicFee !== null && exchangeMaxDynamicFee !== void 0 ? exchangeMaxDynamicFee : ZERO_BIG_NUM);
        });
    }
    getCrossMarginMinInitialMargin(marketKey, marketAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketInternal = this.getInternalFuturesMarket(marketAddress, marketKey);
            return yield marketInternal.getMinInitialMargin();
        });
    }
    getPositionHistory(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryPositionHistory(this.sdk, walletAddress);
            return response ? mapFuturesPositions(response) : [];
        });
    }
    getCompletePositionHistory(walletAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryCompletePositionHistory(this.sdk, walletAddress);
            return response ? mapFuturesPositions(response) : [];
        });
    }
    getAssetPriceData(marketKey, marketAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketInternal = this.getInternalFuturesMarket(marketAddress, marketKey);
            return yield marketInternal.getAsseetPrice();
        });
    }
    // TODO: Support pagination
    getTradesForMarket(marketAsset, walletAddress, accountType, pageLength = 16) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryTrades(this.sdk, {
                marketAsset,
                walletAddress,
                accountType,
                pageLength,
            });
            return response ? mapTrades(response) : [];
        });
    }
    getAllTrades(walletAddress, accountType, pageLength = 16) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryTrades(this.sdk, {
                walletAddress,
                accountType,
                pageLength,
            });
            return response ? mapTrades(response) : [];
        });
    }
    getIdleMarginInMarkets(accountOrEoa) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const markets = (_a = this.markets) !== null && _a !== void 0 ? _a : (yield this.getMarkets());
            const filteredMarkets = markets.filter((m) => !m.isSuspended);
            const marketParams = (_b = filteredMarkets === null || filteredMarkets === void 0 ? void 0 : filteredMarkets.map((m) => ({
                asset: m.asset,
                marketKey: m.marketKey,
                address: m.market,
            }))) !== null && _b !== void 0 ? _b : [];
            const positions = yield this.getFuturesPositions(accountOrEoa, marketParams);
            const positionsWithIdleMargin = positions.filter((p) => { var _a; return !((_a = p.position) === null || _a === void 0 ? void 0 : _a.size.abs().gt(0)) && p.remainingMargin.gt(0); });
            const idleInMarkets = positionsWithIdleMargin.reduce((acc, p) => acc.add(p.remainingMargin), wei(0));
            return {
                totalIdleInMarkets: idleInMarkets,
                marketsWithIdleMargin: positionsWithIdleMargin.reduce((acc, p) => {
                    const market = filteredMarkets.find((m) => m.marketKey === p.marketKey);
                    if (market) {
                        acc.push({
                            marketAddress: market.market,
                            marketKey: market.marketKey,
                            position: p,
                        });
                    }
                    return acc;
                }, []),
            };
        });
    }
    getIdleMargin(eoa, account) {
        return __awaiter(this, void 0, void 0, function* () {
            const idleMargin = yield this.getIdleMarginInMarkets(account || eoa);
            const { susdWalletBalance } = yield this.sdk.synths.getSynthBalances(eoa);
            return {
                total: idleMargin.totalIdleInMarkets.add(susdWalletBalance),
                marketsTotal: idleMargin.totalIdleInMarkets,
                walletTotal: susdWalletBalance,
                marketsWithMargin: idleMargin.marketsWithIdleMargin,
            };
        });
    }
    // This is on an interval of 15 seconds.
    getFuturesTrades(marketKey, minTs, maxTs) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield queryFuturesTrades(this.sdk, marketKey, minTs, maxTs);
            return response ? mapTrades(response) : null;
        });
    }
    // TODO: Get delayed order fee
    getOrderFee(marketAddress, size) {
        return __awaiter(this, void 0, void 0, function* () {
            const marketContract = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            const orderFee = yield marketContract.orderFee(size.toBN(), 0);
            return wei(orderFee.fee);
        });
    }
    // Contract mutations
    approveCrossMarginDeposit(crossMarginAddress, amount = BigNumber.from(ethers.constants.MaxUint256)) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.SUSD)
                throw new Error(UNSUPPORTED_NETWORK);
            return this.sdk.transactions.createContractTxn(this.sdk.context.contracts.SUSD, 'approve', [
                crossMarginAddress,
                amount,
            ]);
        });
    }
    depositCrossMarginAccount(crossMarginAddress, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                [AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN],
                [defaultAbiCoder.encode(['int256'], [amount.toBN()])],
            ]);
        });
    }
    withdrawCrossMarginAccount(crossMarginAddress, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            const { commands, inputs } = yield this.batchIdleMarketMarginSweeps(crossMarginAddress);
            commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
            inputs.push(defaultAbiCoder.encode(['int256'], [amount.neg().toBN()]));
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                commands,
                inputs,
            ]);
        });
    }
    modifySmartMarginMarketMargin(crossMarginAddress, marketAddress, marginDelta) {
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            const commands = [];
            const inputs = [];
            if (marginDelta.gt(0)) {
                console.log("modifySmartMarginMarketMargin ==> crossMarginAddress");
                console.log(crossMarginAddress);
                const freeMargin = yield this.getCrossMarginAccountBalance(crossMarginAddress);
                /*
                if (marginDelta.gt(freeMargin)) {
                    // Margin delta bigger than account balance,
                    // need to pull some from the users wallet or idle margin
                    const {
                        commands: sweepCommands,
                        inputs: sweepInputs,
                        idleMargin,
                    } = await this.batchIdleMarketMarginSweeps(crossMarginAddress)
    
                    commands.push(...sweepCommands)
                    inputs.push(...sweepInputs)
    
    
                    console.log("modifySmartMarginMarketMargin ==> idleMargin");
                    console.log(idleMargin);
                    console.log("modifySmartMarginMarketMargin ==> freeMargin");
                    console.log(freeMargin);
    
    
    
                    const totalFreeMargin = idleMargin.totalIdleInMarkets.add(freeMargin)
                    console.log("modifySmartMarginMarketMargin ==> totalFreeMargin");
                    console.log(totalFreeMargin);
                    const depositAmount = marginDelta.gt(totalFreeMargin)
                        ? marginDelta.sub(totalFreeMargin).abs()
                        : wei(0)
    
                        console.log("modifySmartMarginMarketMargin ==> depositAmount");
                        console.log(depositAmount);
                    if (depositAmount.gt(0)) {
                        // If we don't have enough from idle market margin then we pull from the wallet
                        commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN)
                        inputs.push(defaultAbiCoder.encode(['int256'], [depositAmount.toBN()]))
                    }
                }
                */
                console.log("modifySmartMarginMarketMargin ==> marketAddress");
                console.log(marketAddress);
                console.log("modifySmartMarginMarketMargin ==> marginDelta");
                console.log(marginDelta);
                commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
                inputs.push(defaultAbiCoder.encode(['int256'], [marginDelta.toBN()]));
                commands.push(AccountExecuteFunctions.PERPS_V2_MODIFY_MARGIN);
                inputs.push(encodeModidyMarketMarginParams(marketAddress, marginDelta));
            }
            else {
                console.log("modifySmartMarginMarketMargin ==> Account Modify Margin");
                console.log(marketAddress);
                console.log("modifySmartMarginMarketMargin ==> Account Modify Margin marginDelta");
                console.log(marginDelta);
                commands.push(AccountExecuteFunctions.PERPS_V2_MODIFY_MARGIN);
                inputs.push(encodeModidyMarketMarginParams(marketAddress, marginDelta));
                commands.push(AccountExecuteFunctions.ACCOUNT_MODIFY_MARGIN);
                inputs.push(defaultAbiCoder.encode(['int256'], [marginDelta.toBN()]));
            }
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                commands,
                inputs,
            ]);
        });
    }
    modifySmartMarginPositionSize(crossMarginAddress, market, sizeDelta, desiredFillPrice, cancelPendingReduceOrders) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("modifySmartMarginPositionSize ==> ");
            const commands = [];
            const inputs = [];
            if (cancelPendingReduceOrders) {
                const existingOrders = yield this.getConditionalOrders(crossMarginAddress);
                const existingOrdersForMarket = existingOrders.filter((o) => o.marketKey === market.key && o.reduceOnly);
                // Remove all pending reduce only orders if instructed
                existingOrdersForMarket.forEach((o) => {
                    commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                    inputs.push(defaultAbiCoder.encode(['uint256'], [o.id]));
                });
            }
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_DELAYED_ORDER);
            inputs.push(encodeSubmitOrderParams(market.address, sizeDelta, 0, desiredFillPrice));
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                commands,
                inputs,
            ]);
        });
    }
    depositIsolatedMargin(marketAddress, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            const txn = this.sdk.transactions.createContractTxn(market, 'transferMargin', [amount.toBN()]);
            return txn;
        });
    }
    withdrawIsolatedMargin(marketAddress, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            const txn = this.sdk.transactions.createContractTxn(market, 'transferMargin', [
                amount.neg().toBN(),
            ]);
            return txn;
        });
    }
    closeIsolatedPosition(marketAddress, priceImpactDelta) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            return market.closePositionWithTracking(priceImpactDelta.toBN(), KWENTA_TRACKING_CODE);
        });
    }
    submitIsolatedMarginOrder(marketAddress, sizeDelta, priceImpactDelta) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            return this.sdk.transactions.createContractTxn(market, 'submitOffchainDelayedOrderWithTracking', [sizeDelta.toBN(), priceImpactDelta.toBN(), KWENTA_TRACKING_CODE]);
        });
    }
    cancelDelayedOrder(marketAddress, account, isOffchain) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            return isOffchain
                ? market.cancelOffchainDelayedOrder(account)
                : market.cancelDelayedOrder(account);
        });
    }
    executeDelayedOrder(marketAddress, account) {
        return __awaiter(this, void 0, void 0, function* () {
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            console.log("sdk ==> executeDelayedOrder ==> account: ");
            console.log(account);
            return market.executeDelayedOrder(account);
        });
    }
    executeDelayedOffchainOrder(marketKey, marketAddress, account) {
        return __awaiter(this, void 0, void 0, function* () {
            const { Pyth } = this.sdk.context.contracts;
            const market = PerpsV2Market__factory.connect(marketAddress, this.sdk.context.signer);
            if (!Pyth)
                throw new Error(UNSUPPORTED_NETWORK);
            // get price update data
            const priceUpdateData = yield this.sdk.prices.getPythPriceUpdateData(marketKey);
            const updateFee = yield Pyth.getUpdateFee(priceUpdateData);
            return market.executeOffchainDelayedOrder(account, priceUpdateData, { value: updateFee });
        });
    }
    createCrossMarginAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("createCrossMarginAccount ==> ");
            if (!this.sdk.context.contracts.SmartMarginAccountFactory)
                throw new Error(UNSUPPORTED_NETWORK);
            return this.sdk.transactions.createContractTxn(this.sdk.context.contracts.SmartMarginAccountFactory, 'newAccount', []);
        });
    }
    submitCrossMarginOrder(market, walletAddress, crossMarginAddress, order, options) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            const commands = [];
            const inputs = [];
            if (options === null || options === void 0 ? void 0 : options.cancelExpiredDelayedOrders) {
                commands.push(AccountExecuteFunctions.PERPS_V2_CANCEL_DELAYED_ORDER);
                inputs.push(defaultAbiCoder.encode(['address'], [market.address]));
            }
            const idleMargin = yield this.getIdleMargin(walletAddress, crossMarginAddress);
            const freeMargin = yield this.getCrossMarginAccountBalance(crossMarginAddress);
            // Sweep idle margin from other markets to account
            /*
                    if (idleMargin.marketsTotal.gt(0)) {
                        idleMargin.marketsWithMargin.forEach((m) => {
                            commands.push(AccountExecuteFunctions.PERPS_V2_WITHDRAW_ALL_MARGIN)
                            inputs.push(defaultAbiCoder.encode(['address'], [m.marketAddress]))
                        })
                    }
                    */
            let arrIdleMktMargin = idleMargin.marketsWithMargin.filter((m) => { return market.key === m.marketKey; });
            let idleMktMargin = arrIdleMktMargin[0];
            console.log("submitCrossMarginOrder ==> idleMktMargin");
            console.log(idleMktMargin);
            console.log("submitCrossMarginOrder ==> ");
            if (order.sizeDelta.abs().gt(0)) {
                if (!order.conditionalOrderInputs) {
                    commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_DELAYED_ORDER);
                    order.sizeDelta = order.sizeDelta.div(1e18);
                    console.log("submitCrossMarginOrder ==> order.sizeDelta");
                    console.log(order.sizeDelta);
                    console.log("submitCrossMarginOrder ==> order.desiredFillPrice");
                    console.log(order.desiredFillPrice);
                    //order.sizeDelta = order.sizeDelta.div("1000000000").div("10");
                    inputs.push(
                    //encodeSubmitOffchainOrderParams(market.address, order.sizeDelta, order.desiredFillPrice)
                    encodeSubmitOrderParams(market.address, order.sizeDelta, 0, order.desiredFillPrice));
                }
                else {
                    commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                    const params = encodeConditionalOrderParams(market.key, {
                        marginDelta: order.marginDelta,
                        sizeDelta: order.sizeDelta,
                        price: order.conditionalOrderInputs.price,
                        desiredFillPrice: order.desiredFillPrice,
                    }, order.conditionalOrderInputs.orderType, order.conditionalOrderInputs.reduceOnly);
                    inputs.push(params);
                }
            }
            const existingOrders = yield this.getConditionalOrders(crossMarginAddress);
            const existingOrdersForMarket = existingOrders.filter((o) => o.marketKey === market.key && o.reduceOnly);
            if (order.takeProfit || order.stopLoss) {
                if (order.takeProfit) {
                    commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                    const encodedParams = encodeConditionalOrderParams(market.key, {
                        marginDelta: wei(0),
                        sizeDelta: order.takeProfit.sizeDelta,
                        price: order.takeProfit.price,
                        desiredFillPrice: order.takeProfit.desiredFillPrice,
                    }, ConditionalOrderTypeEnum.LIMIT, true);
                    inputs.push(encodedParams);
                }
                if (order.stopLoss) {
                    commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                    const encodedParams = encodeConditionalOrderParams(market.key, {
                        marginDelta: wei(0),
                        sizeDelta: order.stopLoss.sizeDelta,
                        price: order.stopLoss.price,
                        desiredFillPrice: order.stopLoss.desiredFillPrice,
                    }, ConditionalOrderTypeEnum.STOP, true);
                    inputs.push(encodedParams);
                }
            }
            if (options === null || options === void 0 ? void 0 : options.cancelPendingReduceOrders) {
                // Remove all pending reduce only orders if instructed
                existingOrdersForMarket.forEach((o) => {
                    commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                    inputs.push(defaultAbiCoder.encode(['uint256'], [o.id]));
                });
            }
            else {
                if (order.takeProfit) {
                    // Remove only existing take profit to overwrite
                    const existingTakeProfits = existingOrdersForMarket.filter((o) => o.size.abs().eq(SL_TP_MAX_SIZE) && o.orderType === ConditionalOrderTypeEnum.LIMIT);
                    if (existingTakeProfits.length) {
                        existingTakeProfits.forEach((tp) => {
                            commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                            inputs.push(defaultAbiCoder.encode(['uint256'], [tp.id]));
                        });
                    }
                }
                if (order.stopLoss) {
                    // Remove only existing stop loss to overwrite
                    const existingStopLosses = existingOrdersForMarket.filter((o) => o.size.abs().eq(SL_TP_MAX_SIZE) && o.orderType === ConditionalOrderTypeEnum.STOP);
                    if (existingStopLosses.length) {
                        existingStopLosses.forEach((sl) => {
                            commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                            inputs.push(defaultAbiCoder.encode(['uint256'], [sl.id]));
                        });
                    }
                }
            }
            console.log("submitCrossMarginOrder ==> crossMarginAccountContract ");
            console.log(crossMarginAccountContract);
            console.log("submitCrossMarginOrder ==> crossMarginAccountContract |  commands");
            console.log(commands);
            console.log("submitCrossMarginOrder ==> crossMarginAccountContract | inputs");
            console.log(inputs);
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [commands, inputs], {
                value: (_b = (_a = order.keeperEthDeposit) === null || _a === void 0 ? void 0 : _a.toBN()) !== null && _b !== void 0 ? _b : '0',
            });
        });
    }
    closeCrossMarginPosition(market, crossMarginAddress, desiredFillPrice) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("closeCrossMarginPosition ==> ");
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            const commands = [];
            const inputs = [];
            const existingOrders = yield this.getConditionalOrders(crossMarginAddress);
            console.log("closeCrossMarginPosition ==> existingOrders");
            console.log(existingOrders);
            const existingOrdersForMarket = existingOrders.filter((o) => o.marketKey === market.key && o.reduceOnly);
            existingOrdersForMarket.forEach((o) => {
                commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                inputs.push(defaultAbiCoder.encode(['uint256'], [o.id]));
            });
            console.log("closeCrossMarginPosition ==> desiredFillPrice");
            console.log(desiredFillPrice);
            //commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_CLOSE_OFFCHAIN_DELAYED_ORDER)
            commands.push(AccountExecuteFunctions.PERPS_V2_SUBMIT_CLOSE_DELAYED_ORDER);
            //inputs.push(encodeCloseOffchainOrderParams(market.address, desiredFillPrice))
            inputs.push(encodeCloseOrderParams(market.address, 0, desiredFillPrice));
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                commands,
                inputs,
            ]);
        });
    }
    cancelConditionalOrder(crossMarginAddress, orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                [AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER],
                [defaultAbiCoder.encode(['uint256'], [orderId])],
            ]);
        });
    }
    withdrawAccountKeeperBalance(crossMarginAddress, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [
                [AccountExecuteFunctions.ACCOUNT_WITHDRAW_ETH],
                [defaultAbiCoder.encode(['uint256'], [amount.toBN()])],
            ]);
        });
    }
    updateStopLossAndTakeProfit(marketKey, crossMarginAddress, params) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const crossMarginAccountContract = SmartMarginAccount__factory.connect(crossMarginAddress, this.sdk.context.signer);
            const commands = [];
            const inputs = [];
            if (params.takeProfit || params.stopLoss) {
                const existingOrders = yield this.getConditionalOrders(crossMarginAddress);
                const existingOrdersForMarket = existingOrders.filter((o) => o.marketKey === marketKey);
                const existingStopLosses = existingOrdersForMarket.filter((o) => o.size.abs().eq(SL_TP_MAX_SIZE) &&
                    o.reduceOnly &&
                    o.orderType === ConditionalOrderTypeEnum.STOP);
                const existingTakeProfits = existingOrdersForMarket.filter((o) => o.size.abs().eq(SL_TP_MAX_SIZE) &&
                    o.reduceOnly &&
                    o.orderType === ConditionalOrderTypeEnum.LIMIT);
                if (params.takeProfit) {
                    if (existingTakeProfits.length) {
                        existingTakeProfits.forEach((tp) => {
                            commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                            inputs.push(defaultAbiCoder.encode(['uint256'], [tp.id]));
                        });
                    }
                    if (!params.takeProfit.isCancelled) {
                        commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                        const encodedParams = encodeConditionalOrderParams(marketKey, {
                            marginDelta: wei(0),
                            sizeDelta: params.takeProfit.sizeDelta,
                            price: params.takeProfit.price,
                            desiredFillPrice: params.takeProfit.desiredFillPrice,
                        }, ConditionalOrderTypeEnum.LIMIT, true);
                        inputs.push(encodedParams);
                    }
                }
                if (params.stopLoss) {
                    if (existingStopLosses.length) {
                        existingStopLosses.forEach((sl) => {
                            commands.push(AccountExecuteFunctions.GELATO_CANCEL_CONDITIONAL_ORDER);
                            inputs.push(defaultAbiCoder.encode(['uint256'], [sl.id]));
                        });
                    }
                    if (!params.stopLoss.isCancelled) {
                        commands.push(AccountExecuteFunctions.GELATO_PLACE_CONDITIONAL_ORDER);
                        const encodedParams = encodeConditionalOrderParams(marketKey, {
                            marginDelta: wei(0),
                            sizeDelta: params.stopLoss.sizeDelta,
                            price: params.stopLoss.price,
                            desiredFillPrice: params.stopLoss.desiredFillPrice,
                        }, ConditionalOrderTypeEnum.STOP, true);
                        inputs.push(encodedParams);
                    }
                }
            }
            return this.sdk.transactions.createContractTxn(crossMarginAccountContract, 'execute', [commands, inputs], {
                value: (_b = (_a = params.keeperEthDeposit) === null || _a === void 0 ? void 0 : _a.toBN()) !== null && _b !== void 0 ? _b : '0',
            });
        });
    }
    // Private methods
    getInternalFuturesMarket(marketAddress, marketKey) {
        var _a;
        let market = (_a = this.internalFuturesMarkets[this.sdk.context.networkId]) === null || _a === void 0 ? void 0 : _a[marketAddress];
        if (market)
            return market;
        market = new PerpsV2MarketInternal(this.sdk, this.sdk.context.provider, marketKey, marketAddress);
        this.internalFuturesMarkets = {
            [this.sdk.context.networkId]: Object.assign(Object.assign({}, this.internalFuturesMarkets[this.sdk.context.networkId]), { [marketAddress]: market }),
        };
        return market;
    }
    batchIdleMarketMarginSweeps(crossMarginAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            const idleMargin = yield this.getIdleMarginInMarkets(crossMarginAddress);
            const commands = [];
            const inputs = [];
            // Sweep idle margin from other markets to account
            if (idleMargin.totalIdleInMarkets.gt(0)) {
                idleMargin.marketsWithIdleMargin.forEach((m) => {
                    commands.push(AccountExecuteFunctions.PERPS_V2_WITHDRAW_ALL_MARGIN);
                    inputs.push(defaultAbiCoder.encode(['address'], [m.marketAddress]));
                });
            }
            return { commands, inputs, idleMargin };
        });
    }
}
