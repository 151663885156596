import { FundingRatePeriods, FundingRateMultipliers } from 'state/futures/types'

export const FUNDING_RATE_PERIODS: FundingRatePeriods = {
	1: '1H',
	8: '8H',
	24: '24H',
	8766: '1Y',
} 
export const FUNDING_RATE_MULTIPLIER: FundingRateMultipliers = {
	'1H': 1,
	'8H': 8,
	'24H': 24,
	'1Y': 8766,
}

export const YEARLY_FUNDING_MULTIPLIER = 8766

export const TIME_RANGE_OPTIONS = [
	{
		value: 60 * 60 * 24,
		label: '1D',
	},
	{
		value: 60 * 60 * 24 * 3,
		label: '3D',
	},
	{
		value: 60 * 60 * 24 * 7,
		label: '1W',
	},
	{
		value: 60 * 60 * 24 * 14,
		label: '2W',
	},
	{
		value: 60 * 60 * 24 * 30,
		label: '1M',
	},
	{
		value: 60 * 60 * 24 * 91,
		label: '3M',
	},
	{
		value: 60 * 60 * 24 * 182,
		label: '6M',
	},
	{
		value: 60 * 60 * 24 * 365,
		label: '1Y',
	},
]
