var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UNSUPPORTED_NETWORK } from '../common/errors';
import { client } from '../utils/files';
import { StatusMap } from '../utils/system';
export default class SystemService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    getSynthetixStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            const { SystemStatus, DappMaintenance } = this.sdk.context.multicallContracts;
            if (!SystemStatus || !DappMaintenance) {
                throw new Error(UNSUPPORTED_NETWORK);
            }
            const [isSystemUpgrading, isExchangePaused] = (yield this.sdk.context.multicallProvider.all([
                SystemStatus.isSystemUpgrading(),
                DappMaintenance.isPausedSX(),
            ]));
            return isSystemUpgrading || isExchangePaused;
        });
    }
    getKwentaStatus() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield client.get('kwenta-status.json', {
                headers: { 'Cache-Control': 'no-cache' },
            });
            return Object.assign(Object.assign({}, response.data), { status: StatusMap[response.data.status] });
        });
    }
}
