import Link from 'next/link'
import { FC, memo } from 'react'
import styled, { css } from 'styled-components'

import LogoDarkSvg from 'assets/svg/brand/logo-dark.svg'
import LogoSvg from 'assets/svg/brand/infinity-logo.svg'
import ROUTES from 'constants/routes'
import { useAppSelector } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'
import { Environment } from 'constants/app'

const CURRENT_ENV = process.env.NEXT_PUBLIC_ENVIRONMENT ?? Environment.Local

const SvgLogo = memo(({ mode }: { mode: string}) => {
	return <EthGasLogo mode={mode} env={CURRENT_ENV}/>
})

const Logo: FC<{ mode: string}> = memo(({ mode }) => {
	return (
		<Link href={ROUTES.Home.Root}>
			<LogoContainer mode={mode}>
				<SvgLogo mode={mode}/>
			</LogoContainer>
		</Link>
	)
})

const LogoContainer = styled.span<{ mode: string }>`
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 32px;
	width: 150px;
	${(props) =>
		props.mode === 'desktop' &&
		css`
			margin-right: 20px;
		`
	}
	${(props) =>
		props.mode === 'mobile' &&
		css`
			margin-left: 10px;
		`
	}
	
`

const EthGasLogo = styled.div<{ mode: string, env?: string }>`
	background-image: ${(props) => `url('${props.env === Environment.App ? props.theme.colors.selectedTheme.logoBeta.src : props.theme.colors.selectedTheme.logo.src}')`};
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 136px;
	height: 40px;
	margin-left: ${(props) => props.env === Environment.App ? '-7px' : '0px'};
	${(props) =>
		props.mode === 'desktop' &&
		css`
			margin-top: 2px;
		`
	}
`

const LogoTitle = styled.div`
	text-transform: uppercase;
	color: ${(props) => props.theme.colors.selectedTheme.cyan};
	font-size: 16px;
	font-weight: 700;
`

export default Logo
