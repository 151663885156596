import { useEffect } from 'react'

import { fetchBalances } from 'state/balances/actions'
import { sdk } from 'state/config'
import { fetchEarnTokenPrices } from 'state/earn/actions'
import { selectMarkets } from 'state/futures/selectors'
import { useAppDispatch, useAppSelector, useFetchAction, usePollAction } from 'state/hooks'
import { fetchPreviousDayPrices, updatePrices } from 'state/prices/actions'
import { setConnectionError } from 'state/prices/reducer'
import { selectNetwork, selectWallet } from 'state/wallet/selectors'
import { serializePrices } from 'utils/futures'

import { checkSynthetixStatus } from './actions'
import { useVersion } from 'contexts/VersionContext'
import { fetchGasVolatility } from 'state/explorer/actions'
import { fetchCurrentGasPrice } from 'state/dashboard/actions'

export function useAppData(ready: boolean) {
	const dispatch = useAppDispatch()
	const wallet = useAppSelector(selectWallet)
	const markets = useAppSelector(selectMarkets)
	const network = useAppSelector(selectNetwork)
	const appVersion = useVersion();

	usePollAction('fetchGasVolatility', fetchGasVolatility, {
		dependencies: [network],
		disabled: appVersion !== 'dashboard',
		intervalTime: 60000 * 60,
	})

	usePollAction('fetchBalances', fetchBalances, {
		dependencies: [wallet, network],
		disabled: appVersion !== 'futures',
	})

	usePollAction('fetchPreviousDayPrices', fetchPreviousDayPrices, {
		intervalTime: 60000 * 15,
		dependencies: [markets.length, network],
		disabled: !markets.length || appVersion !== 'futures',
	})

	usePollAction('checkSynthetixStatus', checkSynthetixStatus, {
		intervalTime: 2 * 60 * 1000,
		dependencies: [network],
		disabled: appVersion !== 'futures',
	})

	// usePollAction('fetchCurrentGasPrice', fetchCurrentGasPrice, { 
    //     intervalTime: 10 * 1000,
	// 	disabled: appVersion !== 'dashboard',
    // })

	useEffect(() => {
		if (ready && appVersion === 'futures') {
			sdk.prices.startPriceUpdates(15000)
		}
	}, [ready, appVersion])

	useEffect(() => {
		if(appVersion === 'futures') {
			sdk.prices.onPricesUpdated(({ prices, type, source }) => {
				dispatch(updatePrices(serializePrices(prices), type))
				if (source === 'stream') {
					// must be connected again, remove any error
					dispatch(setConnectionError(null))
				}
			})

			sdk.prices.onPricesConnectionUpdated(({ error }) => {
				dispatch(setConnectionError(error?.message))
			})

			return () => {
				sdk.prices.removePricesListeners()
				sdk.prices.removeConnectionListeners()
			}
		}
	}, [dispatch, appVersion])
}
