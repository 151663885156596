import { createSlice } from '@reduxjs/toolkit'

import { FetchStatus } from 'state/types'

import { fetchBalances, fetchEthBalances } from './actions'
import { BalancesState } from './types'

export const ZERO_BALANCES = {
	synthBalances: [],
	synthBalancesMap: {},
	totalUSDBalance: '0',
	susdWalletBalance: '0',
	tokenBalances: {},
}

export const BALANCES_INITIAL_STATE: BalancesState = {
	status: FetchStatus.Idle,
	error: undefined,
	...ZERO_BALANCES,
}

const balancesSlice = createSlice({
	name: 'balances',
	initialState: BALANCES_INITIAL_STATE,
	reducers: {
		clearBalances: (state) => {
			state.synthBalances = []
			state.synthBalancesMap = {}
			state.totalUSDBalance = undefined
			state.susdWalletBalance = undefined
			state.error = undefined
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchBalances.pending, (state) => {
			state.status = FetchStatus.Loading
		})
		builder.addCase(fetchBalances.fulfilled, (state, action) => {
			state.status = FetchStatus.Success
			const ethBalance = state.synthBalances.find(s => s.currencyKey === 'ETH')
			state.synthBalances = [...action.payload.synthBalances]
			if (ethBalance) {
				state.synthBalances.push(ethBalance)
			}
			
			state.totalUSDBalance = action.payload.totalUSDBalance
			state.synthBalancesMap = {...state.synthBalancesMap, ...action.payload.synthBalancesMap}
			state.susdWalletBalance = action.payload.susdWalletBalance
			state.tokenBalances = action.payload.tokenBalances
		})
		builder.addCase(fetchBalances.rejected, (state) => {
			state.status = FetchStatus.Error
		})
		builder.addCase(fetchEthBalances.fulfilled, (state, action) => {
			const ethIndex = state.synthBalances.findIndex(s => s.currencyKey === 'ETH')
			if (ethIndex >= 0) {
				state.synthBalances[ethIndex].balance = action.payload 
				state.synthBalances[ethIndex].usdBalance = `${Number(action.payload) * 1800}`
			} else {
				state.synthBalances.push({
					currencyKey: "ETH",
					balance: action.payload,
					usdBalance: `${Number(action.payload) * 1800}`
				})
			}
			state.synthBalancesMap.ETH = {
				currencyKey: "ETH",
				balance: action.payload,
				usdBalance: `${Number(action.payload) * 1800}`
			}
		})
		builder.addCase(fetchEthBalances.rejected, (state) => {
			state.status = FetchStatus.Error
		})
	},
})

export default balancesSlice.reducer
