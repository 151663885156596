import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { NextPage } from 'next'
import { AppProps, AppInitialProps, AppContext } from 'next/app'
import Head from 'next/head'
import { FC, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query/devtools'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { WagmiConfig } from 'wagmi'

import ErrorNotifier from 'components/ErrorNotifier'
import Connector from 'containers/Connector'
import { chains, wagmiClient } from 'containers/Connector/config'
import useMonitorTransactions from 'hooks/useMonitorTransactions'
import AcknowledgementModal from 'sections/app/AcknowledgementModal'
import Layout from 'sections/shared/Layout'
import SystemStatus from 'sections/shared/SystemStatus'
import { useAppData } from 'state/app/hooks'
import { fetchEthBalances } from 'state/balances/actions'
import { useAppSelector, useAppDispatch } from 'state/hooks'
import { selectCurrentTheme } from 'state/preferences/selectors'
import store from 'state/store'
import { themes } from 'styles/theme'
import { IGNORE_ERRORS } from 'utils/logError'
import { getDesignTokens } from 'utils/theme'

import '@rainbow-me/rainbowkit/styles.css'
import '@reach/dialog/styles.css'
import Script from 'next/script'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'styles/main.css'
import '../i18n'
import { useVersion, VERSION, VersionContextProvider } from 'contexts/VersionContext'
import { startWebsocketService, subscribeToTopic } from 'services/WebsocketService'
import { TEventType } from 'services/websocketMessageHandlers'

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

const WEBSOCKET_TOPICS = ["last-block-stat", "last-mempool-data", "mempool-price-percentile"]

Sentry.init({
	/*dsn:
		'https://d48644bc80d04977a26132b346417210@o4504363236851712.ingest.sentry.io/4504363261362177',*/
	maxBreadcrumbs: 50,
	debug: process.env.NODE_ENV !== 'production',
	enabled: process.env.NODE_ENV === 'production',
	release: 'kwenta@' + process.env.GIT_HASH_ID!.toString(),
	autoSessionTracking: true,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.3,
	ignoreErrors: IGNORE_ERRORS,
})

const InnerApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
	const [isReady, setReady] = useState(false)
	const { providerReady } = Connector.useContainer()
	const dispatch = useAppDispatch()
	const { provider, walletAddress } = Connector.useContainer()

	useAppData(providerReady)
	useMonitorTransactions()

	const getLayout = Component.getLayout || ((page) => page)
	const currentTheme = useAppSelector(selectCurrentTheme)

	const theme = useMemo(() => themes[currentTheme], [currentTheme])
	// @ts-ignore palette options
	const muiTheme = useMemo(() => createTheme(getDesignTokens(currentTheme)), [currentTheme])
	useEffect(() => {
		setReady(true)
		const { close } = startWebsocketService()
		WEBSOCKET_TOPICS.forEach(topic => subscribeToTopic(topic as TEventType))
		return () => {
			close()
		}
	}, [])

	useEffect(() => {
		if (walletAddress && provider) {
			dispatch(fetchEthBalances({ provider, walletAddress }))
		}
	}, [walletAddress, provider])

	useEffect(() => {
		const fetchEthInterval = setInterval(() => {
			dispatch(fetchEthBalances({ provider, walletAddress }))
		}, 30000)
		return () => {
			clearInterval(fetchEthInterval)
		}
	}, [fetchEthBalances])

	return isReady ? (
		<RainbowKitProvider
			chains={chains}
			theme={currentTheme === 'dark' ? darkTheme() : lightTheme()}
			modalSize="compact"
		>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={muiTheme}>
					<Layout>
						<AcknowledgementModal />
						<SystemStatus>{getLayout(<Component {...pageProps} />)}</SystemStatus>
					</Layout>
					<ErrorNotifier />
					{/* <ReactQueryDevtools position="top-left" /> */}
				</MuiThemeProvider>
			</ThemeProvider>
		</RainbowKitProvider>
	) : null
}

const App: FC<AppProps & { version: VERSION }> = (props) => {
	const { t } = useTranslation()
	const gaID = process.env.NEXT_PUBLIC_GA_ID ?? ""

  // referential stable - without useState/useMemo a new cache will be generated each time App is rendered
  const {queryClient} = useMemo(() => {
    const queryClient = new QueryClient({});
    // broadcastQueryClient({ queryClient }); // turn on for cross tab storage sharing - make sure all query cache storage data serializable!
    return {queryClient/* ,localStoragePersister */};
  },[]);


	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
				/>
				<meta name="description" content={t('meta.description')} />
				{/* open graph */}
				<meta property="og:url" content="https://ethgas.com/" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={t('meta.og.title')} />
				<meta property="og:description" content={t('meta.description')} />
				<meta property="og:image" content="/images/ethgas_og_image.png" />
				<meta property="og:image:alt" content={t('meta.og.title')} />
				<meta property="og:site_name" content={t('meta.og.site-name')} />
				{/* twitter */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@ETHGASofficial" />
				<meta name="twitter:creator" content="@ETHGASofficial" />
				<meta name="twitter:image" content="/images/ethgas_og_image.png" />
				<meta name="twitter:url" content="https://twitter.com/ETHGASofficial" />
				<link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/dev/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/dev/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/dev/favicon-16x16.png" />
				<link rel="manifest" href="/images/favicon/dev/site.webmanifest" />
				<link rel="mask-icon" href="/images/favicon/dev/safari-pinned-tab.svg" color="#5bbad5" />
				<link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />
			</Head>
			{/* <!-- Google tag (gtag.js) --> */}
			{gaID && <><Script async src={`https://www.googletagmanager.com/gtag/js?id=${gaID}`}/>
			<Script id="google-analytics">{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());

				gtag('config', '${gaID}');
			`}</Script></>}
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					{/* <WebsocketProvider> */}
						<WagmiConfig client={wagmiClient}>
							<Connector.Provider>
								<VersionContextProvider version={props.pageProps.version}>
									<InnerApp {...props} />
								</VersionContextProvider>
							</Connector.Provider>
						</WagmiConfig>
					{/* </WebsocketProvider> */}
				</QueryClientProvider>
			</Provider>
		</>
	)
}

// @ts-ignore
App.getInitialProps = async ( 
	context: AppContext
): Promise<AppInitialProps> => {
	let version = context.ctx.req?.headers['x-app-version']?.toString().toLocaleLowerCase() as VERSION;
  if(!["dashboard","futures"].includes(version)) version = "dashboard"; // normalize
	// console.log('App version',version);
  return { pageProps: { version } };
}


export default App
