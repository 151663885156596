import { computed, signal } from "@preact/signals-react";
import { pick } from "lodash";
import { InclusionChanceBucketStat, InclusionTimeBucketStat, LastBlockStat } from "sections/market/types";

export const blockSignal = signal<LastBlockStat[]>([]);
export const blockLast14Signal = computed<LastBlockStat[]>(() => blockSignal.value.slice(-14));
export const lastBlockSignal = signal<LastBlockStat | null>(null);

export const lastBlockNumberSignal = computed<number>(() => lastBlockSignal.value?.number ?? 0);

export const txInclusionChanceBucketDataSignal = computed(() => pick(lastBlockSignal.value?.txInclusionStat, [
    'inclusionChanceBucketSize',
    'inclusionChanceBucketStart',
]) as InclusionChanceBucketStat)

export const txInclusionTimeBucketDataSignal = computed(() => pick(lastBlockSignal.value?.txInclusionStat, [
    'inclusionTimeBucketSize',
    'inclusionTimeBucketStart',
]) as InclusionTimeBucketStat)

export const marketableTxSignal = computed(() => lastBlockSignal.value?.marketableTx ?? { count: 0,totalGasLimit: 0 })
export const gasUsedSignal = computed(() => lastBlockSignal.value?.gasUsed ?? 0)

