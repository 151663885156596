import { Chain } from '@rainbow-me/rainbowkit';
import ZkSyncIcon from 'assets/png/rainbowkit/zksync.png'

 
export const zksyncSepolia: Chain = {
  id: 300,
  name: 'zkSync Sepolia',
  network: 'zksyncSepolia',
  iconUrl: async () => ZkSyncIcon,
  iconBackground: '#000',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    //public: { http: ['http://127.0.0.1:8545'] },
    // @ts-ignore
    public: { http: ['https://sepolia.era.zksync.dev'] },
    default: { http: ['https://sepolia.era.zksync.dev'] satisfies string[]},
    //default: { http: ['http://127.0.0.1:8545'] satisfies string[]},
  },
  blockExplorers: {
    //etherscan: { name: 'Blockscout', url: 'http://localhost:4000' },
    default: { name: 'Etherscan', url: 'https://sepolia.explorer.zksync.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
      blockCreated: 2292,
    },
  },
  testnet: true
}