import { FuturesAccountType, FuturesMarketAsset } from '@kwenta/sdk/types'

import { EXTERNAL_LINKS } from './links'

const prettyURLsDisabled = !!process.env.NEXT_PUBLIC_DISABLE_PRETTY_URLS

const normalizeRoute = (baseURL: string, path: string, queryParam: string) =>
    prettyURLsDisabled ? `${baseURL}?${queryParam}=${path}` : `${baseURL}/${path}`

export const formatUrl = (route: string, params: Record<string, string>) => {
    return route + '?' + new URLSearchParams(params)
}

export const ROUTES = {
    Home: {
        Root: '/',
    },
    Market: {
        Home: '/market',
    },
    Explore: {
        Wallets: {
            List: '/wallets',
            Dashboard: (address: string) => `/wallet/${address}`,
            EntityDashboard: (entity: string) => `/entity/${entity}/wallets`,
            CustomGroupDashboard: (addresses: string[]) => 
                formatUrl('/custom-dashboard/wallets', {
                    addresses: addresses.join(','),
                }),
        },
        Contracts: {
            List: (tab: string) => `/contracts/${tab}`,
            Dashboard: (protocolName: string) => `/contract/${protocolName}`,
            EntityDashboard: (entity: string) => `/entity/${entity}/contracts`,
            CustomGroupDashboard: (addresses: string[]) => 
                formatUrl('/custom-dashboard/contracts', {
                    addresses: addresses.join(','),
                }),

        },
        Validators: {
            List: '/validators',
        },
        BlockBuilders: {
            List: '/block-builders',
        }
    },
    Dashboard: {
        Home: '/dashboard',
    },
    Community: {
        Home: '/community/rewards',
        Rewards: '/community/rewards',
        Leaderboards: '/community/leaderboards',
        Protocols: '/community/protocols',
        Herds: '/community/herds',
    },
    Portfolio: {
        Home: '/portfolio',
        Overview: normalizeRoute('/portfolio', 'overview', 'tab'),
        History: normalizeRoute('/portfolio', 'history', 'tab'),
        Markets: normalizeRoute('/portfolio', 'markets', 'tab'),
        Earn: normalizeRoute('/portfolio', 'earn', 'tab'),
        Stake: normalizeRoute('/portfolio', 'staking', 'tab'),
        Rewards: normalizeRoute('/portfolio', 'rewards', 'tab'),
        TradingRewards: formatUrl('/portfolio/staking', { tab: 'trading-rewards' }),
    },
    Exchange: {
        Home: '/exchange',
        MarketPair: (baseCurrencyKey: string, quoteCurrencyKey: string) =>
            `/exchange/?quote=${quoteCurrencyKey}&base=${baseCurrencyKey}`,
        Into: (currencyKey: string) => `/exchange/?quote=${currencyKey}`,
    },
    Futures: {
        Home: (accountType: FuturesAccountType) => formatUrl('/futures', { accountType, asset: 'sGAS' }),
        MarketPair: (asset: FuturesMarketAsset | string, accountType: FuturesAccountType) =>
            formatUrl('/futures', { asset, accountType }),
        Position: (asset: FuturesMarketAsset, accountType: FuturesAccountType) =>
            formatUrl('/futures', {
                asset,
                accountType,
                tab: 'position',
            }),
        Orders: (asset: FuturesMarketAsset, accountType: FuturesAccountType) =>
            formatUrl('/futures', { asset, accountType, tab: 'orders' }),
        ConditionalOrders: (asset: FuturesMarketAsset, accountType: FuturesAccountType) =>
            formatUrl('/futures', { asset, accountType, tab: 'conditional_orders' }),
        Trades: (asset: FuturesMarketAsset, accountType: FuturesAccountType) =>
            formatUrl('/futures', { asset, accountType, tab: 'trades' }),
        Transfers: (asset: FuturesMarketAsset, accountType: FuturesAccountType) =>
            formatUrl('/futures', { asset, accountType, tab: 'transfers' }),
    },
    Stats: {
        Home: '/stats',
    },
    Leaderboard: {
        Home: '/leaderboard',
        Trader: (trader: string) => `/leaderboard/?trader=${trader}`,
        Competition: (round: string) => `/leaderboard/?competitionRound=${round}`,
    },
    Earn: {
        Home: '/earn',
    },
}

export const SUB_MENUS = {
    [ROUTES.Portfolio.Overview]: [
        { label: 'Overview', link: '/portfolio/overview' },
        // { label: 'Markets', link: '/dashboard/futuress' },
        // { label: 'Governance', link: '/governance' },
    ],
    [ROUTES.Home.Root]: [
        { label: 'Overview', link: EXTERNAL_LINKS.Docs.Governance },
        { label: 'KIPs', link: EXTERNAL_LINKS.Governance.Kips },
    ],
}

export const setLastVisited = (baseCurrencyPair: string, accountType: FuturesAccountType): void => {
    localStorage.setItem('lastVisited', ROUTES.Futures.MarketPair(baseCurrencyPair, accountType))
}

export default ROUTES
