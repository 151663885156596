// Takes a comparitor which should return a bool condition to
// signal to continue retrying, comparing prev and new query result

import { FilterItemProps } from 'components/types';
import logError from './logError'

export const refetchWithComparator = async (
	query: () => Promise<any>,
	existingResult: any,
	comparator: (previous: any, current: any) => boolean,
	interval = 1000,
	max = 25
): Promise<{ data: any; status: string }> => {
	return new Promise((res) => {
		let count = 1

		const refetch = async (existingResult: any) => {
			const timeout = setTimeout(async () => {
				if (count > max) {
					clearTimeout(timeout)
					logError(new Error('refetch timeout'))
					res({ data: null, status: 'timeout' })
				} else {
					const next = await query()
					count += 1
					if (!comparator(existingResult, next)) {
						clearTimeout(timeout)
						res({ data: next, status: 'complete' })
					} else {
						refetch(existingResult)
					}
				}
			}, interval)
		}
		refetch(existingResult)
	})
}

export const generateQueryText = (requestObject: any) => Object.keys(requestObject).map(k=>`${k}=${requestObject[k]}`).join('&');

const OPERATOR_MAPPING: {[key: string]: any} = {
	"=": "eq",
	"≠": "neq",
	">": "gt",
	"≥": "gte",
	"<": "lt",
	"≤": "lte",
	"contains": "like",
	"not contains": "nlike"
}
export const OPERATOR_MAPPING_REVERSE: {[key: string]: any} = {
	"text": {
		"like": "contains",
		"nlike": "not contains"
	},
	"number": {
		"eq": "=",
		"neq": "≠",
		"gt": ">",
		"gte": "≥",
		"lt": "<",
		"lte": "≤"
	}
}

const SORT_ORDER_MAPPING: {[key: string]: any} = {
	"A→Z": "asc",
	"Z→A": "desc",
	"1→9": "asc",
	"9→1": "desc"
}
export const SORT_ORDER_MAPPING_REVERSE: {[key: string]: any} = {
	"text": {
		"asc": "A→Z",
		"desc": "Z→A"
	},
	"number": {
		"asc": "1→9",
		"desc": "9→1"
	}
}

export const generateFilterQuery = (filters: FilterItemProps[]) => {
	if (!filters.length) return ''
	
	const filterQuery = filters.map(({field, operator, value, requestValue}) => {
		const queryValue = ["contains", "not contains", "like", "nlike"].includes(operator) ? `%${value}%` : (requestValue ?? value)
		return `${field}:${OPERATOR_MAPPING[operator] ?? operator}:${queryValue}`
	}).join(',')
    return filterQuery
}

export const generateSortQuery = (sorts: any[]) => {
	if (!sorts.length || sorts[0].column === '') return ''
	
	const sortQuery = sorts.map(({field, order}) => {
		return `${field}:${SORT_ORDER_MAPPING[order]}`
	}).join(',')
	return sortQuery
}