export type FilterItemProps = {
    column:             string
    field:              string
    operator:           string
    value:              string
    requestValue?:      number
}
export type SortItemProps = {
    column:             string
    field:              string
    order:              string
}

export type FilterableColumn = {
    name:                   string
    field:                  string
    type:                   "text" | "number"
    requestValueConverter?: (value: number) => number
    urlValueConverter?:     (value: number) => number
}

export enum OperatorBetweenConditions {
    AND = "AND",
    OR = "OR"
}
