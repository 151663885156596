import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { monitorTransaction } from 'contexts/RelayerContext'
import { handleTransactionError } from 'state/app/reducer'
import { selectTransaction } from 'state/app/selectors'
import { useAppSelector } from 'state/hooks'
import { selectMarketInfo } from 'state/futures/selectors'
import usePrevious from './usePrevious'

export default function useMonitorTransactions() {
	const dispatch = useDispatch()
	const transaction = useAppSelector(selectTransaction)
	const marketInfo = useAppSelector(selectMarketInfo)
	const prevTransactionHash = usePrevious(transaction?.hash)

	useEffect(() => {
		if (transaction?.hash && transaction?.hash !== prevTransactionHash) {
			monitorTransaction({
				txHash: transaction.hash,
				onTxFailed: (err) => {
					dispatch(handleTransactionError({
						message: err.failureReason ?? 'transaction_failed',
						options: { minInitialMargin: marketInfo?.minInitialMargin }
					}))
				},
			})
		}
	}, [transaction?.hash, prevTransactionHash, dispatch, marketInfo?.minInitialMargin])
}
