import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_DASHBOARD_CURRENCY, DEFAULT_TIME_RANGE_SELECTOR } from 'constants/defaults'
import { DEFAULT_QUERY_STATUS, LOADING_STATUS, SUCCESS_STATUS } from 'state/constants'
import { FetchStatus } from 'state/types'
import {
    fetchFeaturedProtocolList,
    fetchContractTransactionsData,
    fetchCumulativeGasSpendData,
    fetchProtocolLeaderboard,
    fetchContractGroupLeaderboard,
    fetchGasVolatility,
    fetchENSNames,
    fetchValidatorStats,
    fetchBlockBuilderStats,
    fetchSenderGroupAddresses,
    fetchSenderGroups,
    fetchContractTransactionsDataForGroup,
    fetchReceiverGroupAddresses,
    fetchReceiverGroups,
    fetchCustomReceiverGroupData,
    fetchReceiverGroupContractTransactionsData,
    fetchReceiverGroupCumulativeGasSpendData,
    fetchAddressByENSName,
    fetchCumulativeGasSpendDataForGroup
} from './actions'
import { ENSProps, ExplorerState } from './types'
import _ from 'lodash'
import { OperatorBetweenConditions } from 'components/types'

export const EXPLORER_INITIAL_STATE: ExplorerState = {
    selectedProtocol: null,
    selectedSenderGroupName: null,
    selectedReceiverGroupName: null,
    featuredProtocolList: [],
    protocolData: {},
    contractLeaderboard: [],
    contractGroupLeaderboard: [],
    gasVolatility: {
        gasVol: 0,
        lastVwap: 0
    },
    ensNames: {},
    validatorList: {
        graph: {},
        stat: {
            size: 0,
            data: {},
        },
        topValidators: []
    },
    blockBuilderList: {
        graph: {},
        stat: {
            size: 0,
            data: {},
        },
        topBlockBuilders: []
    },
    senderGroupData: {},
    senderGroups: [],
    receiverGroupData: {},
    receiverGroups: [],
    groupAddresses: [],
    appliedFilters: {
        filterItems: [],
        operatorBetweenConditions: OperatorBetweenConditions.AND
    },
    appliedSorting: [],
    queryStatuses: {
        transactionsData: DEFAULT_QUERY_STATUS,
        gasSpendTableData: DEFAULT_QUERY_STATUS,
        featuredProtocolList: DEFAULT_QUERY_STATUS,
        contractLeaderboard: DEFAULT_QUERY_STATUS,
        contractGroupLeaderboard: DEFAULT_QUERY_STATUS,
        gasVolatility: DEFAULT_QUERY_STATUS,
        ensNames: DEFAULT_QUERY_STATUS,
        ensNameLookup: DEFAULT_QUERY_STATUS,
        validatorList: DEFAULT_QUERY_STATUS,
        blockBuilderList: DEFAULT_QUERY_STATUS,
        senderGroupAddressList: DEFAULT_QUERY_STATUS,
        senderGroups: DEFAULT_QUERY_STATUS,
        contractTransactionsDataForGroup: DEFAULT_QUERY_STATUS,
        receiverGroupAddressList: DEFAULT_QUERY_STATUS,
        receiverGroups: DEFAULT_QUERY_STATUS,
        receiverGroupData: DEFAULT_QUERY_STATUS,
        cumulativeGasSpendDataForGroup: DEFAULT_QUERY_STATUS,
    }
}

const explorerSlice = createSlice({
    name: 'explorer',
    initialState: EXPLORER_INITIAL_STATE,
    reducers: {
        setSelectedProtocol: (state, action) => {
            state.selectedProtocol = action.payload
        },
        setSelectedSenderGroupName: (state, action) => {
            state.selectedSenderGroupName = action.payload
        },
        setSelectedReceiverGroupName: (state, action) => {
            state.selectedReceiverGroupName = action.payload
        },
        setSelectedGroupAddresses: (state, action) => {
            state.groupAddresses = action.payload
        },
        setAppliedFilters: (state, action) => {
            state.appliedFilters = action.payload
        },
        setAppliedSorting: (state, action) => {
            state.appliedSorting = action.payload
        }
    },
    extraReducers: (builder) => {
        // gas spend data 
        builder.addCase(fetchCumulativeGasSpendData.pending, (explorerState) => {
            explorerState.queryStatuses.gasSpendTableData = LOADING_STATUS
        })
        builder.addCase(fetchCumulativeGasSpendData.fulfilled, (explorerState, action) => {
            const {
                walletAddress,
                gasSpentTable,
                protocolBreakdown,
                topProtocolNames,
            } = action.payload
            explorerState.protocolData[walletAddress] = {
                ...explorerState.protocolData[walletAddress], 
                gasSpentTableData: gasSpentTable,
                topProtocolNames,
                protocolBreakdown,
            }
            explorerState.queryStatuses.gasSpendTableData = SUCCESS_STATUS
        })
        builder.addCase(fetchCumulativeGasSpendData.rejected, (explorerState) => {
            explorerState.queryStatuses.gasSpendTableData = {
                error: 'Failed to fetch protocol gas spent data',
                status: FetchStatus.Error,
            }
        })
        // transactions data
        builder.addCase(fetchContractTransactionsData.pending, (explorerState) => {
            explorerState.queryStatuses.transactionsData = LOADING_STATUS
        })
        builder.addCase(fetchContractTransactionsData.fulfilled, (explorerState, action) => {
            const {
                walletAddress,
                dailyTransactionDetails,
                protocolInfo,
                functionPercentile,
                overallPercentile,
                functionPercentileDaily,
            } = action.payload
            explorerState.protocolData[walletAddress] = {
                ...explorerState.protocolData[walletAddress], 
                cumulativeGasSpendData: dailyTransactionDetails,
                protocolInfo,
                functionPercentile,
                overallPercentile,
                functionPercentileDaily,
            }
            explorerState.queryStatuses.transactionsData = SUCCESS_STATUS
        })
        builder.addCase(fetchContractTransactionsData.rejected, (explorerState) => {
            explorerState.queryStatuses.transactionsData = {
                error: 'Failed to fetch protocol transactions data',
                status: FetchStatus.Error,
            }
        })
        // featured protocol list
        builder.addCase(fetchFeaturedProtocolList.pending, (explorerState) => {
            explorerState.queryStatuses.featuredProtocolList = LOADING_STATUS
        })
        builder.addCase(fetchFeaturedProtocolList.fulfilled, (explorerState, action) => {
            const {
                featuredProtocolList
            } = action.payload
            explorerState.featuredProtocolList = featuredProtocolList;
            explorerState.queryStatuses.featuredProtocolList = SUCCESS_STATUS
        })
        builder.addCase(fetchFeaturedProtocolList.rejected, (explorerState) => {
            explorerState.queryStatuses.featuredProtocolList = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })
        // contract leaderboard
        builder.addCase(fetchProtocolLeaderboard.pending, (explorerState) => {
            explorerState.queryStatuses.contractLeaderboard = LOADING_STATUS
        })
        builder.addCase(fetchProtocolLeaderboard.fulfilled, (explorerState, action) => {
            const {
                contractLeaderboard
            } = action.payload
            explorerState.contractLeaderboard = contractLeaderboard;
            explorerState.queryStatuses.contractLeaderboard = SUCCESS_STATUS
        })
        builder.addCase(fetchProtocolLeaderboard.rejected, (explorerState) => {
            explorerState.queryStatuses.contractLeaderboard = {
                error: 'Failed to fetch contract leaderboard',
                status: FetchStatus.Error,
            }
        })

        // contract group leaderboard
        builder.addCase(fetchContractGroupLeaderboard.pending, (explorerState) => {
            explorerState.queryStatuses.contractGroupLeaderboard = LOADING_STATUS
        })
        builder.addCase(fetchContractGroupLeaderboard.fulfilled, (explorerState, action) => {
            const {
                contractGroupLeaderboard
            } = action.payload
            explorerState.contractGroupLeaderboard = contractGroupLeaderboard;
            explorerState.queryStatuses.contractGroupLeaderboard = SUCCESS_STATUS
        })
        builder.addCase(fetchContractGroupLeaderboard.rejected, (explorerState) => {
            explorerState.queryStatuses.contractGroupLeaderboard = {
                error: 'Failed to fetch contract leaderboard',
                status: FetchStatus.Error,
            }
        })

        // gas volatility data
        builder.addCase(fetchGasVolatility.pending, (explorerState) => {
            explorerState.queryStatuses.gasVolatility = LOADING_STATUS
        })
        builder.addCase(fetchGasVolatility.fulfilled, (explorerState, action) => {
            const {
                gasVolatility
            } = action.payload
            explorerState.gasVolatility = gasVolatility;
            explorerState.queryStatuses.gasVolatility = SUCCESS_STATUS
        })
        builder.addCase(fetchGasVolatility.rejected, (explorerState) => {
            explorerState.queryStatuses.gasVolatility = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // ens names
        builder.addCase(fetchENSNames.pending, (explorerState) => {
            explorerState.queryStatuses.ensNames = LOADING_STATUS
        })
        builder.addCase(fetchENSNames.fulfilled, (explorerState, action) => {
            const {
                ensEntries
            } = action.payload
            ensEntries?.forEach((ensEntry: ENSProps) => {
                explorerState.ensNames[ensEntry.address] = ensEntry
            })
            explorerState.queryStatuses.ensNames = SUCCESS_STATUS
        })
        builder.addCase(fetchENSNames.rejected, (explorerState) => {
            explorerState.queryStatuses.ensNames = {
                error: 'Failed to fetch ens names',
                status: FetchStatus.Error,
            }
        })
        builder.addCase(fetchAddressByENSName.pending, (explorerState) => {
            explorerState.queryStatuses.ensNameLookup = LOADING_STATUS
        })
        builder.addCase(fetchAddressByENSName.fulfilled, (explorerState, action) => {
            const {
                ensEntries
            } = action.payload
            ensEntries?.forEach((ensEntry: ENSProps) => {
                explorerState.ensNames[ensEntry.address] = ensEntry
            })
            explorerState.queryStatuses.ensNameLookup = SUCCESS_STATUS
        })
        builder.addCase(fetchAddressByENSName.rejected, (explorerState) => {
            explorerState.queryStatuses.ensNameLookup = {
                error: 'Failed to fetch ens name lookup',
                status: FetchStatus.Error,
            }
        })

        // validator list
        builder.addCase(fetchValidatorStats.pending, (explorerState) => {
            explorerState.queryStatuses.validatorList = LOADING_STATUS
        })
        builder.addCase(fetchValidatorStats.fulfilled, (explorerState, action) => {
            const {
                validatorsList
            } = action.payload
            explorerState.validatorList = validatorsList;
            explorerState.queryStatuses.validatorList = SUCCESS_STATUS
        })
        builder.addCase(fetchValidatorStats.rejected, (explorerState) => {
            explorerState.queryStatuses.validatorList = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // block builder list
        builder.addCase(fetchBlockBuilderStats.pending, (explorerState) => {
            explorerState.queryStatuses.blockBuilderList = LOADING_STATUS
        })
        builder.addCase(fetchBlockBuilderStats.fulfilled, (explorerState, action) => {
            const {
                blockBuilderList
            } = action.payload
            explorerState.blockBuilderList = blockBuilderList;
            explorerState.queryStatuses.blockBuilderList = SUCCESS_STATUS
        })
        builder.addCase(fetchBlockBuilderStats.rejected, (explorerState) => {
            explorerState.queryStatuses.blockBuilderList = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // Sender Group Addresses
        builder.addCase(fetchSenderGroupAddresses.pending, (explorerState) => {
            explorerState.queryStatuses.senderGroupAddressList = LOADING_STATUS
        })
        builder.addCase(fetchSenderGroupAddresses.fulfilled, (explorerState, action) => {
            console.log('fetchSenderGroupAddresses.fulfilled');
            const {
                groupAddressList,
                groupName
            } = action.payload
            if (groupName !== null) {
                explorerState.senderGroupData[groupName] = {
                    ...explorerState.senderGroupData[groupName],
                    groupAddressList
                }
            }
            
            explorerState.queryStatuses.senderGroupAddressList = SUCCESS_STATUS
        })
        builder.addCase(fetchSenderGroupAddresses.rejected, (explorerState) => {
            explorerState.queryStatuses.senderGroupAddressList = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // Sender Groups
        builder.addCase(fetchSenderGroups.pending, (explorerState) => {
            explorerState.queryStatuses.senderGroups = LOADING_STATUS
        })
        builder.addCase(fetchSenderGroups.fulfilled, (explorerState, action) => {
            const {
                groups
            } = action.payload
            if (groups) {
                explorerState.senderGroups = groups
            }
            
            explorerState.queryStatuses.senderGroups = SUCCESS_STATUS
        })
        builder.addCase(fetchSenderGroups.rejected, (explorerState) => {
            explorerState.queryStatuses.senderGroups = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // Sender GroupData
        builder.addCase(fetchContractTransactionsDataForGroup.pending, (explorerState) => {
            explorerState.queryStatuses.contractTransactionsDataForGroup = LOADING_STATUS
        })
        builder.addCase(fetchContractTransactionsDataForGroup.fulfilled, (explorerState, action) => {
            const {
                addresses,
                walletHash,
                // groupInfo,
                // dailyTransactionDetails,
                gasSpentTable,
                protocolBreakdown,
                topProtocolNames,
            } = action.payload
            if(!_.isEqual(addresses,explorerState.groupAddresses)) {
                // console.log('drop unmatched fetchCustomSenderGroupData result')
                return;
            }
            if (gasSpentTable) {
                explorerState.senderGroupData[walletHash] = {
                    ...(explorerState.senderGroupData[walletHash] ?? {}),
                    gasSpentTableData: gasSpentTable,
                    contractTransactions: protocolBreakdown,
                    topProtocolNames: topProtocolNames
                }
                explorerState.queryStatuses.contractTransactionsDataForGroup = SUCCESS_STATUS
            } else {
                explorerState.queryStatuses.contractTransactionsDataForGroup = {
                    error: 'Failed to fetch group data',
                    status: FetchStatus.Error,
                }
            }
        })
        builder.addCase(fetchContractTransactionsDataForGroup.rejected, (explorerState) => {
            explorerState.queryStatuses.contractTransactionsDataForGroup = {
                error: 'Failed to fetch group data',
                status: FetchStatus.Error,
            }
        })


        // Sender GroupData
        builder.addCase(fetchCumulativeGasSpendDataForGroup.pending, (explorerState) => {
            explorerState.queryStatuses.cumulativeGasSpendDataForGroup = LOADING_STATUS
        })
        builder.addCase(fetchCumulativeGasSpendDataForGroup.fulfilled, (explorerState, action) => {
            const {
                addresses,
                walletHash,
                groupInfo,
                dailyTransactionDetails,
            } = action.payload
            if(!_.isEqual(addresses,explorerState.groupAddresses)) {
                // console.log('drop unmatched fetchCustomSenderGroupData result')
                return;
            }
            if (groupInfo) {
                explorerState.senderGroupData[walletHash] = {
                    ...(explorerState.senderGroupData[walletHash] ?? {}),
                    groupAddressList: explorerState.senderGroupData[walletHash]?.groupAddressList ?? addresses,
                    groupInfo: groupInfo,
                    cumulativeGasSpendChart: dailyTransactionDetails,
                }
                explorerState.queryStatuses.cumulativeGasSpendDataForGroup = SUCCESS_STATUS
            } else {
                explorerState.queryStatuses.cumulativeGasSpendDataForGroup = {
                    error: 'Failed to fetch group data',
                    status: FetchStatus.Error,
                }
            }
        })
        builder.addCase(fetchCumulativeGasSpendDataForGroup.rejected, (explorerState) => {
            explorerState.queryStatuses.cumulativeGasSpendDataForGroup = {
                error: 'Failed to fetch group data',
                status: FetchStatus.Error,
            }
        })

        // Receiver Group Addresses
        builder.addCase(fetchReceiverGroupAddresses.pending, (explorerState) => {
            explorerState.queryStatuses.receiverGroupAddressList = LOADING_STATUS
        })
        builder.addCase(fetchReceiverGroupAddresses.fulfilled, (explorerState, action) => {
            console.log('fetchReceiverGroupAddresses.fulfilled');
            const {
                groupAddressList,
                groupName
            } = action.payload
            if (groupName !== null) {
                explorerState.receiverGroupData[groupName] = {
                    ...explorerState.receiverGroupData[groupName],
                    groupAddressList
                }
            }
            
            explorerState.queryStatuses.receiverGroupAddressList = SUCCESS_STATUS
        })
        builder.addCase(fetchReceiverGroupAddresses.rejected, (explorerState) => {
            explorerState.queryStatuses.receiverGroupAddressList = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // Receiver Groups
        builder.addCase(fetchReceiverGroups.pending, (explorerState) => {
            explorerState.queryStatuses.receiverGroups = LOADING_STATUS
        })
        builder.addCase(fetchReceiverGroups.fulfilled, (explorerState, action) => {
            const {
                groups
            } = action.payload
            if (groups) {
                // console.log('groups',groups);
                explorerState.receiverGroups = groups
            }
            
            explorerState.queryStatuses.receiverGroups = SUCCESS_STATUS
        })
        builder.addCase(fetchReceiverGroups.rejected, (explorerState) => {
            explorerState.queryStatuses.receiverGroups = {
                error: 'Failed to fetch featured protocol list',
                status: FetchStatus.Error,
            }
        })

        // Receiver group transactions data
        builder.addCase(fetchReceiverGroupContractTransactionsData.pending, (explorerState) => {
            explorerState.queryStatuses.receiverGroupData = LOADING_STATUS
        })
        builder.addCase(fetchReceiverGroupContractTransactionsData.fulfilled, (explorerState, action) => {
            const {
                walletAddresses,
                contractHash,
                dailyTransactionDetails,
                contractInfo,
                functionPercentile,
                overallPercentile,
                functionPercentileDaily,
            } = action.payload
            if(!_.isEqual(walletAddresses,explorerState.groupAddresses)) {
                // console.log('drop unmatched fetchReceiverGroupContractTransactionsData result')
                return;
            }
            // console.log('fetchReceiverGroupContractTransactionsData', action.payload)
            const groupAddressList = explorerState.receiverGroupData[contractHash]?.groupAddressList ?? walletAddresses.map(a => ({ address: a}));
            explorerState.receiverGroupData[contractHash] = {
                ...explorerState.receiverGroupData[contractHash],
                groupAddressList,
                cumulativeGasSpendData: dailyTransactionDetails,
                contractInfo,
                functionPercentile,
                overallPercentile,
                functionPercentileDaily,
            }
            explorerState.queryStatuses.receiverGroupData = SUCCESS_STATUS
        })
        builder.addCase(fetchReceiverGroupContractTransactionsData.rejected, (explorerState) => {
            explorerState.queryStatuses.receiverGroupData = {
                error: 'Failed to fetch protocol transactions data',
                status: FetchStatus.Error,
            }
        })

        // Receiver group gas spend data 
        builder.addCase(fetchReceiverGroupCumulativeGasSpendData.pending, (explorerState) => {
            explorerState.queryStatuses.gasSpendTableData = LOADING_STATUS
        })
        builder.addCase(fetchReceiverGroupCumulativeGasSpendData.fulfilled, (explorerState, action) => {
            const {
                walletAddresses,
                contractHash,
                gasSpentTable,
                protocolBreakdown,
                topProtocolNames,
            } = action.payload
            if(!_.isEqual(walletAddresses,explorerState.groupAddresses)) {
                // console.log('drop unmatched fetchReceiverGroupCumulativeGasSpendData result')
                return;
            }
            explorerState.receiverGroupData[contractHash] = {
                ...explorerState.receiverGroupData[contractHash], 
                gasSpentTableData: gasSpentTable,
                topProtocolNames,
                protocolBreakdown,
            }
            explorerState.queryStatuses.gasSpendTableData = SUCCESS_STATUS
        })
        builder.addCase(fetchReceiverGroupCumulativeGasSpendData.rejected, (explorerState) => {
            explorerState.queryStatuses.gasSpendTableData = {
                error: 'Failed to fetch protocol gas spent data',
                status: FetchStatus.Error,
            }
        })
    }
})

export const {
    setSelectedProtocol,
    setSelectedSenderGroupName,
    setSelectedReceiverGroupName,
    setSelectedGroupAddresses,
    setAppliedFilters,
    setAppliedSorting
} = explorerSlice.actions

export default explorerSlice.reducer