import { computed, signal } from "@preact/signals-react";
import { pick } from "lodash";
import { LastMempoolData } from "sections/market/types";

// export const mempoolDataSignal = signal<LastMempoolData[]>([]);
export const lastMempoolDataSignal = signal<LastMempoolData | null>(null);

export const nextBlockIdSignal = computed<number>(() => lastMempoolDataSignal.value?.nextBlockId ?? 0);

export const walletMarketHistoryFeeChartDataSignal = computed(() => pick(lastMempoolDataSignal.value, [
  'nextBaseFee',
  'nextAvgPriorityFee',
  'blockNumber',
  'lastAvgPriorityFee',
  'lastBlockId',
]))
export const walletMarketableTransactionChartDataSignal = computed(() => pick(lastMempoolDataSignal.value, [
  'marketableTxCnt',
  'blockNumber',
  'lastBlockTxCnt',
  'lastBlockId',
]))
export const walletMarketBlockUtilizationChartDataSignal = computed(() => pick(lastMempoolDataSignal.value, [
  'nextBlockUtilization',
  'blockNumber',
  'lastBlockUtilization',
  'lastBlockId',
]))
export const walletMarketBlockCostChartDataSignal = computed(() => pick(lastMempoolDataSignal.value, [
  'nextBlockCostUSD',
  'nextBlockCostETH',
  'blockNumber',
  'lastBlockCostUSD',
  'lastBlockCostETH',
  'lastBlockId',
]))

export const txInclusionNextBlockChanceSignal = computed(() => lastMempoolDataSignal.value?.txInclusionStat?.nextBlockChance ?? []);
export const expectedInclusionTimeSignal = computed(() => lastMempoolDataSignal.value?.txInclusionStat?.expectedInclusionTime)
export const expectedGasPriceSignal = computed(() => lastMempoolDataSignal.value?.txInclusionStat?.expectedGasPrice)
export const expectedTimeSignal = computed(() => lastMempoolDataSignal.value?.txInclusionStat?.expectedTime)
export const maxFeePerGasFor60sSignal = computed(() => lastMempoolDataSignal.value?.maxFeePerGasFor60s ?? [])
