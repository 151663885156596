export const HEADER_HEIGHT = '96px'
export const HEADER_TOP_PADDING = '31px'

export const SPACING_FROM_HEADER = '0px'

export const HOURS_TOGGLE_WIDTH = '47px'
export const HOURS_TOGGLE_HEIGHT = '18px'

export enum zIndex {
	BASE = 1,
	HEADER = 10,
	PAGE_SUBHEADER = 11,
	MARKET_DROPDOWN = 40,
	SEARCH_OVERLAY = 49,
	DIALOG_OVERLAY = 50,
	MOBILE_FOOTER = 51,
	DRAWER = 65,
	SEARCH_AUTOCOMPELTE_DROPDOWN = 201,
}
