export enum Period {
	FIFTEEN_MINUTES = 'FIFTEEN_MINUTES',
	ONE_HOUR = 'ONE_HOUR',
	ONE_DAY = 'ONE_DAY',
	ONE_WEEK = 'ONE_WEEK',
	ONE_MONTH = 'ONE_MONTH',
	SIX_MONTHS = 'SIX_MONTHS',
	ONE_YEAR = 'ONE_YEAR',
}

export type TimeRangeDurationOption = '15min' | '1h' | '24h' | '1w' | '1m' | '6m' | '1y';

export type TimeRangeOptionProps = {
	value: number
	label: string
	requestValue: TimeRangeDurationOption
	disabled?: boolean
}

export const PERIOD_IN_SECONDS: Record<Period, number> = {
	FIFTEEN_MINUTES: 15 * 60,
	ONE_HOUR: 60 * 60,
	ONE_DAY: 24 * 60 * 60,
	ONE_WEEK: 7 * 24 * 60 * 60,
	ONE_MONTH: 30 * 24 * 60 * 60,
	SIX_MONTHS: 182 * 24 * 60 * 60,
	ONE_YEAR: 365 * 24 * 60 * 60,
}

export const ALL_TIME_RANGE_OPTIONS: {[key: string]: TimeRangeOptionProps} = {
	[Period.FIFTEEN_MINUTES]: {
		value: PERIOD_IN_SECONDS.FIFTEEN_MINUTES,
		label: '15 minutes',
		requestValue: '15min'
	},
	[Period.ONE_HOUR]: {
		value: PERIOD_IN_SECONDS.ONE_HOUR,
		label: '1 hour',
		requestValue: '1h'
	},
	[Period.ONE_DAY]: {
		value: PERIOD_IN_SECONDS.ONE_DAY,
		label: '24 hours',
		requestValue: '24h'
	},
	[Period.ONE_WEEK]: {
		value: PERIOD_IN_SECONDS.ONE_WEEK,
		label: '7 days',
		requestValue: '1w'
	},
	[Period.ONE_MONTH]: {
		value: PERIOD_IN_SECONDS.ONE_MONTH,
		label: '1 month',
		requestValue: '1m'
	},
	[Period.SIX_MONTHS]: {
		value: PERIOD_IN_SECONDS.SIX_MONTHS,
		label: '6 months',
		requestValue: '6m',
		disabled: true
	},
	[Period.ONE_YEAR]: {
		value: PERIOD_IN_SECONDS.ONE_YEAR,
		label: '1 year',
		requestValue: '1y',
		disabled: true
	},
	// {
	// 	value: Number.POSITIVE_INFINITY,
	// 	label: 'All time',
	// }
};

export const getTimeRangeOptions = (minKey:Period = Period.FIFTEEN_MINUTES, maxKey:Period = Period.ONE_YEAR)=>{
	const minValue = ALL_TIME_RANGE_OPTIONS[minKey].value;
	const maxValue = ALL_TIME_RANGE_OPTIONS[maxKey].value;
	const periodKeys = Object.keys(ALL_TIME_RANGE_OPTIONS);
	return periodKeys.reduce((options,key)=>{
		const item = ALL_TIME_RANGE_OPTIONS[key];
		if(item.value < minValue || item.value > maxValue) return options;
		return {...options, [key]: item};
	},{} as typeof ALL_TIME_RANGE_OPTIONS);
};

export const getTimeRangeLastLabelMapping = (minKey:Period = Period.FIFTEEN_MINUTES, maxKey:Period = Period.ONE_YEAR)=>{
	const minValue = ALL_TIME_RANGE_OPTIONS[minKey].value;
	const maxValue = ALL_TIME_RANGE_OPTIONS[maxKey].value;
	const periodKeys = Object.keys(ALL_TIME_RANGE_OPTIONS);
	return periodKeys.reduce((options,key)=>{
		const item = ALL_TIME_RANGE_OPTIONS[key];
		if(item.value < minValue || item.value > maxValue) return options;
		return {...options, [item.value]: item.label};
	},{} as {
		[value:number]: string,
	});
}

export const getTimeRangeLastKeyMapping = (minKey:Period = Period.FIFTEEN_MINUTES, maxKey:Period = Period.ONE_YEAR)=>{
	const minValue = ALL_TIME_RANGE_OPTIONS[minKey].value;
	const maxValue = ALL_TIME_RANGE_OPTIONS[maxKey].value;
	const periodKeys = Object.keys(ALL_TIME_RANGE_OPTIONS);
	return periodKeys.reduce((options,key)=>{
		const item = ALL_TIME_RANGE_OPTIONS[key];
		if(item.value < minValue || item.value > maxValue) return options;
		return {...options, [item.value]: item.requestValue};
	},{} as {
		[value:number]: TimeRangeDurationOption,
	});
}

export const getTimeRangeByRequestValue = (requestValue:string)=>{
	const keys = Object.keys(ALL_TIME_RANGE_OPTIONS);
	for(let i=0;i<keys.length;i++){
		const key = keys[i];
		const item = ALL_TIME_RANGE_OPTIONS[key];
		if(item.requestValue === requestValue) return item;
	}
	return null;
}

// Default time range options - no fifteen minutes
export const TIME_RANGE_OPTIONS = getTimeRangeOptions(Period.ONE_HOUR); 

// mapping only used for look up, no need to apply range
/**
 * map of value (period in seconds) to label ('hour')
 */
export const TIME_RANGE_LAST_LABEL_MAPPING = getTimeRangeLastLabelMapping(); /* {
	[PERIOD_IN_SECONDS.ONE_HOUR]: 'hour',
	[PERIOD_IN_SECONDS.ONE_DAY]: '24 hours',
	[PERIOD_IN_SECONDS.ONE_WEEK]: '7 days',
	[PERIOD_IN_SECONDS.ONE_MONTH]: 'month',
	[PERIOD_IN_SECONDS.SIX_MONTHS]: '6 months',
	[PERIOD_IN_SECONDS.ONE_YEAR]: 'year',
} */

// mapping only used for look up, no need to apply range
/**
 * map of value (period in seconds) to request value ('1h')
 */
export const TIME_RANGE_LAST_KEY_MAPPING = getTimeRangeLastKeyMapping(); /* {
	[PERIOD_IN_SECONDS.ONE_DAY]: TIME_RANGE_OPTIONS[Period.ONE_DAY].requestValue,
	[PERIOD_IN_SECONDS.ONE_WEEK]: TIME_RANGE_OPTIONS[Period.ONE_WEEK].requestValue,
	[PERIOD_IN_SECONDS.ONE_MONTH]: TIME_RANGE_OPTIONS[Period.ONE_MONTH].requestValue,
	[PERIOD_IN_SECONDS.SIX_MONTHS]: TIME_RANGE_OPTIONS[Period.SIX_MONTHS].requestValue,
	[PERIOD_IN_SECONDS.ONE_YEAR]: TIME_RANGE_OPTIONS[Period.ONE_YEAR].requestValue,
} */