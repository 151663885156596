import { DEFAULT_DASHBOARD_CURRENCY, DEFAULT_TIME_RANGE_SELECTOR } from 'constants/defaults'
import { Candle, CumulativeGasSpendChartData, DashboardState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_QUERY_STATUS, LOADING_STATUS, SUCCESS_STATUS } from 'state/constants'
import { fetchCurrentGasPrice, fetchMarketData, fetchCumulativeGasSpendData, fetchContractTransactionsData } from './actions'
import { FetchStatus } from 'state/types'
import isBefore from 'date-fns/isBefore'
import { DEFAULT_USER_DATA, DEFAULT_USER_INFO } from './constants'

export const DASHBOARD_INITIAL_STATE: DashboardState = {
    selectedDashboardTimeRange: DEFAULT_TIME_RANGE_SELECTOR,
    selectedDashboardCurrency: DEFAULT_DASHBOARD_CURRENCY,
    selectedWallet: '',
    transactionTypeExpanded: false,
    marketData: {
        currentGasPrice: 0,
        candles: [],
    },
    userData: {},
    queryStatuses: {
        marketData: DEFAULT_QUERY_STATUS,
        cumulativeGasSpendData: DEFAULT_QUERY_STATUS,
        contractTransactionsData: DEFAULT_QUERY_STATUS,
    }
}

const isBiggerDataSet = (newData: (Candle | CumulativeGasSpendChartData)[] , oldData: (Candle | CumulativeGasSpendChartData)[]) => {
    if (newData.length === 0) return false
    if (oldData.length === 0) return true
    const firstDateOfNewData = new Date(newData[0].timestamp)
    const firstDateOfOldData = new Date(oldData[0].timestamp)
    return isBefore(firstDateOfNewData, firstDateOfOldData)
}

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: DASHBOARD_INITIAL_STATE,
	reducers: {
        setSelectedDashboardTimeRange: (state, action) => {
            state.selectedDashboardTimeRange = action.payload
        },
        setSelectedDashboardCurrency: (state, action) => {
            state.selectedDashboardCurrency = action.payload
        },
        setTransactionTypeExpanded: (state, action) => {
            state.transactionTypeExpanded = action.payload
        },
        setSelectedWallet: (state, action) => {
            state.selectedWallet = action.payload
        },
        setUserGroup: (state, action) => {
            const groupData = action.payload
            groupData.forEach(({ address, group }: { address: string, group: string}) => {
                if (state?.userData?.[address]?.userInfo) {
                    state.userData[address].userInfo!.group = group
                } else {
                    state.userData ? state.userData[address] = {
                        ...DEFAULT_USER_DATA,
                        userInfo: {
                            ...DEFAULT_USER_INFO,
                            group: group
                        }
                    } : state.userData = {
                        [address]: {
                            ...DEFAULT_USER_DATA,
                            userInfo: {
                                ...DEFAULT_USER_INFO,
                                group: group
                            }
                        }
                    }
                }
            })

        },
        setUserType: (state, action) => {
            const typeData = action.payload
            typeData.forEach(({ address, type }: { address: string, type: string}) => {
                if (state?.userData?.[address]?.userInfo) {
                    state.userData[address].userInfo!.type = type
                } else {
                    state.userData ? state.userData[address] = {
                        ...DEFAULT_USER_DATA,
                        userInfo: {
                            ...DEFAULT_USER_INFO,
                            type
                        }
                    } : state.userData = {
                        [address]: {
                            ...DEFAULT_USER_DATA,
                            userInfo: {
                                ...DEFAULT_USER_INFO,
                                type
                            }
                        }
                    }
                }
            })

        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMarketData.pending, (dashboardState) => {
			dashboardState.queryStatuses.marketData = LOADING_STATUS
		})
		builder.addCase(fetchMarketData.fulfilled, (dashboardState, action) => {
			dashboardState.queryStatuses.marketData = SUCCESS_STATUS
            if (action.payload) {
                // const shouldReplaceMarketData = isBiggerDataSet(action.payload.marketData, dashboardState.marketData.candles)
			    // dashboardState.marketData.candles = shouldReplaceMarketData ? action.payload.marketData : dashboardState.marketData.candles.concat(action.payload.marketData)
			    
                dashboardState.marketData.candles = action.payload.marketData
                
                // const shouldReplaceWalletData = isBiggerDataSet(action.payload.walletData, dashboardState.cumulativeGasSpendChart)
                // dashboardState.cumulativeGasSpendChart = shouldReplaceWalletData ? action.payload.walletData : dashboardState.cumulativeGasSpendChart.concat(action.payload.walletData)
                
                // dashboardState.contractTransactions = action.payload.contractTransactions
                // dashboardState.topContractsChart = action.payload.topContractData
                // dashboardState.topTransactionTypesChart = action.payload.topTransactionTypesData
            }
		})
		builder.addCase(fetchMarketData.rejected, (dashboardState) => {
			dashboardState.queryStatuses.marketData = {
				status: FetchStatus.Error,
				error: 'Failed to fetch volume data',
			}
		})
        builder.addCase(fetchCurrentGasPrice.fulfilled, (dashboardState, action) => {
			dashboardState.marketData.currentGasPrice = action.payload
		})

        builder.addCase(fetchCumulativeGasSpendData.pending, (dashboardState) => {
			dashboardState.queryStatuses.cumulativeGasSpendData = LOADING_STATUS
		})
		builder.addCase(fetchCumulativeGasSpendData.fulfilled, (dashboardState, action) => {
            const {
                walletAddress,
                dailyTransactionDetails,
                userInfo
            } = action.payload
			dashboardState.userData[walletAddress] = {
                ...(dashboardState.userData[walletAddress] ?? {}),
                cumulativeGasSpendChart: dailyTransactionDetails,
                //@ts-ignore
                userInfo: {
                    // defaults
                    avgGasSpentEth: 0, avgGasSpentUsd: 0, avgGasPrice: 0, percentageOnGas: 0, 
                    totalGasSpentEth: 0, totalGasSpentUsd: 0, transactionCount: 0, totalGasUsed: 0, 
                    // existing
                    ...(dashboardState.userData[walletAddress]?.userInfo ?? null),
                    // new
                    ...userInfo
                }
            }
			dashboardState.queryStatuses.cumulativeGasSpendData = SUCCESS_STATUS
		})
		builder.addCase(fetchCumulativeGasSpendData.rejected, (dashboardState) => {
			dashboardState.queryStatuses.cumulativeGasSpendData = {
				error: 'Failed to fetch protocol gas spent data',
				status: FetchStatus.Error,
			}
		})

        builder.addCase(fetchContractTransactionsData.pending, (dashboardState) => {
			dashboardState.queryStatuses.contractTransactionsData = LOADING_STATUS
		})
		builder.addCase(fetchContractTransactionsData.fulfilled, (dashboardState, action) => {
            const {
                walletAddress,
                gasSpentTable,
                protocolBreakdown,
                topProtocolNames,
            } = action.payload
			dashboardState.userData[walletAddress] = {
                ...dashboardState.userData[walletAddress],
                gasSpentTableData: gasSpentTable,
                contractTransactions: protocolBreakdown,
                topProtocolNames: topProtocolNames
            }
			dashboardState.queryStatuses.contractTransactionsData = SUCCESS_STATUS
		})
		builder.addCase(fetchContractTransactionsData.rejected, (dashboardState) => {
			dashboardState.queryStatuses.contractTransactionsData = {
				error: 'Failed to fetch protocol gas spent data',
				status: FetchStatus.Error,
			}
		})
    }
})

export const {
    setSelectedDashboardTimeRange,
    setSelectedDashboardCurrency,
    setTransactionTypeExpanded,
    setSelectedWallet,
    setUserGroup,
    setUserType
} = dashboardSlice.actions

export default dashboardSlice.reducer