var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { formatBytes32String } from '@ethersproject/strings';
import request, { gql } from 'graphql-request';
import { SMART_MARGIN_FRAGMENT, ISOLATED_MARGIN_FRAGMENT, DEFAULT_NUMBER_OF_TRADES, } from '../constants/futures';
import { mapMarginTransfers, mapSmartMarginTransfers } from '../utils/futures';
import { getFuturesPositions, getFuturesTrades } from '../utils/subgraph';
export const queryAccountsFromSubgraph = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    if (!walletAddress)
        return [];
    const response = yield request(sdk.futures.futuresGqlEndpoint, gql `
			query crossMarginAccounts($owner: String!) {
				crossMarginAccounts(where: { owner: $owner }) {
					id
					owner
				}
			}
		`, { owner: walletAddress });
    return (response === null || response === void 0 ? void 0 : response.crossMarginAccounts.map((cm) => cm.id)) || [];
});
export const queryCrossMarginAccounts = (sdk, walletAddress) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    // TODO: Contract should be updating to support one to many
    console.log("queryCrossMarginAccounts ==> ");
    const accounts = yield ((_a = sdk.context.contracts.SmartMarginAccountFactory) === null || _a === void 0 ? void 0 : _a.getAccountsOwnedBy(walletAddress));
    return accounts !== null && accounts !== void 0 ? accounts : [];
});
export const queryTrades = (sdk, params) => __awaiter(void 0, void 0, void 0, function* () {
    const filter = {
        account: params.walletAddress,
        accountType: params.accountType === 'isolated_margin' ? 'isolated_margin' : 'smart_margin',
    };
    if (params.marketAsset) {
        filter['asset'] = formatBytes32String(params.marketAsset);
    }
    return getFuturesTrades(sdk.futures.futuresGqlEndpoint, {
        first: params.pageLength,
        where: filter,
        orderDirection: 'desc',
        orderBy: 'timestamp',
    }, {
        id: true,
        timestamp: true,
        account: true,
        abstractAccount: true,
        accountType: true,
        margin: true,
        size: true,
        marketKey: true,
        asset: true,
        price: true,
        positionId: true,
        positionSize: true,
        positionClosed: true,
        pnl: true,
        feesPaid: true,
        keeperFeesPaid: true,
        orderType: true,
        trackingCode: true,
        fundingAccrued: true,
    });
});
export const queryPositionHistory = (sdk, account) => {
    return getFuturesPositions(sdk.futures.futuresGqlEndpoint, {
        where: {
            abstractAccount: account,
        },
        first: 99999,
        orderBy: 'openTimestamp',
        orderDirection: 'desc',
    }, {
        id: true,
        lastTxHash: true,
        openTimestamp: true,
        closeTimestamp: true,
        timestamp: true,
        market: true,
        marketKey: true,
        asset: true,
        account: true,
        abstractAccount: true,
        accountType: true,
        isOpen: true,
        isLiquidated: true,
        trades: true,
        totalVolume: true,
        size: true,
        initialMargin: true,
        margin: true,
        pnl: true,
        feesPaid: true,
        netFunding: true,
        pnlWithFeesPaid: true,
        netTransfers: true,
        totalDeposits: true,
        fundingIndex: true,
        entryPrice: true,
        avgEntryPrice: true,
        lastPrice: true,
        exitPrice: true,
    });
};
export const queryCompletePositionHistory = (sdk, account) => {
    return getFuturesPositions(sdk.futures.futuresGqlEndpoint, {
        where: {
            account: account,
        },
        first: 99999,
        orderBy: 'openTimestamp',
        orderDirection: 'desc',
    }, {
        id: true,
        lastTxHash: true,
        openTimestamp: true,
        closeTimestamp: true,
        timestamp: true,
        market: true,
        marketKey: true,
        asset: true,
        account: true,
        abstractAccount: true,
        accountType: true,
        isOpen: true,
        isLiquidated: true,
        trades: true,
        totalVolume: true,
        size: true,
        initialMargin: true,
        margin: true,
        pnl: true,
        feesPaid: true,
        netFunding: true,
        pnlWithFeesPaid: true,
        netTransfers: true,
        totalDeposits: true,
        fundingIndex: true,
        entryPrice: true,
        avgEntryPrice: true,
        lastPrice: true,
        exitPrice: true,
    });
};
export const queryIsolatedMarginTransfers = (sdk, account) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.futures.futuresGqlEndpoint, ISOLATED_MARGIN_FRAGMENT, {
        walletAddress: account,
    });
    return response ? mapMarginTransfers(response.futuresMarginTransfers) : [];
});
export const querySmartMarginTransfers = (sdk, account) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.futures.futuresGqlEndpoint, SMART_MARGIN_FRAGMENT, {
        walletAddress: account,
    });
    return response ? mapSmartMarginTransfers(response.smartMarginAccountTransfers) : [];
});
export const queryFuturesTrades = (sdk, marketKey, minTs, maxTs) => {
    return getFuturesTrades(sdk.futures.futuresGqlEndpoint, {
        first: DEFAULT_NUMBER_OF_TRADES,
        where: {
            marketKey: formatBytes32String(marketKey),
            timestamp_gt: minTs,
            timestamp_lt: maxTs,
        },
        orderDirection: 'desc',
        orderBy: 'timestamp',
    }, {
        id: true,
        timestamp: true,
        account: true,
        abstractAccount: true,
        accountType: true,
        margin: true,
        size: true,
        marketKey: true,
        asset: true,
        price: true,
        positionId: true,
        positionSize: true,
        positionClosed: true,
        pnl: true,
        feesPaid: true,
        keeperFeesPaid: true,
        orderType: true,
        fundingAccrued: true,
        trackingCode: true,
    });
};
export const queryFundingRateHistory = (sdk, marketAsset, minTimestamp, period = 'Hourly') => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request(sdk.futures.futuresGqlEndpoint, gql `
			query fundingRateUpdate(
				$marketAsset: Bytes!
				$minTimestamp: BigInt!
				$period: FundingRatePeriodType!
			) {
				fundingRatePeriods(
					where: { asset: $marketAsset, timestamp_gt: $minTimestamp, period: $period }
					first: 1000
				) {
					timestamp
					fundingRate
				}
			}
		`, { marketAsset: formatBytes32String(marketAsset), minTimestamp, period });
    return response.fundingRatePeriods.map((x) => ({
        timestamp: Number(x.timestamp) * 1000,
        fundingRate: Number(x.fundingRate),
    }));
});
