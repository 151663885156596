import { FC, ReactNode, createContext, useContext, useEffect } from 'react';

export type VERSION = "dashboard"|"futures"

const VersionContext = createContext<VERSION>("dashboard");

export const useVersion = () => useContext(VersionContext);

export const VersionContextProvider : FC<{ children?: ReactNode, version: VERSION }> = (({children, version})=>{
  useEffect(()=>{
    console.log("VersionContext version",version);
  },[version]);
  return (
    <VersionContext.Provider value={version}>
      {children}
    </VersionContext.Provider>
  )
});