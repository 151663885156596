var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
import { wei } from '@synthetixio/wei';
import BN from 'bn.js';
import { Contract as MultiCallContract } from 'ethcall';
import { KWENTA_TRACKING_CODE } from '../constants/futures';
import { ZERO_WEI, ZERO_BIG_NUM, UNIT_BIG_NUM, UNIT_BN } from '../constants/number';
import { PotentialTradeStatus } from '../types/futures';
import { multiplyDecimal, divideDecimal } from '../utils/number';
import PerpsV2Market from './abis/PerpsV2Market.json';
import { PerpsV2Market__factory } from './types';
class FuturesMarketInternal {
    constructor(sdk, provider, marketKey, marketAddress) {
        this.getTradePreview = (account, sizeDelta, marginDelta, tradePrice, takerFeeDelayedOrder, makerFeeDelayedOrder) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const multiCallContract = new MultiCallContract(this._perpsV2MarketContract.address, PerpsV2Market);
            const preFetchedData = yield this._sdk.context.multicallProvider.all([
                multiCallContract.assetPrice(),
                multiCallContract.marketSkew(),
                multiCallContract.marketSize(),
                multiCallContract.accruedFunding(account),
                multiCallContract.fundingSequenceLength(),
                multiCallContract.fundingLastRecomputed(),
                multiCallContract.fundingRateLastRecomputed(),
                multiCallContract.positions(account),
            ]);
            console.log("getTradePreview ==> first line");
            console.log("getTradePreview ==> preFetchedData");
            console.log(preFetchedData);
            const blockNum = yield ((_a = this._provider) === null || _a === void 0 ? void 0 : _a.getBlockNumber());
            this._block = yield fetchBlockWithRetry(blockNum, this._provider);
            const remainingMargin = yield this._getRemainingMargin(account);
            this._onChainData = {
                //@ts-ignore
                assetPrice: preFetchedData[0].price,
                marketSkew: preFetchedData[1],
                marketSize: preFetchedData[2],
                //@ts-ignore
                accruedFunding: preFetchedData[3].funding,
                fundingSequenceLength: preFetchedData[4],
                fundingLastRecomputed: preFetchedData[5],
                fundingRateLastRecomputed: preFetchedData[6],
            };
            //this._onChainData.assetPrice = this._onChainData.assetPrice.div(1e18);
            console.log("getTradePreview ==> this._onChainData.assetPrice");
            console.log(Number(this._onChainData.assetPrice));
            console.log("getTradePreview ==> this._onChainData.marketSkew");
            console.log(Number(this._onChainData.marketSkew));
            console.log("getTradePreview ==> this._onChainData.marketSize");
            console.log(Number(this._onChainData.marketSize));
            console.log("getTradePreview ==> this._onChainData.accruedFunding");
            console.log(Number(this._onChainData.accruedFunding));
            console.log("getTradePreview ==> this._onChainData.fundingSequenceLength");
            console.log(Number(this._onChainData.fundingSequenceLength));
            console.log("getTradePreview ==> this._onChainData.fundingLastRecomputed");
            console.log(Number(this._onChainData.fundingLastRecomputed));
            console.log("getTradePreview ==> this.fundingRateLastRecomputed.");
            console.log(Number(this._onChainData.fundingRateLastRecomputed));
            //this._onChainData.assetPrice = divideDecimal(this._onChainData.assetPrice,BigNumber.from("10000000000000000000"));
            //this._onChainData.marketSkew = this._onChainData.marketSkew.div(1e18);g
            //this._onChainData.marketSize = this._onChainData.marketSize.div(1e18);
            //this._onChainData.fundingRateLastRecomputed = this._onChainData.fundingRateLastRecomputed.div(1e18);
            //this._onChainData.marketSkew = mulDecimal(this._onChainData.marketSkew,BigNumber.from("10000000000000000000"));
            const position = preFetchedData[7];
            console.log("getTradePreview ==> position");
            console.log(Number(position));
            const takerFeeOffchain = yield this._getSetting('takerFeeDelayedOrder');
            const makerFeeOffchain = yield this._getSetting('makerFeeDelayedOrder');
            const takerFee = takerFeeDelayedOrder || takerFeeOffchain;
            const makerFee = makerFeeDelayedOrder || makerFeeOffchain;
            console.log("getTradePreview ==> takerFee");
            console.log(Number(takerFee));
            console.log("getTradePreview ==> makerFee");
            console.log(Number(makerFee));
            console.log("getTradePreview ==> tradePrice");
            console.log(Number(tradePrice));
            console.log("getTradePreview ==> sizeDelta");
            console.log(Number(sizeDelta));
            let fillPrice = yield this._fillPrice(sizeDelta, tradePrice);
            console.log("getTradePreview ==> fillPrice");
            console.log(Number(fillPrice));
            const tradeParams = {
                sizeDelta: sizeDelta,
                fillPrice: fillPrice,
                desiredFillPrice: tradePrice,
                makerFee: makerFee,
                takerFee: takerFee,
                trackingCode: KWENTA_TRACKING_CODE,
            };
            const { newPos, fee, status } = yield this._postTradeDetails(position, tradeParams, marginDelta, remainingMargin);
            let liqPrice = yield this._approxLiquidationPrice(newPos, newPos.lastPrice);
            //liqPrice = liqPrice.mul(1e9);
            return Object.assign(Object.assign({}, newPos), { liqPrice: liqPrice, fee, price: newPos.lastPrice, status: status });
        });
        this.getMinimumMargin = (sizeDelta, tradePrice, exchangeMaxDynamicFee) => __awaiter(this, void 0, void 0, function* () {
            const liquidationBufferRatio = yield this._getSetting('liquidationBufferRatio');
            const liqKeeperFee = yield this._getSetting('keeperLiquidationFee');
            const minFee = yield this._getSetting('minKeeperFee');
            const minInitialMargin = yield this._getSetting('minInitialMargin');
            const liquidationMargin = yield this._liquidationMargin(sizeDelta, tradePrice);
            const maxTradingFee = yield this._maxTradingFee(sizeDelta, tradePrice, exchangeMaxDynamicFee);
            const minMarginBySize = minFee.mul(2).add(liquidationMargin).add(maxTradingFee);
            // return wei(liqKeeperFee).mul(wei(liquidationBufferRatio).mul(10).add(1)).add(wei(minFee)).add(minInitialMargin).mul(2)
            return minMarginBySize.gt(minInitialMargin) ? minMarginBySize : minInitialMargin;
        });
        this.getMinInitialMargin = () => __awaiter(this, void 0, void 0, function* () {
            const minInitialMargin = yield this._getSetting('minInitialMargin');
            return wei(minInitialMargin);
        });
        this.getAsseetPrice = () => __awaiter(this, void 0, void 0, function* () {
            const multiCallContract = new MultiCallContract(this._perpsV2MarketContract.address, PerpsV2Market);
            const preFetchedData = yield this._sdk.context.multicallProvider.all([
                multiCallContract.assetPrice()
            ]);
            const assetPrice = preFetchedData[0];
            return assetPrice;
        });
        this._getRemainingMargin = (account) => __awaiter(this, void 0, void 0, function* () {
            const marketDataContract = this._sdk.context.multicallContracts.PerpsV2MarketData;
            const positionCalls = [];
            positionCalls.push(marketDataContract === null || marketDataContract === void 0 ? void 0 : marketDataContract.positionDetailsForMarketKey(this._marketKeyBytes, account));
            const positionDetails = (yield this._sdk.context.multicallProvider.all(positionCalls));
            return positionDetails.length > 0 ? wei(positionDetails[0].remainingMargin).toBN() : ZERO_BIG_NUM;
        });
        this._maxTradingFee = (sizeDelta, tradePrice, exchangeMaxDynamicFee) => __awaiter(this, void 0, void 0, function* () {
            const takerFee = yield this._getSetting('takerFeeDelayedOrder');
            return takerFee.mul(2).add(exchangeMaxDynamicFee).mul(multiplyDecimal(sizeDelta, tradePrice)).div(1e9).abs();
        });
        this._postTradeDetails = (oldPos, tradeParams, marginDelta, remainingMargin) => __awaiter(this, void 0, void 0, function* () {
            const getOldPosition = () => ({
                id: oldPos.id,
                lastFundingIndex: oldPos.lastFundingIndex,
                margin: oldPos.margin,
                lastPrice: oldPos.lastPrice,
                size: oldPos.size
            });
            console.log("_postTradeDetails ==>");
            console.log("_postTradeDetails ==> oldPos.size");
            console.log(oldPos.size);
            if (!this._sdk.context.contracts.Exchanger)
                throw new Error('Unsupported network');
            // Reverts if the user is trying to submit a size-zero order.
            if (tradeParams.sizeDelta.eq(0) && marginDelta.eq(0)) {
                return { newPos: getOldPosition(), fee: ZERO_BIG_NUM, status: PotentialTradeStatus.NIL_ORDER };
            }
            // The order is not submitted if the user's existing position needs to be liquidated.
            if (yield this._canLiquidate(oldPos, this._onChainData.assetPrice)) {
                return { newPos: getOldPosition(), fee: ZERO_BIG_NUM, status: PotentialTradeStatus.CAN_LIQUIDATE };
            }
            const fee = yield this._orderFee(tradeParams);
            const { margin, status } = yield this._recomputeMarginWithDelta(oldPos, this._onChainData.assetPrice, marginDelta.sub(fee), remainingMargin);
            if (status !== PotentialTradeStatus.OK) {
                return { newPos: getOldPosition(), fee: ZERO_BIG_NUM, status };
            }
            const lastFundingIndex = yield this._latestFundingIndex();
            console.log("_postTradeDetails ==> tradeParams.sizeDelta");
            console.log(tradeParams.sizeDelta);
            console.log("_postTradeDetails ==> oldPos.sizeDelta");
            console.log(oldPos);
            const newPos = {
                id: oldPos.id,
                lastFundingIndex: lastFundingIndex,
                margin: margin,
                lastPrice: tradeParams.fillPrice,
                size: oldPos.size.add(tradeParams.sizeDelta)
            };
            console.log("_postTradeDetails ==> CnewPos");
            console.log(newPos);
            const minInitialMargin = yield this._getSetting('minInitialMargin');
            const positionDecreasing = newPos.size.eq(ZERO_BIG_NUM) ||
                (oldPos.size.gte(ZERO_BIG_NUM) === newPos.size.gte(ZERO_BIG_NUM) && newPos.size.abs().lt(oldPos.size.abs()));
            if (!positionDecreasing) {
                if (newPos.margin.add(fee).lt(minInitialMargin)) {
                    return {
                        newPos: getOldPosition(),
                        fee: ZERO_BIG_NUM,
                        status: PotentialTradeStatus.INSUFFICIENT_MARGIN,
                    };
                }
            }
            const liqPremium = yield this._liquidationPremium(newPos.size, this._onChainData.assetPrice);
            let liqMargin = yield this._liquidationMargin(newPos.size, this._onChainData.assetPrice);
            liqMargin = liqMargin.add(liqPremium);
            if (margin.lte(liqMargin)) {
                return { newPos, fee: ZERO_BIG_NUM, status: PotentialTradeStatus.CAN_LIQUIDATE };
            }
            const maxLeverage = yield this._getSetting('maxLeverage');
            const maxLeverageForSize = yield this._maxLeverageForSize(newPos.size);
            console.log("_postTradeDetails ==> AnewPos.size");
            console.log(newPos.size);
            const leverage = divideDecimal(multiplyDecimal(newPos.size, tradeParams.fillPrice), margin.add(fee));
            console.log("_postTradeDetails ==> BnewPos.size");
            console.log(newPos.size);
            if (maxLeverage.add(UNIT_BIG_NUM.div(100)).lt(leverage.abs()) ||
                leverage.abs().gt(maxLeverageForSize)) {
                return {
                    newPos: getOldPosition(),
                    fee: ZERO_BIG_NUM,
                    status: PotentialTradeStatus.MAX_LEVERAGE_EXCEEDED,
                };
            }
            const maxMarketValue = yield this._getSetting('maxMarketValue');
            //console.log("_postTradeDetails ==> maxMarketValue");
            //console.log(maxMarketValue);
            const tooLarge = yield this._orderSizeTooLarge(maxMarketValue, oldPos.size, newPos.size);
            console.log("_postTradeDetails ==> tooLarge");
            console.log(tooLarge);
            console.log("_postTradeDetails ==> newPos.size");
            console.log(newPos.size);
            if (tooLarge) {
                return {
                    newPos: getOldPosition(),
                    fee: ZERO_BIG_NUM,
                    status: PotentialTradeStatus.MAX_MARKET_SIZE_EXCEEDED,
                };
            }
            return { newPos, fee: fee, status: PotentialTradeStatus.OK };
        });
        this._liquidationPremium = (positionSize, currentPrice) => __awaiter(this, void 0, void 0, function* () {
            if (positionSize.eq(0)) {
                return 0;
            }
            // note: this is the same as fillPrice() where the skew is 0.
            const notional = multiplyDecimal(positionSize, currentPrice).abs();
            console.log("_liquidationPremium ==> notional");
            console.log(notional);
            let skewScale = yield this._getSetting('skewScale');
            //skewScale = skewScale.div(1e9);
            let liqPremiumMultiplier = yield this._getSetting('liquidationPremiumMultiplier');
            //liqPremiumMultiplier = liqPremiumMultiplier.div(1e11);
            const skewedSize = divideDecimal(positionSize.abs(), skewScale);
            const value = multiplyDecimal(skewedSize, notional);
            return multiplyDecimal(value, liqPremiumMultiplier);
        });
        this._orderFee = (tradeParams) => __awaiter(this, void 0, void 0, function* () {
            const notionalDiff = multiplyDecimal(tradeParams.sizeDelta, tradeParams.fillPrice);
            const marketSkew = yield this._onChainData.marketSkew;
            if (this._sameSide(marketSkew.add(tradeParams.sizeDelta), marketSkew)) {
                const staticRate = this._sameSide(notionalDiff, marketSkew)
                    ? tradeParams.takerFee
                    : tradeParams.makerFee;
                return multiplyDecimal(notionalDiff, staticRate).abs();
            }
            // IGNORED DYNAMIC FEE //
            const takerSize = divideDecimal(marketSkew.add(tradeParams.sizeDelta), tradeParams.sizeDelta).abs();
            const makerSize = UNIT_BIG_NUM.sub(takerSize);
            const takerFee = multiplyDecimal(multiplyDecimal(notionalDiff, takerSize), tradeParams.takerFee).abs();
            const makerFee = multiplyDecimal(multiplyDecimal(notionalDiff, makerSize), tradeParams.makerFee).abs();
            return takerFee.add(makerFee);
        });
        this._recomputeMarginWithDelta = (position, price, marginDelta, remainingMargin) => __awaiter(this, void 0, void 0, function* () {
            const newMargin = remainingMargin.add(marginDelta);
            if (newMargin.lt(ZERO_WEI.toBN())) {
                return { margin: ZERO_WEI.toBN(), status: PotentialTradeStatus.INSUFFICIENT_MARGIN };
            }
            const lMargin = yield this._liquidationMargin(position.size, price);
            if (!position.size.isZero() && newMargin.lt(lMargin)) {
                return { margin: newMargin, status: PotentialTradeStatus.CAN_LIQUIDATE };
            }
            return { margin: newMargin, status: PotentialTradeStatus.OK };
        });
        this._marginPlusProfitFunding = (position, price) => __awaiter(this, void 0, void 0, function* () {
            const funding = this._onChainData.accruedFunding;
            const profitLost = this._profitLoss(position, price);
            return position.margin.add(profitLost).add(funding);
        });
        this._profitLoss = (position, price) => {
            const priceShift = price.sub(position.lastPrice);
            return multiplyDecimal(position.size, priceShift);
        };
        this._nextFundingEntry = (price) => __awaiter(this, void 0, void 0, function* () {
            const latestFundingIndex = yield this._latestFundingIndex();
            const fundingSequenceVal = yield this._perpsV2MarketContract.fundingSequence(latestFundingIndex);
            const unrecordedFunding = yield this._unrecordedFunding(price);
            return fundingSequenceVal.add(unrecordedFunding);
        });
        this._latestFundingIndex = () => __awaiter(this, void 0, void 0, function* () {
            const fundingSequenceLength = this._onChainData.fundingSequenceLength;
            return fundingSequenceLength.sub(1); // at least one element is pushed in constructor
        });
        this._netFundingPerUnit = (startIndex, price) => __awaiter(this, void 0, void 0, function* () {
            const fundingSequenceVal = yield this._perpsV2MarketContract.fundingSequence(startIndex.toNumber());
            const nextFunding = yield this._nextFundingEntry(price);
            return nextFunding.sub(fundingSequenceVal);
        });
        this._proportionalElapsed = () => __awaiter(this, void 0, void 0, function* () {
            // TODO: get block at the start
            if (!this._block)
                throw new Error('Missing block data');
            const fundingLastRecomputed = this._onChainData.fundingLastRecomputed;
            const rate = BigNumber.from(this._block.timestamp).sub(fundingLastRecomputed);
            return divideDecimal(rate, BigNumber.from(86400));
        });
        this._currentFundingVelocity = () => __awaiter(this, void 0, void 0, function* () {
            const maxFundingVelocity = yield this._getSetting('maxFundingVelocity');
            const skew = yield this._proportionalSkew();
            return multiplyDecimal(skew, maxFundingVelocity);
        });
        this._currentFundingRate = () => __awaiter(this, void 0, void 0, function* () {
            const fundingRateLastRecomputed = this._onChainData.fundingRateLastRecomputed;
            const elapsed = yield this._proportionalElapsed();
            const velocity = yield this._currentFundingVelocity();
            return BigNumber.from(fundingRateLastRecomputed).add(multiplyDecimal(velocity, elapsed));
        });
        this._unrecordedFunding = (price) => __awaiter(this, void 0, void 0, function* () {
            const fundingRateLastRecomputed = BigNumber.from(this._onChainData.fundingRateLastRecomputed);
            const nextFundingRate = yield this._currentFundingRate();
            const elapsed = yield this._proportionalElapsed();
            const avgFundingRate = divideDecimal(fundingRateLastRecomputed.add(nextFundingRate).mul(-1), UNIT_BIG_NUM.mul(2));
            return multiplyDecimal(multiplyDecimal(avgFundingRate, elapsed), price);
        });
        this._proportionalSkew = () => __awaiter(this, void 0, void 0, function* () {
            const marketSkew = yield this._onChainData.marketSkew;
            const skewScale = yield this._getSetting('skewScale');
            const pSkew = divideDecimal(marketSkew, skewScale);
            // Ensures the proportionalSkew is between -1 and 1.
            const proportionalSkew = BN.min(BN.max(UNIT_BN.neg(), new BN(pSkew.toString())), UNIT_BN);
            return BigNumber.from(proportionalSkew.toString());
        });
        this._approxLiquidationPrice = (position, currentPrice) => __awaiter(this, void 0, void 0, function* () {
            if (position.size.isZero()) {
                return BigNumber.from('0');
            }
            //position.size = position.size.mul(1e9);
            //position.lastPrice = position.lastPrice.div(1e9);
            //currentPrice = divideDecimal(currentPrice,BigNumber.from("1000000000000000000000000000"));
            //position.lastPrice = divideDecimal(position.lastPrice,BigNumber.from("1000000000000000000000000000"));
            //position.size = wei(position.size).mul(1e18).toBN();
            position.lastPrice = wei(position.lastPrice).mul(1e9).toBN();
            currentPrice = wei(currentPrice).mul(1e9).toBN();
            console.log("_approxLiquidationPrice ==> position.lastFundingIndex");
            console.log(Number(position.lastFundingIndex));
            console.log("_approxLiquidationPrice ==> currentPrice");
            console.log(Number(currentPrice));
            console.log("_approxLiquidationPrice ==> position.size");
            console.log(Number(position.size));
            //position.size = multiplyDecimal(position.size,BigNumber.from("1000000000000000000"));
            //position.size = wei(position.size).mul(1e19).toBN();
            console.log("_approxLiquidationPrice ==> position.size B");
            console.log(Number(position.size));
            console.log("_approxLiquidationPrice ==> position.lastPrice");
            console.log(Number(position.lastPrice));
            console.log("_approxLiquidationPrice ==> position.margin");
            console.log(Number(position.margin));
            let fundingPerUnit = yield this._netFundingPerUnit(position.lastFundingIndex, currentPrice);
            //fundingPerUnit = divideDecimal(fundingPerUnit,BigNumber.from("1000000000000000000"));
            console.log("_approxLiquidationPrice ==> fundingPerUnit");
            console.log(Number(fundingPerUnit));
            //fundingPerUnit = fundingPerUnit.div(1e9);
            let liqMargin = yield this._liquidationMargin(position.size, currentPrice);
            console.log("_approxLiquidationPrice ==> liqMargin");
            console.log(Number(liqMargin));
            let liqPremium = yield this._liquidationPremium(position.size, currentPrice);
            console.log("_approxLiquidationPrice ==> liqPremium");
            console.log(Number(liqPremium));
            //liqPremium = liqPremium.div(1e9);
            //position.lastPrice = position.lastPrice.div(1e9);
            //position.margin = position.margin.div(1e18);
            //liqMargin = liqMargin.div(1e18);
            let result = position.lastPrice
                .add(divideDecimal(liqMargin.sub(position.margin.sub(liqPremium)), position.size))
                //.add(liqMargin.sub(position.margin.sub(liqPremium)).div(position.size))
                .sub(fundingPerUnit);
            //result = wei(result).div(1e17).toBN();
            console.log("_approxLiquidationPrice ==> result");
            console.log(Number(result));
            let final_res = result.lt(0) ? BigNumber.from(0) : result;
            console.log("_approxLiquidationPrice ==> final_res");
            console.log(Number(final_res));
            return final_res;
            return BigNumber.from(1);
        });
        this._exactLiquidationMargin = (positionSize, price) => __awaiter(this, void 0, void 0, function* () {
            const keeperFee = yield this._liquidationFee(positionSize, price);
            const stakerFee = yield this._stakerFee(positionSize, price);
            return keeperFee.add(stakerFee);
        });
        this._liquidationMargin = (positionSize, price) => __awaiter(this, void 0, void 0, function* () {
            const liquidationBufferRatio = yield this._getSetting('liquidationBufferRatio');
            const liqKeeperFee = yield this._getSetting('keeperLiquidationFee');
            const liquidationBuffer = multiplyDecimal(multiplyDecimal(positionSize.abs(), price), liquidationBufferRatio);
            console.log("_liquidationMargin ==> liquidationBufferRatio");
            console.log(Number(liquidationBufferRatio));
            console.log("_liquidationMargin ==> liqKeeperFee");
            console.log(Number(liqKeeperFee));
            console.log("_liquidationMargin ==> positionSize");
            console.log(Number(positionSize));
            console.log("_liquidationMargin ==> price");
            console.log(Number(price));
            console.log("_liquidationMargin ==> liquidationBuffer");
            console.log(Number(liquidationBuffer));
            const fee = yield this._liquidationFee(positionSize, price);
            console.log("_liquidationMargin ==> fee");
            console.log(Number(fee));
            let result = liquidationBuffer.add(fee).add(liqKeeperFee);
            console.log("_liquidationMargin ==> result: " + Number(result));
            return result;
        });
        this._liquidationFee = (positionSize, price) => __awaiter(this, void 0, void 0, function* () {
            const liquidationFeeRatio = yield this._getSetting('liquidationFeeRatio');
            const minFee = yield this._getSetting('minKeeperFee');
            const maxFee = yield this._getSetting('maxKeeperFee');
            const proportionalFee = multiplyDecimal(multiplyDecimal(positionSize.abs(), price), liquidationFeeRatio);
            const cappedProportionalFee = proportionalFee.gt(maxFee) ? maxFee : proportionalFee;
            return cappedProportionalFee.gt(minFee) ? proportionalFee : minFee;
        });
        this._stakerFee = (positionSize, price) => __awaiter(this, void 0, void 0, function* () {
            const liquidationBufferRatio = yield this._getSetting('liquidationBufferRatio');
            const stakerFee = multiplyDecimal(multiplyDecimal(positionSize.abs(), price), liquidationBufferRatio);
            return stakerFee;
        });
        this._fillPrice = (size, price) => __awaiter(this, void 0, void 0, function* () {
            //size = wei(size).mul(1e18).toBN();
            //price = wei(price).div(1e18).toBN();
            const marketSkew = yield this._onChainData.marketSkew;
            const skewScale = yield this._getSetting('skewScale');
            const pdBefore = divideDecimal(marketSkew, skewScale);
            const pdAfter = divideDecimal(marketSkew.add(size), skewScale);
            const priceBefore = price.add(multiplyDecimal(price, pdBefore));
            const priceAfter = price.add(multiplyDecimal(price, pdAfter));
            // How is the p/d-adjusted price calculated using an example:
            //
            // price      = $1200 USD (oracle)
            // size       = 100
            // skew       = 0
            // skew_scale = 1,000,000 (1M)
            //
            // Then,
            //
            // pd_before = 0 / 1,000,000
            //           = 0
            // pd_after  = (0 + 100) / 1,000,000
            //           = 100 / 1,000,000
            //           = 0.0001
            //
            // price_before = 1200 * (1 + pd_before)
            //              = 1200 * (1 + 0)
            //              = 1200
            // price_after  = 1200 * (1 + pd_after)
            //              = 1200 * (1 + 0.0001)
            //              = 1200 * (1.0001)
            //              = 1200.12
            // Finally,
            //
            // fill_price = (price_before + price_after) / 2
            //            = (1200 + 1200.12) / 2
            //            = 1200.06
            return divideDecimal(priceBefore.add(priceAfter), UNIT_BIG_NUM.mul(2));
        });
        this._canLiquidate = (position, price) => __awaiter(this, void 0, void 0, function* () {
            // No liquidating empty positions.
            console.log("_canLiquidate ==> position.size");
            console.log(Number(position.size));
            console.log("_canLiquidate ==> price");
            console.log(Number(price));
            if (position.size.eq(0)) {
                return false;
            }
            const remainingLiquidatableMargin = yield this._remainingLiquidatableMargin(position, price);
            const liqMargin = yield this._liquidationMargin(position.size, price);
            return remainingLiquidatableMargin.lt(liqMargin);
        });
        this._remainingLiquidatableMargin = (position, price) => __awaiter(this, void 0, void 0, function* () {
            const liqPremium = yield this._liquidationPremium(position.size, price);
            const marginPlusProfitFunding = yield this._marginPlusProfitFunding(position, price);
            const remaining = marginPlusProfitFunding.sub(liqPremium);
            return remaining.gt(0) ? remaining : ZERO_BIG_NUM;
        });
        this._orderSizeTooLarge = (maxSize, oldSize, newSize) => __awaiter(this, void 0, void 0, function* () {
            if ((this._sameSide(oldSize, newSize) && newSize.abs().lte(oldSize.abs())) || newSize.eq(0)) {
                return false;
            }
            const marketSkew = this._onChainData.marketSkew;
            const marketSize = this._onChainData.marketSize;
            console.log("_orderSizeTooLarge ==> marketSkew");
            console.log(marketSkew);
            console.log("_orderSizeTooLarge ==> marketSize");
            console.log(marketSize);
            const newSkew = marketSkew.sub(oldSize).add(newSize);
            const newMarketSize = marketSize.sub(oldSize.abs()).add(newSize.abs());
            let newSideSize;
            if (newSize.gt(ZERO_BIG_NUM)) {
                newSideSize = newMarketSize.add(newSkew);
            }
            else {
                newSideSize = newMarketSize.sub(newSkew);
            }
            if (maxSize.lt(newSideSize.div(2).abs())) {
                return true;
            }
            return false;
        });
        this._maxLeverageForSize = (size) => __awaiter(this, void 0, void 0, function* () {
            const skewScale = yield this._getSetting('skewScale');
            const liqPremMultiplier = yield this._getSetting('liquidationPremiumMultiplier');
            const liqBufferRatio = yield this._getSetting('liquidationBufferRatio');
            const liqBuffer = wei(0.5);
            const liqBufferRatioWei = wei(liqBufferRatio);
            const liqPremMultiplierWei = wei(liqPremMultiplier);
            const skewScaleWei = wei(skewScale);
            return liqBuffer
                .div(wei(size).abs().div(skewScaleWei).mul(liqPremMultiplierWei).add(liqBufferRatioWei))
                .toBN();
        });
        this._batchGetSettings = () => __awaiter(this, void 0, void 0, function* () {
            if (!this._perpsV2MarketSettings)
                throw new Error('Market settings not initialized');
            const settings = (yield this._sdk.context.multicallProvider.all([
                this._perpsV2MarketSettings.minInitialMargin(),
                this._perpsV2MarketSettings.takerFeeDelayedOrder(this._marketKeyBytes),
                this._perpsV2MarketSettings.makerFeeDelayedOrder(this._marketKeyBytes),
                this._perpsV2MarketSettings.maxLeverage(this._marketKeyBytes),
                this._perpsV2MarketSettings.maxMarketValue(this._marketKeyBytes),
                this._perpsV2MarketSettings.skewScale(this._marketKeyBytes),
                this._perpsV2MarketSettings.liquidationPremiumMultiplier(this._marketKeyBytes),
                this._perpsV2MarketSettings.maxFundingVelocity(this._marketKeyBytes),
                this._perpsV2MarketSettings.liquidationBufferRatio(this._marketKeyBytes),
                this._perpsV2MarketSettings.liquidationFeeRatio(),
                this._perpsV2MarketSettings.maxKeeperFee(),
                this._perpsV2MarketSettings.minKeeperFee(),
                this._perpsV2MarketSettings.keeperLiquidationFee(),
            ]));
            this._marketSettings = {
                minInitialMargin: settings[0],
                takerFeeDelayedOrder: settings[1],
                makerFeeDelayedOrder: settings[2],
                maxLeverage: settings[3],
                maxMarketValue: settings[4],
                skewScale: settings[5],
                liquidationPremiumMultiplier: settings[6],
                maxFundingVelocity: settings[7],
                liquidationBufferRatio: settings[8],
                liquidationFeeRatio: settings[9],
                maxKeeperFee: settings[10],
                minKeeperFee: settings[11],
                keeperLiquidationFee: settings[12],
            };
            return this._marketSettings;
        });
        this._getSetting = (settingType) => __awaiter(this, void 0, void 0, function* () {
            if (this._marketSettings)
                return this._marketSettings[settingType];
            const settings = yield this._batchGetSettings();
            return settings[settingType];
        });
        this._sdk = sdk;
        this._provider = provider;
        this._perpsV2MarketContract = PerpsV2Market__factory.connect(marketAddress, provider);
        this._perpsV2MarketSettings = sdk.context.multicallContracts.PerpsV2MarketSettings;
        this._marketKeyBytes = formatBytes32String(marketKey);
        this._cache = {};
        this._block = null;
        this._marketAddress = marketAddress;
        this._onChainData = {
            assetPrice: BigNumber.from(0),
            marketSkew: BigNumber.from(0),
            marketSize: BigNumber.from(0),
            fundingSequenceLength: BigNumber.from(0),
            fundingLastRecomputed: 0,
            fundingRateLastRecomputed: 0,
            accruedFunding: BigNumber.from(0),
        };
    }
    _sameSide(a, b) {
        return a.gte(ZERO_BIG_NUM) === b.gte(ZERO_BIG_NUM);
    }
}
const fetchBlockWithRetry = (blockNum, provider, count = 0) => __awaiter(void 0, void 0, void 0, function* () {
    // Sometimes the block number is returned before the block
    // is ready to fetch and so getBlock returns null
    const block = yield (provider === null || provider === void 0 ? void 0 : provider.getBlock(blockNum));
    if (block)
        return block;
    if (count > 5)
        return null;
    yield new Promise((resolve) => setTimeout(resolve, 200));
    return fetchBlockWithRetry(blockNum, provider, count + 1);
});
export default FuturesMarketInternal;
