import lastBlockTopcHandler from "./lastBlockTopicHandler";
import lastMempoolDataTopicHandler from "./lastMempoolDataTopicHandler";
import mempoolPricePercentileTopcHandler from "./mempoolPricePercentileTopcHandler";

export type TEventType = "last-block-stat" | "last-mempool-data" | "mempool-price-percentile"; // allowed event types

interface WSMessage {
    "type": TEventType,
    [key:string]: any,
}

const TOPIC_HANDLERS: { [key in TEventType]: { messageHandler: (data: any) => void, reset?: () => void}} = {
    "last-block-stat": lastBlockTopcHandler,
    "last-mempool-data": lastMempoolDataTopicHandler,
    "mempool-price-percentile": mempoolPricePercentileTopcHandler,
}

export const handleWebsocketMessages = (messages: string[]) => {
    messages.forEach((message) => {
    try {
        const json:WSMessage = JSON.parse(message);
        TOPIC_HANDLERS[json.type] && TOPIC_HANDLERS[json.type].messageHandler(json)
    } catch (ignore) {
    }
  })
}

export const resetWebsocketStatus = () => {
    (Object.keys(TOPIC_HANDLERS) as TEventType[]).forEach((key) => {
        TOPIC_HANDLERS[key]?.reset?.();
    })
}