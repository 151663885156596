import { capitalize, isObject, reduce } from "lodash";
export const truncateAddress = (address, first = 5, last = 5) => `${address.slice(0, first)}...${address.slice(-last, address.length)}`;
export const truncateAddressWith0x = (address, first = 5, last = 5) => {
    if (!address)
        return '';
    return address.startsWith('0x') ? truncateAddress(address, first, last) : `0x${truncateAddress(address, first, last)}`;
};
export const addressWith0x = (address) => (address.startsWith('0x') ? address : `0x${address}`);
export const truncateString = (text, max = 256) => {
    if ((text === null || text === void 0 ? void 0 : text.length) > max)
        return text.substring(0, max) + ' ...';
    return text;
};
export const strPadLeft = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
};
const objStringify = (obj) => {
    if (!isObject(obj))
        return obj;
    const str = reduce(obj, (acc, value, key) => {
        if (!isObject(value))
            return `${acc}${key}: $value,`;
        return `${acc}${key}: { ${objStringify(value)} },`;
    }, '');
    return str;
};
export const buildQueryString = (params) => {
    var _a;
    const query = `query 
		${capitalize(params.queryField)}($${params.queryValue}: ${(_a = params.queryValueType) !== null && _a !== void 0 ? _a : "[String!]"}) { 
			${params.queryField}(${params.first ? `first:${params.first},` : ''}where: { ${objStringify(params.where).replaceAll('$value', `$${params.queryValue}`)}}) {
				${params.selectionSet.join(' ')} } }`;
    return query;
};
