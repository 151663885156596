import Wei, { wei } from '@synthetixio/wei';
import { utils } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { DEFAULT_CRYPTO_DECIMALS, DEFAULT_FIAT_DECIMALS, DEFAULT_NUMBER_DECIMALS, UNIT_BIG_NUM, ZERO_WEI, } from '../constants/number';
import { isFiatCurrency } from './exchange';
const thresholds = [
    { value: 1e12, divisor: 1e12, unit: 'T', decimals: 2 },
    { value: 1e9, divisor: 1e9, unit: 'B', decimals: 2 },
    { value: 1e6, divisor: 1e6, unit: 'M', decimals: 2 },
    { value: 1e3, divisor: 1e3, unit: 'K', decimals: 2 },
];
export const SHORT_CRYPTO_CURRENCY_DECIMALS = 4;
export const LONG_CRYPTO_CURRENCY_DECIMALS = 8;
export const getDecimalPlaces = (value) => (value.toString().split('.')[1] || '').length;
export const truncateNumbers = (value, maxDecimalDigits) => {
    if (value.toString().includes('.')) {
        const parts = value.toString().split('.');
        return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
    }
    return value.toString();
};
/**
 * ethers utils.commify method will reduce the decimals of a number to one digit if those decimals are zero.
 * This helper is used to reverse this behavior in order to display the specified decimals in the output.
 *
 * ex: utils.commify('10000', 2) => '10,000.0'
 * ex: commifyAndPadDecimals('10000', 2)) => '10,000.00'
 * @param value - commified value from utils.commify
 * @param decimals - number of decimals to display on commified value.
 * @returns string
 */
export const commifyAndPadDecimals = (value, decimals) => {
    let formatted = utils.commify(value);
    const comps = formatted.split('.');
    if (!decimals)
        return comps[0];
    if (comps.length === 2 && comps[1].length !== decimals) {
        const zeros = '0'.repeat(decimals - comps[1].length);
        const decimalSuffix = `${comps[1]}${zeros}`;
        formatted = `${comps[0]}.${decimalSuffix}`;
    }
    return formatted;
};
// TODO: implement max decimals
export const formatNumber = (value, options) => {
    var _a, _b;
    const prefix = options === null || options === void 0 ? void 0 : options.prefix;
    const suffix = options === null || options === void 0 ? void 0 : options.suffix;
    const truncateThreshold = (_a = options === null || options === void 0 ? void 0 : options.truncateOver) !== null && _a !== void 0 ? _a : 0;
    const suggestDecimals = options === null || options === void 0 ? void 0 : options.suggestDecimals;
    let truncation = options === null || options === void 0 ? void 0 : options.truncation;
    let weiValue = wei(0);
    try {
        weiValue = wei(value);
    }
    catch (e) {
        // eslint-disable-next-line
        console.error(e);
    }
    const isNegative = weiValue.lt(wei(0));
    const formattedValue = [];
    if (isNegative) {
        formattedValue.push('-');
    }
    if (prefix) {
        formattedValue.push(prefix);
    }
    // specified truncation params overrides universal truncate
    truncation =
        truncateThreshold && !truncation
            ? thresholds.find((threshold) => weiValue.gte(threshold.value) && weiValue.gte(truncateThreshold))
            : truncation;
    const weiBeforeAsString = truncation ? weiValue.abs().div(truncation.divisor) : weiValue.abs();
    const decimals = truncation
        ? truncation.decimals
        : suggestDecimals
            ? suggestedDecimals(weiBeforeAsString)
            : (_b = options === null || options === void 0 ? void 0 : options.minDecimals) !== null && _b !== void 0 ? _b : DEFAULT_NUMBER_DECIMALS;
    let weiAsStringWithDecimals = weiBeforeAsString.toString(decimals);
    if ((options === null || options === void 0 ? void 0 : options.maxDecimals) || (options === null || options === void 0 ? void 0 : options.maxDecimals) === 0) {
        weiAsStringWithDecimals = wei(weiAsStringWithDecimals).toString(options.maxDecimals);
    }
    const withCommas = commifyAndPadDecimals(weiAsStringWithDecimals, decimals);
    formattedValue.push(withCommas);
    if (truncation) {
        formattedValue.push(truncation.unit);
    }
    if (suffix) {
        formattedValue.push(` ${suffix}`);
    }
    return formattedValue.join('');
};
export const formatCryptoCurrency = (value, options) => {
    var _a;
    return formatNumber(value, {
        prefix: options === null || options === void 0 ? void 0 : options.sign,
        suffix: options === null || options === void 0 ? void 0 : options.currencyKey,
        minDecimals: (_a = options === null || options === void 0 ? void 0 : options.minDecimals) !== null && _a !== void 0 ? _a : DEFAULT_CRYPTO_DECIMALS,
        maxDecimals: options === null || options === void 0 ? void 0 : options.maxDecimals,
        suggestDecimals: options === null || options === void 0 ? void 0 : options.suggestDecimals,
    });
};
export const formatFiatCurrency = (value, options) => {
    var _a;
    return formatNumber(value, Object.assign(Object.assign({}, options), { prefix: options === null || options === void 0 ? void 0 : options.sign, suffix: options === null || options === void 0 ? void 0 : options.currencyKey, minDecimals: (_a = options === null || options === void 0 ? void 0 : options.minDecimals) !== null && _a !== void 0 ? _a : DEFAULT_FIAT_DECIMALS }));
};
export const formatCurrency = (currencyKey, value, options) => isFiatCurrency(currencyKey)
    ? formatFiatCurrency(value, options)
    : formatCryptoCurrency(value, options);
export const formatDollars = (value, options) => formatCurrency('sUSD', value, Object.assign({ sign: '$' }, options));
export const formatsETH = (value, options) => formatCurrency('sETH', value, Object.assign({ currencyKey: 'sETH', minDecimals: DEFAULT_CRYPTO_DECIMALS, maxDecimals: DEFAULT_CRYPTO_DECIMALS }, options));
export const formatOpInt = (value, options) => formatCurrency('sETH', value, Object.assign({ currencyKey: 'sETH', minDecimals: DEFAULT_CRYPTO_DECIMALS, maxDecimals: DEFAULT_CRYPTO_DECIMALS }, options));
export const formatGas = (value, options) => formatNumber(value, Object.assign(Object.assign({}, options), { suffix: 'GAS', truncateOver: 1e3 }));
export const formatPriceGwei = (value, options) => formatCryptoCurrency(value, Object.assign({ currencyKey: 'Gwei', minDecimals: 2, maxDecimals: 2 }, options));
export const formatPercent = (value, options) => {
    var _a;
    const decimals = (_a = options === null || options === void 0 ? void 0 : options.minDecimals) !== null && _a !== void 0 ? _a : 2;
    return `${wei(value).mul(100).toString(decimals)}%`;
};
export function scale(input, decimalPlaces) {
    return input.mul(wei(10).pow(decimalPlaces));
}
export const formatGwei = (wei) => wei / 1e8 / 10;
export const formatNumberWithComma = (value) => formatNumber(value, { minDecimals: 0, maxDecimals: 0 });
export const divideDecimal = (x, y) => {
    return x.mul(UNIT_BIG_NUM).div(y);
};
export const multiplyDecimal = (x, y) => {
    return x.mul(y).div(UNIT_BIG_NUM);
};
export const weiFromWei = (weiAmount) => {
    if (weiAmount instanceof Wei) {
        const precisionDiff = 18 - weiAmount.p;
        return wei(weiAmount, 18, true).div(Math.pow(10, precisionDiff));
    }
    else {
        return wei(weiAmount, 18, true);
    }
};
export const suggestedDecimals = (value) => {
    value = wei(value).abs().toNumber();
    if (value >= 100000)
        return 0;
    if (value >= 100 || value === 0)
        return 2;
    if (value >= 10)
        return 3;
    if (value >= 0.1)
        return 4;
    if (value >= 0.01)
        return 5;
    if (value >= 0.001)
        return 6;
    if (value >= 0.0001)
        return 7;
    return 8;
};
export const floorNumber = (num, decimals) => {
    const precision = Math.pow(10, (decimals !== null && decimals !== void 0 ? decimals : suggestedDecimals(num)));
    return Math.floor(Number(num) * precision) / precision;
};
export const ceilNumber = (num, decimals) => {
    const precision = Math.pow(10, (decimals !== null && decimals !== void 0 ? decimals : suggestedDecimals(num)));
    return Math.ceil(Number(num) * precision) / precision;
};
// Converts to string but strips trailing zeros
export const weiToString = (weiVal) => {
    return String(parseFloat(weiVal.toString()));
};
export const isZero = (num) => {
    return wei(num || 0).eq(0);
};
export const weiFromEth = (num) => wei(num).toBN().toString();
export const gweiToWei = (val) => {
    return parseUnits(wei(val).toString(), 9).toString();
};
export const toWei = (value, p) => {
    return !!value ? wei(value, p) : ZERO_WEI;
};
export const stripZeros = (value) => {
    if (!value)
        return '';
    return String(value).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
};
