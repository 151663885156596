import { ADDRESSES as APPTEST_ADDRESSES } from "./constants_apptest";
import { ADDRESSES as APP_ADDRESSES } from "./constants_app";
import { merge } from 'lodash';
const DEFAULT_ADDRESSES = {
    Exchanger: {
        1: '0xD64D83829D92B5bdA881f6f61A4e4E27Fc185387',
        5: '0x889d8a97f43809Ef3FBb002B4b7a6A65319B61eD',
        10: '0xC37c47C55d894443493c1e2E615f4F9f4b8fDEa4',
        420: '0x601A1Cf1a34d9cF0020dCCD361c155Fe54CE24fB',
        31338: '0xD28F3246f047Efd4059B24FA1fa587eD9fa3e77F',
        270: '0x2E71f98f285e99C13d4716E55d20a4f0A5C3A5d0',
        280: '0x7f844a5748161Db6025D8888423d5558E6CC420D',
    },
    SystemStatus: {
        1: '0x696c905F8F8c006cA46e9808fE7e00049507798F',
        5: '0x31541f35F6Bd061f4A894fB7eEE565f81EE50df3',
        10: '0xE8c41bE1A167314ABAF2423b72Bf8da826943FFD',
        420: '0x9D89fF8C6f3CC22F4BbB859D0F85FB3a4e1FA916',
        31338: '0x8b9d5A75328b5F3167b04B42AD00092E7d6c485c',
        270: '0x9e25820E1f5682478235C45fC2c607E8aA9DBF4E',
        280: '0x71110Dd71B1E6FB84ff06642d052948896f463Bc',
    },
    ExchangeRates: {
        1: '0xb4dc5ced63C2918c89E491D19BF1C0e92845de7C',
        5: '0xea765947303051507033202CAB7D3f5d4961CF5d',
        10: '0x0cA3985f973f044978d2381AFEd9c4D85a762d11',
        420: '0x061B75475035c20ef2e35E1002Beb90C3c1f24cC',
        31338: '0x9BcA065E19b6d630032b53A8757fB093CbEAfC1d',
        270: '0xC00515D76a50D523550C1DA1727cf9Eab8f560F2',
        280: '0x9EE3B04d8b206005e52e4aAE29213cF3FdDCFC8a',
    },
    SynthUtil: {
        1: '0x81Aee4EA48f678E172640fB5813cf7A96AFaF6C3',
        5: '0x492395BA6866EF703DA49667fF92Cb8551e7a2D1',
        10: '0x87b1481c82913301Fc6c884Ac266a7c430F92cFAx',
        420: '0xC647DecC9c4f9162dBF77E4367199F5ED0950355',
        31338: '0x8C08821f5f94b519c853486eB131667AA528A460',
        270: '0x7B38A778a9c8A09bc177D491baB8cCC978Fe5375',
        280: '0x4EBb54a58b7Ed2D0711139E653FB6D74f536D2EB',
    },
    SystemSettings: {
        1: '0x5ad055A1F8C936FB0deb7024f1539Bb3eAA8dc3E',
        5: '0xA1B0898C54124E06aEAa823dC46ad0C306Ca6CD5',
        10: '0x05E1b1Dff853B1D67828Aa5E8CB37cC25aA050eE',
        420: '0xD2cECA6DD62243aB2d342Eb04882c86a10b35274',
        31338: '0xFD296cCDB97C605bfdE514e9810eA05f421DEBc2',
        270: '0xe498215f123c94C2a52105E47378623bEF2f2e15',
        280: '0x0C4C56c78274577d037623Cd71C9B60f32B4476B',
    },
    SynthRedeemer: {
        1: '0xe533139Af961c9747356D947838c98451015e234',
        5: '0x32A0BAA5Acec418a85Fd032f0292893B8E4f743B',
        10: '0xA997BD647AEe62Ef03b41e6fBFAdaB43d8E57535',
        420: '0x2A8338199D802620B4516a557195a498595d7Eb6',
        31338: '0x0D92d35D311E54aB8EEA0394d7E773Fc5144491a',
        270: '0xa1A00eA444ee61e9047b39F329eb7a0FCE73C554',
        280: '0x17a31BF390528e538b768e190119958b154DfA36',
    },
    FuturesMarketData: {
        10: '0xC51aeDBEC3aCD26650a7E85B6909E8AEc4d0F19e',
        420: '0x3FAe35Cfea950Fada314589213BABC54A084d5Bf',
        31338: '0x90E75f390332356426B60FB440DF23f860F6A113',
        270: '0x41064A133A0155f3ea3f38AC4a796553C0AC64ED',
        280: '0x15b5646710Ae655B5Fe89ACB4c5881e4A411D718',
    },
    FuturesMarketSettings: {
        10: '0xaE55F163337A2A46733AA66dA9F35299f9A46e9e',
        420: '0x0A40Ecde17dc16c4001Bf0E4f5D5Ff1818219b3b',
        31338: '0x59c7D03d2E9893FB7bAa89dA50a9452e1e9B8b90',
        270: '0x4073f1f3312ad7B0c3479aCbA9aFbf45588a418b',
        280: '0x6e5ACc950886771f9bd7a6Da6c1424Cc295f9FcC',
    },
    PerpsV2MarketData: {
        10: '0x340B5d664834113735730Ad4aFb3760219Ad9112',
        420: '0xcE2dC389fc8Be231beECED1D900881e38596d7b2',
        31338: '0xf93b0549cD50c849D792f0eAE94A598fA77C7718',
        270: '0x41064A133A0155f3ea3f38AC4a796553C0AC64ED',
        280: '0x15b5646710Ae655B5Fe89ACB4c5881e4A411D718',
    },
    PerpsV2MarketSettings: {
        10: '0x649F44CAC3276557D03223Dbf6395Af65b11c11c',
        420: '0xedf10514EF611e3808622f24e236b83cB9E51dCe',
        31338: '0x8CeA85eC7f3D314c4d144e34F2206C8Ac0bbadA1',
        270: '0x4073f1f3312ad7B0c3479aCbA9aFbf45588a418b',
        280: '0x6e5ACc950886771f9bd7a6Da6c1424Cc295f9FcC',
    },
    Pyth: {
        10: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
        420: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
        31338: '0xff1a0f4744e8582DF1aE09D5611b887B6a12925C',
        270: '0x6F922c9a5A86ceC231fEbb335515A11370d3a738',
        280: '0x6F922c9a5A86ceC231fEbb335515A11370d3a738',
    },
    SUSD: {
        1: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
        10: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
        420: '0xebaeaad9236615542844adc5c149f86c36ad1136',
        31338: '0xEC7cb8C3EBE77BA6d284F13296bb1372A8522c5F',
        270: '0xf34A86cB5cA64f41df28781c21aCC30C9fAcf739',
        280: '0x52D8f62cc28522540Ed7Bb61A1352c346d228B0f',
    },
    Synthetix: {
        1: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
        5: '0x51f44ca59b867E005e48FA573Cb8df83FC7f7597',
        10: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
        420: '0x2E5ED97596a8368EB9E44B1f3F25B2E813845303',
        31338: '0xCdb63c58b907e76872474A0597C5252eDC97c883',
        270: '0xc4dcc15DFE5e0Af629Ee593ec4FE92a41434AC23',
        280: '0x6904dc274BAED4f4db644f2DFb95b43035029Ac0',
    },
    SynthSwap: {
        10: '0x6d6273f52b0C8eaB388141393c1e8cfDB3311De6',
    },
    SmartMarginAccount: {
        10: '0x0CA2ca9FFf09f575A178F4Bb3c0e7744a1069982',
        420: '0xf9CC1eD5EC9b8DFb9273fdDABD07C7bB37938285',
        31338: '0x94a4d8C45FBaC4cCDD0afAebD0C006d97cfA8b6c',
        270: '0xF0eCd62504B3B2c0BD77145ab3Bb916F09664A9E',
        280: '0x501b5Ee6420029733ff3e18a063535DeDE39faDE',
    },
    SmartMarginAccountFactory: {
        10: '0x8234F990b149Ae59416dc260305E565e5DAfEb54',
        420: '0x30582eeE34719fe22b1B6c3b607636A3ab94522E',
        31338: '0x488b54Cf1b3F65Fa0cf76889ccb78afD2a054f4E',
        270: '0xa6EEf2EC36F04f18ecA9394cE248Cea5b64571e1',
        280: '0x410151885f629E31C2A4325Df7a38077953b5584',
    },
    SmartMarginEvents: {
        10: '0x6BA8Eb350c72A665B841DA90f03401539e7D765F',
        420: '0xa7AE3969A128048290968b41865Eaa53B20FA69e',
        31338: '0x31C89d6188b169aDCC7f6002d9cBAB605B67fd6d',
        270: '0xc5230172E1b89cB7D5Fd06c31cd412C640d02264',
        280: '0xcEBcCB9619D563E6cdCca7a40fba565325f78977',
    },
    KwentaArrakisVault: {
        10: '0x56dEa47c40877c2aaC2a689aC56aa56cAE4938d2',
    },
    StakingRewards: {
        10: '0x6077987e8e06c062094c33177Eb12c4A65f90B65',
    },
    KwentaToken: {
        10: '0x920Cf626a271321C151D027030D5d08aF699456b',
        420: '0xDA0C33402Fc1e10d18c532F0Ed9c1A6c5C9e386C',
    },
    KwentaStakingRewards: {
        10: '0x6e56A5D49F775BA08041e28030bc7826b13489e0',
        420: '0x1653a3a3c4ccee0538685f1600a30df5e3ee830a',
    },
    RewardEscrow: {
        10: '0x1066A8eB3d90Af0Ad3F89839b974658577e75BE2',
        420: '0xaFD87d1a62260bD5714C55a1BB4057bDc8dFA413',
    },
    SupplySchedule: {
        10: '0x3e8b82326Ff5f2f10da8CEa117bD44343ccb9c26',
        420: '0x671423b2e8a99882fd14bbd07e90ae8b64a0e63a',
    },
    vKwentaToken: {
        10: '0x6789D8a7a7871923Fc6430432A602879eCB6520a',
        420: '0xb897D76bC9F7efB66Fb94970371ef17998c296b6',
    },
    veKwentaToken: {
        10: '0x678d8f4ba8dfe6bad51796351824dcceceaeff2b',
        420: '0x3e52b5f840eafd79394c6359e93bf3ffdae89ee4',
    },
    vKwentaRedeemer: {
        10: '0x8132EE584bCD6f8Eb1bea141DB7a7AC1E72917b9',
        420: '0x03c3E61D624F279243e1c8b43eD0fCF6790D10E9',
    },
    veKwentaRedeemer: {
        10: '0xc7088AC8F287539567e458C7D08C2a1470Fd25B7',
        420: '0x86ca3CEbEA60101292EEFCd5802fD6e55D647c87',
    },
    TradingRewards: {
        10: '0xf486A72E8c8143ACd9F65A104A16990fDb38be14',
        420: '0x74c0A3bD10634759DC8B4CA7078C8Bf85bFE1271',
    },
    TradingRewardsPerpsV2: {
        10: '0x2787CC20e5ECb4BF1bfB79eAE284201027683179',
    },
    OpRewards: {
        10: '0x1964cF9d0c5c268DcF5a5d37F13600483237f6F5',
    },
    SnxOpRewards: {
        10: '0xD301BCC5D3229A0a6400F1030c382A5d503931E1',
    },
    BatchClaimer: {
        10: '0x6Fd879830D9b1EE5d4f9ef12f8D5deE916bebD0b',
    },
    DappMaintenance: {
        10: '0x54581A23F62D147AC76d454f0b3eF77F9D766058',
        420: '0x4259a2004A1E110A86564ff1441c37F1461F344F',
    },
};
const Environment = process.env.NEXT_PUBLIC_ENVIRONMENT || 'apptest';
export const ADDRESSES = merge({}, DEFAULT_ADDRESSES, Environment === 'apptest' ? APPTEST_ADDRESSES : APP_ADDRESSES);
console.log('DF - PROCESS.ENV', ADDRESSES);
