import { queryENSNames, queryAddressesByENSName, truncateAddress, truncateAddressWith0x } from '@kwenta/sdk/utils'
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { PERIOD_IN_SECONDS, TIME_RANGE_OPTIONS as DEFAULT_TIME_RANGE_OPTIONS, TimeRangeDurationOption } from 'constants/dashboard'
import { isAddress } from 'ethers/lib/utils'
import _, { forEach, reduce, sortBy } from 'lodash'
import { FeaturedProtocolProps, TopProtocolInfoProps } from 'sections/market/types'
import { AppThunk } from 'state/store'
import { ThunkConfig } from 'state/types'
import { BlockBuilderStatByBlockResponseProps, ProtocolItem, ValidatorStatByBlockResponseProps } from "./types"
import { camelCaseToDashed } from 'utils/formatters/string'
import {
    BlockBuilderStatProps,
    ContractLeaderboardItem,
    ContractTransactionDetails,
    CumulativeGasSpendChartData,
    ENSProps,
    FunctionPercentileDailyItem,
    FunctionWithPercentile,
    GasSpentTableData,
    GasVolatilityProps, 
    OverallWithPercentile,
    ProtocolBreakdown,
    ProtocolInfo,
    RawBlockBuilderStatProps,
    RawValidatorStatProps,
    ValidatorStatProps,
    ValidatorGraphDataProps,
    ValidatorResponseProps,
    BlockBuilderGraphDataProps,
    BlockBuilderResponseProps,
    GroupAddressProps,
    GroupAddressResponseProps,
    GroupProps
} from './types'
import { ContractTransactionsData, HistoricalTransactionBucketItem, UserInfo, CumulativeGasSpendChartData as DashboardCumulativeGasSpendChartData} from 'state/dashboard/types'
import { fetchMarketData } from 'state/dashboard/actions'
import { generateQueryText } from 'utils/queries'

export const normalizeAddressForApi = (addr?:string)=>addr?.replaceAll(/^0x/g,'').toLocaleLowerCase() ?? undefined;
const TIME_RANGE_OPTIONS = Object.values(DEFAULT_TIME_RANGE_OPTIONS);

type ContractTransactionsResponse = {
    total_gas_spent_eth:     number;
    total_gas_spent_usd:     number;
    total_gas_used:          number;
    avg_gas_spent_eth:       number;
    avg_gas_spent_usd:       number;
    avg_gas_price:           number;
    total_txn_no:            number;
    eth_perc_on_gas:         number;
    total_transaction_value: number;
    perc_transaction_value:  number | null;
    function_percentile:     FunctionWithPercentile[],
    overall_percentile:      OverallWithPercentile[],
    txn_details:             ContractTransactionDetails[],
    overspend_gwei:          number
    overspend_usd:           number
}

type RequestObject = {
    query_bucket?: string,
    query_interval?: string,
    query_interval_length?: string,
    contract_addr?: string
    contract_addrs?: string
    graph_interval?: string
    query_duration?: string
    item_limit?: number // general item count cutoff for any list
}

type GroupRequestObject = {
    query_bucket: string,
    query_duration: string,
    wallet_addrs?: string
}

type TransactionDetails = {
    gas_used: number,
    avg_gas_price: number,
    gas_spent_eth: number,
    gas_spent_usd: number,
    gas_price_sum: number
    txn_count: number,
    to_date: string,
    from_date: string
}

type TransactionsResponse = {
    total_gas_spent_eth: number,
    total_gas_spent_usd: number,
    avg_gas_spent_eth: number,
    avg_gas_spent_usd: number,
    avg_gas_price: number,
    total_txn_no: number,
    total_gas_used: number,
    eth_perc_on_gas: number,
    txn_details: TransactionDetails[]
}

export const fetchProtocolData = (timeRange: number, address: `0x${string}`): AppThunk => (
    dispatch,
	getState
) => {
    if (timeRange === Number.POSITIVE_INFINITY) {
        timeRange = PERIOD_IN_SECONDS.ONE_YEAR
    }

    dispatch(fetchContractTransactionsData({ timeRange, address }))
    dispatch(fetchCumulativeGasSpendData({ timeRange, address }))
}

export const fetchContractTransactionsData = createAsyncThunk<
    {
        walletAddress: string,
        protocolInfo: ProtocolInfo | null,
        dailyTransactionDetails: CumulativeGasSpendChartData[],
        functionPercentile: FunctionWithPercentile[],
        overallPercentile: OverallWithPercentile[],
        functionPercentileDaily: FunctionPercentileDailyItem[],
    },
    {
        timeRange: number,
        address: string
    },
    ThunkConfig
>('explorer/fetchContractTransactionsData', async ({timeRange, address}) => {
    // const timeRangeDays = timeRange / (60 * 60 * 24)
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === timeRange)

    // let requestObject: RequestObject = {} as RequestObject
    
    if (!timeRange) return {
        walletAddress: address,
        protocolInfo: null,
        dailyTransactionDetails: [],
        functionPercentile: [],
        overallPercentile: [],
        functionPercentileDaily: [],
    }
    let requestObject: any = {
        "graph-interval": 'day',
        "query-duration": timeRangeObj?.requestValue ?? '1w',
        "contract-addr": normalizeAddressForApi(address),
        limit: 100
    }

    const response = await axios.get<ContractTransactionsResponse>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-contract-txns?${generateQueryText(requestObject)}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const data = response.data

    const protocolInfo = {
        avgGasSpentEth: data.avg_gas_spent_eth,
        avgGasSpentUsd: data.avg_gas_spent_usd,
        totalGasSpentEth: data.total_gas_spent_eth,
        totalGasSpentUsd: data.total_gas_spent_usd,
        transactionCount: data.total_txn_no,
        totalGasUsed: data.total_gas_used,
        percentageOnGas: data.eth_perc_on_gas,
        avgGasPrice: data.avg_gas_price,
        totalTransactionValue: data.total_transaction_value,
        percentageTransactionValue: data.perc_transaction_value ?? 0,
        overspendGwei: data.overspend_gwei,
        overspendUsd: data.overspend_usd
    }

    const functionPercentile = _.sortBy(data.function_percentile,'bucket_gas_used').reverse().slice(0,requestObject.item_limit ?? 50);
    const overallPercentile = data.overall_percentile;

    const functionPercentileDaily = new Array<FunctionPercentileDailyItem>();

    const txnDetails = data?.txn_details?.length > 0 ? data.txn_details : [];
    const dailyTransactionDetails = sortBy(txnDetails,"timestamp").map(dailyData => {
        functionPercentileDaily.push({
            timestamp: new Date(dailyData.from_date).valueOf(),
            functionPercentileMap: functionPercentile.reduce((map,{text_signature:key})=>{
                if(!key) return map;
                if(!dailyData[key]?.percentile) return map;
                return { ...map, [key]: dailyData[key].percentile };
            },{})
        });
        return {
            timestamp: new Date(dailyData.from_date).valueOf(),
            gasSpendEth: dailyData.gas_spent_eth,
            gasSpendUsd: dailyData.gas_spent_usd,
            transactionCount: dailyData.txn_count,
            gasUsed: dailyData.gas_used,
            avgGasPrice: dailyData.avg_gas_price,
            maxGasPrice: dailyData.max_gas_price,
            ninetyFiveMaxGasPrice: dailyData.ninety_five_max_gas_price,
            seventyFiveGasPrice: dailyData.seventy_five_gas_price,
            medianGasPrice: dailyData.median_gas_price,
            twentyFiveGasPrice: dailyData.twenty_five_gas_price,
            fiveMinGasPrice: dailyData.five_min_gas_price,
            minGasPrice: dailyData.min_gas_price,
        }
    })

    return {
        walletAddress: address,
        protocolInfo,
        dailyTransactionDetails: dailyTransactionDetails ?? [],
        functionPercentile: functionPercentile ?? [],
        overallPercentile: overallPercentile ?? [],
        functionPercentileDaily,
    }
});

export const fetchCumulativeGasSpendData = createAsyncThunk<
    {
        walletAddress: string,
        gasSpentTable: GasSpentTableData,
        protocolBreakdown: ProtocolBreakdown,
        topProtocolNames: string[],
    },
    {
        timeRange: number,
        address: string,
        limit?: number,
    },
    ThunkConfig
>('explorer/fetchCumulativeGasSpendData', async ({timeRange, address, limit=100}) => {
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === timeRange)
    const requestObject = {
       "query-duration": timeRangeObj?.requestValue ?? '1w',
        "contract-addr": normalizeAddressForApi(address),
        limit: 100
    }

    try {
        const response = await axios.get<any>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-gas-spend-table?${generateQueryText(requestObject)}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let gasSpentTable = {}

        if (response.data.gas_spend_table) {
            gasSpentTable = reduce(response.data.gas_spend_table, (acc, value, key) => {
                const name = isAddress(`${key}`) ? truncateAddress(key) : key
                return {
                    ...acc,
                    [name]: value
                }
            }, {})
        }
        let protocolBreakdown = {}
        let topProtocolNames = []
        if (response.data.daily_graph) {
            const dailyGraph = response.data.daily_graph
            const protocolMaping = reduce(dailyGraph, (acc: { [key: string]: number}, value: { [key: string]: number}, key: string) => {
                const dayData = {...value}
                const totalGasUnit = dayData.total_gas_units
                delete dayData.total_gas_units
                forEach(dayData, (percentage, protocol) => {
                    const gasUnit = percentage * totalGasUnit
                    if (acc[protocol]) {
                        acc[protocol] += gasUnit
                    } else {
                        acc[protocol] = gasUnit
                    }
                })
                return acc
            }, {})
            const list: any = []
            Object.keys(protocolMaping).forEach(key => {
                list.push({ name: key, value: protocolMaping[key] })
            })
            list.sort((a: any, b: any) => b.value - a.value)
            const topProtocols = list.slice(0, 5)
            topProtocolNames = topProtocols.map((prot: any) => prot.name)

            protocolBreakdown = reduce(dailyGraph, (acc: { [key: string]: any}, value: { [key: string]: number}, key: string) => {
                let topSum = 0
                const timestamp = new Date(key).valueOf()
                acc[timestamp] = {}
                topProtocols.forEach((prot: any) => {
                    if (value[prot.name]) {
                        topSum += value[prot.name]
                        acc[timestamp][prot.name] = value[prot.name]
                    }
                })
                acc[timestamp]["Others"] = 1 - topSum
                return acc
            }, {})
        }

        return {
            walletAddress: address,
            protocolBreakdown,
            topProtocolNames,
            gasSpentTable
        }
    } catch (err) {
        console.log('Fetching table data unsuccessful', err)
        return {
            walletAddress: address,
            gasSpentTable: {},
            protocolBreakdown: {},
            topProtocolNames: [],
        }
    } 
});

export const fetchFeaturedProtocolList = createAsyncThunk<
    {
        featuredProtocolList: ProtocolItem[],
    }
>('explorer/fetchFeaturedProtocolList', async()=>{
	try {
		const response = await axios.get<FeaturedProtocolProps[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-list-of-featured-protocol`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		const dupMap = response.data.reduce((m,p)=>(m.set(p.protocol,(m.get(p.protocol)??0)+1),m),new Map());
		const featuredProtocolList = response.data.map(p=>{
			// const dup = dupMap.get(p.protocol) > 1;
			const label = p.protocol ? `${p.protocol} - ${p.name}` : truncateAddressWith0x(p.address, 10, 9);
			const slug = p.protocol ? camelCaseToDashed(`${p.protocol}-${p.name}`) : null;
            const isProtocol = p.is_protocol ?? false;
            const isTokenContract = p.is_token_contract ?? false;
			return { ...p, address: `0x${p.address}`, label, slug, isProtocol, isTokenContract } as ProtocolItem;
		});
        return { featuredProtocolList };
	} catch (err) {
		return { featuredProtocolList: [] };
	}
})

export const fetchProtocolLeaderboard = createAsyncThunk<
    {
        contractLeaderboard: ContractLeaderboardItem[],
    },
    {
        timeRange: number,
        address: `0x${string}`
    },
    ThunkConfig
>('explorer/fetchProtocolLeaderboard', async({ timeRange, address}, { getState, dispatch })=>{
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === timeRange)
    const requestObject = {
        query_duration: timeRangeObj?.requestValue ?? '1w',
        contract_addr: address ?? '267be1C1D684F78cb4F6a176C4911b741E4Ffdc0'
    }
	try {
		const response = await axios.get<ContractLeaderboardItem[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-contract-leaderboard?contract-addr=${normalizeAddressForApi(requestObject.contract_addr)}&query-duration=${requestObject.query_duration}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		const contractLeaderboard = response.data;
        return { contractLeaderboard };
	} catch (err) {
		return { contractLeaderboard: [] };
	}
});

export const fetchContractGroupLeaderboard = createAsyncThunk<
    {
        contractGroupLeaderboard: ContractLeaderboardItem[],
    },
    {
        timeRange: number,
        addresses: `0x${string}`[]
    },
    ThunkConfig
>('explorer/fetchContractGroupLeaderboard', async({ timeRange, addresses}, { getState, dispatch })=>{
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === timeRange)
    const requestObject = {
        query_duration: timeRangeObj?.requestValue ?? '1w',
        contract_addrs: addresses.map(addr=>normalizeAddressForApi(addr)).join(','),
    }
	try {
		const response = await axios.get<ContractLeaderboardItem[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-bucket-contract-leaderboard?contract-addrs=${requestObject.contract_addrs}&query-duration=${requestObject.query_duration}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
		const contractGroupLeaderboard = response.data;
        return { contractGroupLeaderboard };
	} catch (err) {
		return { contractGroupLeaderboard: [] };
	}
});

export const fetchGasVolatility = createAsyncThunk<
    { gasVolatility: GasVolatilityProps }
>('explorer/fetchGasVolatility', async()=>{
	try {
		const response = await axios.get<GasVolatilityProps>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/txns/get-gas-volatility?query-duration=1m`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

        const gasVolatility = response.data;
        return { gasVolatility };
		
	} catch (err) {
		return {
            gasVolatility: {
                gasVol: 0,
                lastVwap: 0
            }
         };
	}
})

export const fetchENSNames = createAsyncThunk<
    {
        ensEntries: ENSProps[],
    },
    {
        addresses: `0x${string}`[]
    },
    ThunkConfig
>('explorer/fetchENSNames', async({ addresses }, { getState })=>{
    const {
        explorer: { ensNames },
    } = getState()

    const addressesToFetch = addresses.filter((address) => !ensNames[address.toLowerCase()]);
    try {
        if (addressesToFetch.length > 0) {
            const ensEntries = await queryENSNames(addressesToFetch.map(a => a.toLocaleLowerCase().trim()))
            return { ensEntries };
        }
        return { ensEntries: []}
    } catch (err) {
        return { ensEntries: [] };
    }
});

export const fetchAddressByENSName = createAsyncThunk<
    {
        ensEntries: ENSProps[],
    },
    {
        search: string,
    },
    ThunkConfig
>('explorer/fetchAddressByENSName', async({ search }, { getState })=>{
    const {
        explorer: { ensNames },
    } = getState()
    if( ensNames[search] ){
        // return cache
        return { ensEntries: [ ensNames[search] ] };
    }
    try {
        const ensEntries = await queryAddressesByENSName(search)
        return { ensEntries };
    } catch (err) {
        return { ensEntries: [] };
    }
});

export const fetchValidatorStats = createAsyncThunk<
    { 
        validatorsList: { 
            graph: ValidatorGraphDataProps,
            stat: {
                size: number,
                data: { [key: string] : ValidatorStatProps } }
            topValidators: string[]
        }},
    {
        duration: TimeRangeDurationOption
    }
>('explorer/fetchValidatorStats', async({ duration })=>{
	try {
        // 15 minutes - call block level stat API instead
        const api = duration==='15min' ? 
            `${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/txns/get-validator-stat-by-block?num-of-block=75`:
            `${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/txns/get-validator-stat?query-duration=${duration}`;
		const response = await axios.get< ValidatorResponseProps | ValidatorStatByBlockResponseProps >(
			api,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

        if (!response?.data?.graph || !response?.data?.stat) {
            return {
                validatorsList: {
                    graph: {},
                    stat: {
                        size: 0,
                        data: {}
                    },
                    topValidators: []
                }
            }
        }

        const validatorsList: { [key: string]: ValidatorStatProps } = reduce(response.data.stat.data, (acc, value, key) => {
            return {
                ...acc,
                [key]: {
                    name: value?.name ?? '',
                    address: value.address,
                    blockOnMktPerc: value.block_on_mkt_perc ?? 0,
                    percOfPubTxns: value.perc_of_pub_txns,
                    percOfPrvTxns: value.perc_of_prv_txns,
                    txnCount: value.txn_count,
                    blockCount: value.block_count ?? 0,
                    feeUsd: value.fee_usd,
                    feeEth: value.fee_eth
                }
            }
        }, {});

        const validatorGraph: ValidatorGraphDataProps = Array.isArray(response.data.graph) ? response.data.graph.reduce((ret,item)=>{
            return {...ret, [item.block_number]:{
                "total-blocks": 1,
                [item.proposer_address]: 1,
            }}
        },{}) : response.data.graph;

        const topValidators = Object.values(validatorsList).sort((a, b) => b.blockOnMktPerc - a.blockOnMktPerc).slice(0, 10);
        return { 
            validatorsList: {
                graph: validatorGraph,
                stat: {
                    size: response.data.stat.size,
                    data: validatorsList
                },
                topValidators: topValidators.map(t => t.address)
            }
         };
		
	} catch (err) {
		return {
            validatorsList: {
                graph: {},
                stat: {
                    size: 0,
                    data: {}
                },
                topValidators: []
            }
         };
	}
})

export const fetchBlockBuilderStats = createAsyncThunk<
    { 
        blockBuilderList: {
            graph: BlockBuilderGraphDataProps,
            stat: {
                size: number,
                data: { [key: string] : BlockBuilderStatProps }
            }
            topBlockBuilders: string[]
        },
    },
    {
        duration: TimeRangeDurationOption
    }
>('explorer/fetchBlockBuilderStats', async({ duration })=>{
	try {
        // 15 minutes - call block level stat API instead
        const api = duration==='15min' ? 
            `${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/txns/get-builder-stat-by-block?num-of-block=75`:
            `${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/txns/get-builder-stat?query-duration=${duration}`;

        const response = await axios.get<BlockBuilderResponseProps | BlockBuilderStatByBlockResponseProps>(
            api,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response?.data?.graph || !response?.data?.stat) {
            return {
                blockBuilderList: {
                    graph: {},
                    stat: {
                        size: 0,
                        data: {}
                    },
                    topBlockBuilders: []
                }
            }
        }

        const blockBuilderList: { [key: string]: BlockBuilderStatProps } = reduce(response.data.stat.data, (acc, value, key) => {
            return {
                ...acc,
                [key]: {
                    name: value?.name,
                    address: value.address,
                    blockOnMktPerc: value.block_on_mkt_perc ?? 0,
                    percOfPubTxns: value.perc_of_pub_txns,
                    percOfPrvTxns: value.perc_of_prv_txns,
                    txnCount: value.txn_count,
                    blockCount: value.block_count ?? 0,
                    totalFeeUsd: value.total_fee_usd ?? 0,
                    totalFeeEth: value.total_fee_eth ?? 0,
                    netFeeUsd: value.net_fee_usd ?? 0,
                    netFeeEth: value.net_fee_eth ?? 0,
                }
            }
        }, {});

        const blockBuilderGraph: BlockBuilderGraphDataProps = Array.isArray(response.data.graph) ? response.data.graph.reduce((ret,item)=>{
            return {...ret, [item.block_number]:{
                "total-blocks": 1,
                [item.builder_address]: 1,
            }}
        },{}) : response.data.graph;

        const topBlockBuilders = Object.values(blockBuilderList).sort((a, b) => b.blockOnMktPerc - a.blockOnMktPerc).slice(0, 10);

        return { blockBuilderList: {
            graph: blockBuilderGraph,
            stat: {
                size: response.data.stat.size,
                data: blockBuilderList
            },
            topBlockBuilders: topBlockBuilders.map(t => t.address)
        } };
		
	} catch (err) {
		return {
            blockBuilderList: {
                graph: {},
                stat: {
                    size: 0,
                    data: {}
                },
                topBlockBuilders: []
            }
         };
	}
})
export const fetchSenderGroupAddresses = createAsyncThunk<
    { 
        groupAddressList: GroupAddressProps[],
        groupName: string | null
    },
    {
        groupName: string
    }
>('explorer/fetchSenderGroupAddresses', async({ groupName })=>{
	try {
		const response = await axios.get<GroupAddressResponseProps[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/wallet/get-wallet-by-group?wallet-group=${groupName}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

        if (!response?.data ) {
            return { groupAddressList: [], groupName: null }
        }

        const groupAddressList = response?.data.map((value) => ({
            group: value.group,
            address: value.address,
            name: value.name,
            type: value.type,
            debankName: value.debank_name,
            debankIconUrl: value.debank_icon_url
        }))


        return { groupAddressList, groupName };
		
	} catch (err) {
		return {
            groupAddressList: [],
            groupName: null
         };
	}
})
export const fetchSenderGroups = createAsyncThunk<
    { 
       groups: GroupProps[] | null
    }
>('explorer/fetchSenderGroups', async()=>{
	try {
		const response = await axios.get<GroupProps[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/wallet/get-wallet-group`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

        if (!response?.data ) {
            return { groups: [] }
        }

        const groups = response?.data


        return { groups };
		
	} catch (err) {
		return {
            groups: []
         };
	}
})

export const fetchCumulativeGasSpendDataForGroup = createAsyncThunk<
    {
        addresses: string[],
        walletHash: string,
        groupInfo: UserInfo | null,
        dailyTransactionDetails: DashboardCumulativeGasSpendChartData[],
    },
    {
        selectedDashboardTimeRange: number,
        addresses: string[],
        walletHash: string
    },
    ThunkConfig
>('dashboard/fetchCumulativeGasSpendDataForGroup', async ({ selectedDashboardTimeRange, addresses, walletHash }) => {
    try {
        let requestObject: GroupRequestObject = {} as GroupRequestObject
        const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === selectedDashboardTimeRange)
        
        if (selectedDashboardTimeRange === 0) return {
            groupInfo: null,
            dailyTransactionDetails: [],
            addresses,
            walletHash
        }
        requestObject = {
            query_bucket: 'day',
            query_duration: timeRangeObj?.requestValue ?? '1w',
            wallet_addrs: addresses.join(',')
        }
        const response = await axios.get<TransactionsResponse>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/wallet/get-bucket-users-txns?graph-interval=${requestObject.query_bucket}&query-duration=${requestObject.query_duration}&wallet-addrs=${requestObject.wallet_addrs}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        const data: TransactionsResponse = response.data
        const requestData = data?.txn_details?.length > 0 ? data.txn_details : []

        const groupInfo = {
            avgGasSpentEth: data.avg_gas_spent_eth,
            avgGasSpentUsd: data.avg_gas_spent_usd,
            totalGasSpentEth: data.total_gas_spent_eth,
            totalGasSpentUsd: data.total_gas_spent_usd,
            transactionCount: data.total_txn_no,
            totalGasUsed: data.total_gas_used,
            percentageOnGas: data.eth_perc_on_gas,
            avgGasPrice: data.avg_gas_price / 1e9
        }

        const dailyTransactionDetails = requestData.map(dailyData => {
            return {
                timestamp: dailyData.from_date,
                gasSpendEth: dailyData.gas_spent_eth,
                gasSpendUsd: dailyData.gas_spent_usd,
                transactionCount: dailyData.txn_count,
                gasUsed: dailyData.gas_used,
                avgGasPrice: dailyData.avg_gas_price / 1e9
            }
        })

        return {
            addresses,
            walletHash,
            groupInfo,
            dailyTransactionDetails: dailyTransactionDetails ?? []
        }
    } catch (err) {
        console.log('Fetching table data unsuccessful', err)
        return {
            groupInfo: null,
            dailyTransactionDetails: [],
            addresses,
            walletHash
        }
    }
})

export const fetchCustomGroupDashboardData = (selectedDashboardTimeRange: number, addresses: string[], walletHash: string): AppThunk => (
    dispatch,
	getState
) => {
    if (selectedDashboardTimeRange === Number.POSITIVE_INFINITY) {
        selectedDashboardTimeRange = PERIOD_IN_SECONDS.ONE_YEAR
    }
    dispatch(fetchMarketData({ selectedDashboardTimeRange }))
    dispatch(fetchContractTransactionsDataForGroup({ selectedDashboardTimeRange, addresses, walletHash}))
    dispatch(fetchCumulativeGasSpendDataForGroup({ selectedDashboardTimeRange, addresses, walletHash }))
    
}

export const fetchContractTransactionsDataForGroup = createAsyncThunk<
    {
        addresses: string[],
        gasSpentTable: GasSpentTableData,
        protocolBreakdown: ContractTransactionsData,
        topProtocolNames: string[],
        walletHash: string
    },
    {
        selectedDashboardTimeRange: number,
        addresses: string[],
        walletHash: string
    },
        
    ThunkConfig
>('explorer/fetchContractTransactionsDataForGroup', async ({ selectedDashboardTimeRange, addresses, walletHash}, { getState, dispatch }) => {
    // const timeRangeDays = selectedDashboardTimeRange / (60 * 60 * 24)
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === selectedDashboardTimeRange)
    const requestObject = {
        query_duration: timeRangeObj?.requestValue ?? '1w',
        wallet_addrs: addresses.join(',')
    }

    try {
        const response = await axios.get<any>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/wallet/get-bucket-gas-spend-table?query-duration=${requestObject.query_duration}&wallet-addrs=${requestObject.wallet_addrs}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let gasSpentTable = {}

        if (response.data.gas_spend_table) {
            gasSpentTable = reduce(response.data.gas_spend_table, (acc, value, key) => {
                const name = isAddress(`${key}`) ? truncateAddress(key) : key
                return {
                    ...acc,
                    [name]: value
                }
            }, {})
        }
        let protocolBreakdown = {}
        let topProtocolNames = []
        if (response.data.daily_graph) {
            const dailyGraph = response.data.daily_graph
            const protocolMaping = reduce(dailyGraph, (acc: { [key: string]: number}, value: { [key: string]: number}, key: string) => {
                const dayData = {...value}
                const totalGasUnit = dayData.total_gas_units
                delete dayData.total_gas_units
                forEach(dayData, (percentage, protocol) => {
                    const gasUnit = percentage * totalGasUnit
                    if (acc[protocol]) {
                        acc[protocol] += gasUnit
                    } else {
                        acc[protocol] = gasUnit
                    }
                })
                return acc
            }, {})
            const list: any = []
            Object.keys(protocolMaping).forEach(key => {
                list.push({ name: key, value: protocolMaping[key] })
            })
            list.sort((a: any, b: any) => b.value - a.value)
            const topProtocols = list.slice(0, 5)
            topProtocolNames = topProtocols.map((prot: any) => prot.name)

            protocolBreakdown = reduce(dailyGraph, (acc: { [key: string]: any}, value: { [key: string]: number}, key: string) => {
                let topSum = 0
                // const timestamp = new Date(key).valueOf()
                acc[key] = {}
                topProtocols.forEach((prot: any) => {
                    if (value[prot.name]) {
                        topSum += value[prot.name]
                        acc[key][prot.name] = value[prot.name]
                    }
                })
                acc[key]["Others"] = 1 - topSum
                return acc
            }, {})
        }

        return {
            addresses,
            walletHash,
            protocolBreakdown,
            topProtocolNames,
            gasSpentTable
        }
    } catch (err) {
        console.log('Fetching table data unsuccessful', err)
        return {
            addresses,
            walletHash,
            gasSpentTable: {},
            protocolBreakdown: {},
            topProtocolNames: [],
        }
    }
})

export const fetchReceiverGroupAddresses = createAsyncThunk<
    { 
        groupAddressList: GroupAddressProps[],
        groupName: string | null
    },
    {
        groupName: string
    }
>('explorer/fetchReceiverGroupAddresses', async({ groupName })=>{
	try {
		const response = await axios.get<GroupAddressResponseProps[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-contract-by-group?contract-group=${groupName}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

        if (!response?.data ) {
            return { groupAddressList: [], groupName: null }
        }

        const groupAddressList = response?.data.map((value) => ({
            group: value.group,
            address: value.address,
            name: value.name,
            type: value.type,
            debankName: value.debank_name,
            debankIconUrl: value.debank_icon_url
        }))


        return { groupAddressList, groupName };
		
	} catch (err) {
		return {
            groupAddressList: [],
            groupName: null
         };
	}
})
export const fetchReceiverGroups = createAsyncThunk<
    { 
       groups: GroupProps[] | null
    }
>('explorer/fetchReceiverGroups', async()=>{
	try {
		const response = await axios.get<GroupProps[]>(
			`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-contract-group`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

        if (!response?.data ) {
            return { groups: [] }
        }

        const groups = response?.data


        return { groups };
		
	} catch (err) {
		return {
            groups: []
         };
	}
})

export const fetchCustomReceiverGroupData = (timeRange: number, addresses: string[], contractHash: string): AppThunk => (
    dispatch,
	getState
) => {
    if (timeRange === Number.POSITIVE_INFINITY) {
        timeRange = PERIOD_IN_SECONDS.ONE_YEAR
    }
    dispatch(fetchReceiverGroupContractTransactionsData({ timeRange, addresses, contractHash }))
    dispatch(fetchReceiverGroupCumulativeGasSpendData({ timeRange, addresses, contractHash }))
}

export const fetchReceiverGroupContractTransactionsData = createAsyncThunk<
    {
        walletAddresses: string[],
        contractHash: string,
        contractInfo: ProtocolInfo | null,
        dailyTransactionDetails: CumulativeGasSpendChartData[],
        functionPercentile: FunctionWithPercentile[],
        overallPercentile: OverallWithPercentile[],
        functionPercentileDaily: FunctionPercentileDailyItem[],
    },
    {
        timeRange: number,
        addresses: string[],
        contractHash: string
    },
    ThunkConfig
>('explorer/fetchReceiverGroupContractTransactionsData', async ({timeRange, addresses, contractHash}) => {
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === timeRange)

    let requestObject: RequestObject = {} as RequestObject
    
    if (!timeRangeObj) return {
        walletAddresses: addresses,
        contractHash,
        contractInfo: null,
        dailyTransactionDetails: [],
        functionPercentile: [],
        overallPercentile: [],
        functionPercentileDaily: [],
    }
    requestObject = {
        graph_interval: 'day',
        query_duration: timeRangeObj?.requestValue ?? '1w',
        contract_addrs: addresses.join(',')
    }
    const response = await axios.get<ContractTransactionsResponse>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-bucket-contracts-txns?graph-interval=${requestObject.graph_interval}&query-duration=${requestObject.query_duration}&contract-addrs=${requestObject.contract_addrs}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const data = response.data

    // console.log('fetchReceiverGroupContractTransactionsData 1', data)

    const contractInfo = {
        avgGasSpentEth: data.avg_gas_spent_eth,
        avgGasSpentUsd: data.avg_gas_spent_usd,
        totalGasSpentEth: data.total_gas_spent_eth,
        totalGasSpentUsd: data.total_gas_spent_usd,
        transactionCount: data.total_txn_no,
        totalGasUsed: data.total_gas_used,
        percentageOnGas: data.eth_perc_on_gas,
        avgGasPrice: data.avg_gas_price,
        totalTransactionValue: data.total_transaction_value,
        percentageTransactionValue: data.perc_transaction_value ?? 0,
        overspendGwei: data.overspend_gwei,
        overspendUsd: data.overspend_usd
    }

    const functionPercentile = _.sortBy(data.function_percentile,'bucket_gas_used').reverse().slice(0,requestObject.item_limit ?? 50);
    const overallPercentile = data.overall_percentile;

    const functionPercentileDaily = new Array<FunctionPercentileDailyItem>();

    const txnDetails = data?.txn_details?.length > 0 ? data.txn_details : [];
    const dailyTransactionDetails = sortBy(txnDetails,"timestamp").map(dailyData => {
        functionPercentileDaily.push({
            timestamp: new Date(dailyData.from_date).valueOf(),
            functionPercentileMap: functionPercentile.reduce((map,{text_signature:key})=>{
                if(!key) return map;
                if(!dailyData[key]?.percentile) return map;
                return { ...map, [key]: dailyData[key].percentile };
            },{})
        });
        return {
            timestamp: new Date(dailyData.from_date).valueOf(),
            gasSpendEth: dailyData.gas_spent_eth,
            gasSpendUsd: dailyData.gas_spent_usd,
            transactionCount: dailyData.txn_count,
            gasUsed: dailyData.gas_used,
            avgGasPrice: dailyData.avg_gas_price,
            maxGasPrice: dailyData.max_gas_price,
            ninetyFiveMaxGasPrice: dailyData.ninety_five_max_gas_price,
            seventyFiveGasPrice: dailyData.seventy_five_gas_price,
            medianGasPrice: dailyData.median_gas_price,
            twentyFiveGasPrice: dailyData.twenty_five_gas_price,
            fiveMinGasPrice: dailyData.five_min_gas_price,
            minGasPrice: dailyData.min_gas_price,
        }
    })

    return {
        walletAddresses: addresses,
        contractHash,
        contractInfo,
        dailyTransactionDetails: dailyTransactionDetails ?? [],
        functionPercentile: functionPercentile ?? [],
        overallPercentile: overallPercentile ?? [],
        functionPercentileDaily,
    }
});

export const fetchReceiverGroupCumulativeGasSpendData = createAsyncThunk<
    {
        walletAddresses: string[],
        contractHash: string,
        gasSpentTable: GasSpentTableData,
        protocolBreakdown: ProtocolBreakdown,
        topProtocolNames: string[],
    },
    {
        timeRange: number,
        addresses: string[],
        contractHash: string,
        limit?: number,
    },
    ThunkConfig
>('explorer/fetchReceiverGroupCumulativeGasSpendData', async ({timeRange, addresses, contractHash, limit=100}) => {
    const timeRangeObj = TIME_RANGE_OPTIONS.find(option => option.value === timeRange)
    const requestObject = {
        query_duration: timeRangeObj?.requestValue ?? '1w',
        contract_addrs: addresses.join(',')
    }

    try {
        const response = await axios.get<any>(`${process.env.NEXT_PUBLIC_ETHGAS_ANALYTICS_BASE_API_URL}/contract/get-bucket-gas-spend-table?query-duration=${requestObject.query_duration}&contract-addrs=${requestObject.contract_addrs}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        let gasSpentTable = {}

        if (response.data.gas_spend_table) {
            gasSpentTable = reduce(response.data.gas_spend_table, (acc, value, key) => {
                const name = isAddress(`${key}`) ? truncateAddress(key) : key
                return {
                    ...acc,
                    [name]: value
                }
            }, {})
        }
        let protocolBreakdown = {}
        let topProtocolNames = []
        if (response.data.daily_graph) {
            const dailyGraph = response.data.daily_graph
            const protocolMaping = reduce(dailyGraph, (acc: { [key: string]: number}, value: { [key: string]: number}, key: string) => {
                const dayData = {...value}
                const totalGasUnit = dayData.total_gas_units
                delete dayData.total_gas_units
                forEach(dayData, (percentage, protocol) => {
                    const gasUnit = percentage * totalGasUnit
                    if (acc[protocol]) {
                        acc[protocol] += gasUnit
                    } else {
                        acc[protocol] = gasUnit
                    }
                })
                return acc
            }, {})
            const list: any = []
            Object.keys(protocolMaping).forEach(key => {
                list.push({ name: key, value: protocolMaping[key] })
            })
            list.sort((a: any, b: any) => b.value - a.value)
            const topProtocols = list.slice(0, 5)
            topProtocolNames = topProtocols.map((prot: any) => prot.name)

            protocolBreakdown = reduce(dailyGraph, (acc: { [key: string]: any}, value: { [key: string]: number}, key: string) => {
                let topSum = 0
                const timestamp = new Date(key).valueOf()
                acc[timestamp] = {}
                topProtocols.forEach((prot: any) => {
                    if (value[prot.name]) {
                        topSum += value[prot.name]
                        acc[timestamp][prot.name] = value[prot.name]
                    }
                })
                acc[timestamp]["Others"] = 1 - topSum
                return acc
            }, {})
        }

        return {
            walletAddresses: addresses,
            contractHash,
            protocolBreakdown,
            topProtocolNames,
            gasSpentTable
        }
    } catch (err) {
        console.log('Fetching table data unsuccessful', err)
        return {
            walletAddresses: addresses,
            contractHash,
            gasSpentTable: {},
            protocolBreakdown: {},
            topProtocolNames: [],
        }
    } 
});


