var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wei } from '@synthetixio/wei';
import axios from 'codegen-graph-ts/build/src/lib/axios';
import generateGql from 'codegen-graph-ts/build/src/lib/gql';
const MAX_PAGE = 1000;
export const getCandleById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('candle', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['synth'])
            formattedObj['synth'] = obj['synth'];
        if (obj['open'])
            formattedObj['open'] = wei(obj['open']);
        if (obj['high'])
            formattedObj['high'] = wei(obj['high']);
        if (obj['low'])
            formattedObj['low'] = wei(obj['low']);
        if (obj['close'])
            formattedObj['close'] = wei(obj['close']);
        if (obj['average'])
            formattedObj['average'] = wei(obj['average']);
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['period'])
            formattedObj['period'] = wei(obj['period'], 0);
        if (obj['aggregatedPrices'])
            formattedObj['aggregatedPrices'] = wei(obj['aggregatedPrices'], 0);
        return formattedObj;
    });
};
export const getCandles = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('candles', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['synth'])
                    formattedObj['synth'] = obj['synth'];
                if (obj['open'])
                    formattedObj['open'] = wei(obj['open']);
                if (obj['high'])
                    formattedObj['high'] = wei(obj['high']);
                if (obj['low'])
                    formattedObj['low'] = wei(obj['low']);
                if (obj['close'])
                    formattedObj['close'] = wei(obj['close']);
                if (obj['average'])
                    formattedObj['average'] = wei(obj['average']);
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['period'])
                    formattedObj['period'] = wei(obj['period'], 0);
                if (obj['aggregatedPrices'])
                    formattedObj['aggregatedPrices'] = wei(obj['aggregatedPrices'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getCrossMarginAccountById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('crossMarginAccount', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['owner'])
            formattedObj['owner'] = obj['owner'];
        return formattedObj;
    });
};
export const getCrossMarginAccounts = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('crossMarginAccounts', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['owner'])
                    formattedObj['owner'] = obj['owner'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getCrossMarginAccountTransferById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('crossMarginAccountTransfer', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['abstractAccount'])
            formattedObj['abstractAccount'] = obj['abstractAccount'];
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['size'])
            formattedObj['size'] = wei(obj['size'], 0);
        if (obj['txHash'])
            formattedObj['txHash'] = obj['txHash'];
        return formattedObj;
    });
};
export const getCrossMarginAccountTransfers = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc'
                    ? '_gt'
                    : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('crossMarginAccountTransfers', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['abstractAccount'])
                    formattedObj['abstractAccount'] = obj['abstractAccount'];
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['size'])
                    formattedObj['size'] = wei(obj['size'], 0);
                if (obj['txHash'])
                    formattedObj['txHash'] = obj['txHash'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFundingPaymentById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('fundingPayment', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['positionId'])
            formattedObj['positionId'] = obj['positionId'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['amount'])
            formattedObj['amount'] = wei(obj['amount'], 0);
        return formattedObj;
    });
};
export const getFundingPayments = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('fundingPayments', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['positionId'])
                    formattedObj['positionId'] = obj['positionId'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['amount'])
                    formattedObj['amount'] = wei(obj['amount'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFundingRateUpdateById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('fundingRateUpdate', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['market'])
            formattedObj['market'] = obj['market'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['sequenceLength'])
            formattedObj['sequenceLength'] = wei(obj['sequenceLength'], 0);
        if (obj['funding'])
            formattedObj['funding'] = wei(obj['funding'], 0);
        if (obj['fundingRate'])
            formattedObj['fundingRate'] = wei(obj['fundingRate'], 0);
        return formattedObj;
    });
};
export const getFundingRateUpdates = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('fundingRateUpdates', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['market'])
                    formattedObj['market'] = obj['market'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['sequenceLength'])
                    formattedObj['sequenceLength'] = wei(obj['sequenceLength'], 0);
                if (obj['funding'])
                    formattedObj['funding'] = wei(obj['funding'], 0);
                if (obj['fundingRate'])
                    formattedObj['fundingRate'] = wei(obj['fundingRate'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesAggregateStatById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresAggregateStat', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['period'])
            formattedObj['period'] = wei(obj['period'], 0);
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['trades'])
            formattedObj['trades'] = wei(obj['trades'], 0);
        if (obj['volume'])
            formattedObj['volume'] = wei(obj['volume'], 0);
        if (obj['feesKwenta'])
            formattedObj['feesKwenta'] = wei(obj['feesKwenta'], 0);
        if (obj['feesSynthetix'])
            formattedObj['feesSynthetix'] = wei(obj['feesSynthetix'], 0);
        if (obj['feesCrossMarginAccounts'])
            formattedObj['feesCrossMarginAccounts'] = wei(obj['feesCrossMarginAccounts'], 0);
        return formattedObj;
    });
};
export const getFuturesAggregateStats = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc'
                    ? '_gt'
                    : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresAggregateStats', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['period'])
                    formattedObj['period'] = wei(obj['period'], 0);
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['trades'])
                    formattedObj['trades'] = wei(obj['trades'], 0);
                if (obj['volume'])
                    formattedObj['volume'] = wei(obj['volume'], 0);
                if (obj['feesKwenta'])
                    formattedObj['feesKwenta'] = wei(obj['feesKwenta'], 0);
                if (obj['feesSynthetix'])
                    formattedObj['feesSynthetix'] = wei(obj['feesSynthetix'], 0);
                if (obj['feesCrossMarginAccounts'])
                    formattedObj['feesCrossMarginAccounts'] = wei(obj['feesCrossMarginAccounts'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesCumulativeStatById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresCumulativeStat', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['totalLiquidations'])
            formattedObj['totalLiquidations'] = wei(obj['totalLiquidations'], 0);
        if (obj['totalTrades'])
            formattedObj['totalTrades'] = wei(obj['totalTrades'], 0);
        if (obj['totalTraders'])
            formattedObj['totalTraders'] = wei(obj['totalTraders'], 0);
        if (obj['totalVolume'])
            formattedObj['totalVolume'] = wei(obj['totalVolume'], 0);
        if (obj['averageTradeSize'])
            formattedObj['averageTradeSize'] = wei(obj['averageTradeSize'], 0);
        return formattedObj;
    });
};
export const getFuturesCumulativeStats = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc'
                    ? '_gt'
                    : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresCumulativeStats', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['totalLiquidations'])
                    formattedObj['totalLiquidations'] = wei(obj['totalLiquidations'], 0);
                if (obj['totalTrades'])
                    formattedObj['totalTrades'] = wei(obj['totalTrades'], 0);
                if (obj['totalTraders'])
                    formattedObj['totalTraders'] = wei(obj['totalTraders'], 0);
                if (obj['totalVolume'])
                    formattedObj['totalVolume'] = wei(obj['totalVolume'], 0);
                if (obj['averageTradeSize'])
                    formattedObj['averageTradeSize'] = wei(obj['averageTradeSize'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesMarginAccountById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresMarginAccount', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['market'])
            formattedObj['market'] = obj['market'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['margin'])
            formattedObj['margin'] = wei(obj['margin'], 0);
        if (obj['deposits'])
            formattedObj['deposits'] = wei(obj['deposits'], 0);
        if (obj['withdrawals'])
            formattedObj['withdrawals'] = wei(obj['withdrawals'], 0);
        return formattedObj;
    });
};
export const getFuturesMarginAccounts = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc'
                    ? '_gt'
                    : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresMarginAccounts', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['market'])
                    formattedObj['market'] = obj['market'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['margin'])
                    formattedObj['margin'] = wei(obj['margin'], 0);
                if (obj['deposits'])
                    formattedObj['deposits'] = wei(obj['deposits'], 0);
                if (obj['withdrawals'])
                    formattedObj['withdrawals'] = wei(obj['withdrawals'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesMarginTransferById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresMarginTransfer', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['market'])
            formattedObj['market'] = obj['market'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['size'])
            formattedObj['size'] = wei(obj['size'], 0);
        if (obj['txHash'])
            formattedObj['txHash'] = obj['txHash'];
        return formattedObj;
    });
};
export const getFuturesMarginTransfers = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc'
                    ? '_gt'
                    : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresMarginTransfers', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['market'])
                    formattedObj['market'] = obj['market'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['size'])
                    formattedObj['size'] = wei(obj['size'], 0);
                if (obj['txHash'])
                    formattedObj['txHash'] = obj['txHash'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesMarketById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresMarket', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['marketStats'])
            formattedObj['marketStats'] = obj['marketStats'];
        return formattedObj;
    });
};
export const getFuturesMarkets = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresMarkets', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['marketStats'])
                    formattedObj['marketStats'] = obj['marketStats'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesOrderById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresOrder', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['size'])
            formattedObj['size'] = wei(obj['size'], 0);
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['market'])
            formattedObj['market'] = obj['market'];
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['abstractAccount'])
            formattedObj['abstractAccount'] = obj['abstractAccount'];
        if (obj['orderId'])
            formattedObj['orderId'] = wei(obj['orderId'], 0);
        if (obj['targetRoundId'])
            formattedObj['targetRoundId'] = wei(obj['targetRoundId'], 0);
        if (obj['targetPrice'])
            formattedObj['targetPrice'] = wei(obj['targetPrice'], 0);
        if (obj['marginDelta'])
            formattedObj['marginDelta'] = wei(obj['marginDelta'], 0);
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['orderType'])
            formattedObj['orderType'] = obj['orderType'];
        if (obj['status'])
            formattedObj['status'] = obj['status'];
        if (obj['keeper'])
            formattedObj['keeper'] = obj['keeper'];
        return formattedObj;
    });
};
export const getFuturesOrders = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresOrders', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['size'])
                    formattedObj['size'] = wei(obj['size'], 0);
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['market'])
                    formattedObj['market'] = obj['market'];
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['abstractAccount'])
                    formattedObj['abstractAccount'] = obj['abstractAccount'];
                if (obj['orderId'])
                    formattedObj['orderId'] = wei(obj['orderId'], 0);
                if (obj['targetRoundId'])
                    formattedObj['targetRoundId'] = wei(obj['targetRoundId'], 0);
                if (obj['targetPrice'])
                    formattedObj['targetPrice'] = wei(obj['targetPrice'], 0);
                if (obj['marginDelta'])
                    formattedObj['marginDelta'] = wei(obj['marginDelta'], 0);
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['orderType'])
                    formattedObj['orderType'] = obj['orderType'];
                if (obj['status'])
                    formattedObj['status'] = obj['status'];
                if (obj['keeper'])
                    formattedObj['keeper'] = obj['keeper'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesPositionById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresPosition', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['lastTxHash'])
            formattedObj['lastTxHash'] = obj['lastTxHash'];
        if (obj['openTimestamp'])
            formattedObj['openTimestamp'] = wei(obj['openTimestamp'], 0);
        if (obj['closeTimestamp'])
            formattedObj['closeTimestamp'] = wei(obj['closeTimestamp'], 0);
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['market'])
            formattedObj['market'] = obj['market'];
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['abstractAccount'])
            formattedObj['abstractAccount'] = obj['abstractAccount'];
        if (obj['accountType'])
            formattedObj['accountType'] = obj['accountType'];
        if (obj['isOpen'])
            formattedObj['isOpen'] = obj['isOpen'];
        if (obj['isLiquidated'])
            formattedObj['isLiquidated'] = obj['isLiquidated'];
        if (obj['trades'])
            formattedObj['trades'] = wei(obj['trades'], 0);
        if (obj['totalVolume'])
            formattedObj['totalVolume'] = wei(obj['totalVolume'], 0);
        if (obj['size'])
            formattedObj['size'] = wei(obj['size'], 0);
        if (obj['initialMargin'])
            formattedObj['initialMargin'] = wei(obj['initialMargin'], 0);
        if (obj['margin'])
            formattedObj['margin'] = wei(obj['margin'], 0);
        if (obj['pnl'])
            formattedObj['pnl'] = wei(obj['pnl'], 0);
        if (obj['feesPaid'])
            formattedObj['feesPaid'] = wei(obj['feesPaid'], 0);
        if (obj['netFunding'])
            formattedObj['netFunding'] = wei(obj['netFunding'], 0);
        if (obj['pnlWithFeesPaid'])
            formattedObj['pnlWithFeesPaid'] = wei(obj['pnlWithFeesPaid'], 0);
        if (obj['netTransfers'])
            formattedObj['netTransfers'] = wei(obj['netTransfers'], 0);
        if (obj['totalDeposits'])
            formattedObj['totalDeposits'] = wei(obj['totalDeposits'], 0);
        if (obj['fundingIndex'])
            formattedObj['fundingIndex'] = wei(obj['fundingIndex'], 0);
        if (obj['entryPrice'])
            formattedObj['entryPrice'] = wei(obj['entryPrice'], 0);
        if (obj['avgEntryPrice'])
            formattedObj['avgEntryPrice'] = wei(obj['avgEntryPrice'], 0);
        if (obj['lastPrice'])
            formattedObj['lastPrice'] = wei(obj['lastPrice'], 0);
        if (obj['exitPrice'])
            formattedObj['exitPrice'] = wei(obj['exitPrice'], 0);
        return formattedObj;
    });
};
export const getFuturesPositions = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresPositions', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['lastTxHash'])
                    formattedObj['lastTxHash'] = obj['lastTxHash'];
                if (obj['openTimestamp'])
                    formattedObj['openTimestamp'] = wei(obj['openTimestamp'], 0);
                if (obj['closeTimestamp'])
                    formattedObj['closeTimestamp'] = wei(obj['closeTimestamp'], 0);
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['market'])
                    formattedObj['market'] = obj['market'];
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['abstractAccount'])
                    formattedObj['abstractAccount'] = obj['abstractAccount'];
                if (obj['accountType'])
                    formattedObj['accountType'] = obj['accountType'];
                if (obj['isOpen'])
                    formattedObj['isOpen'] = obj['isOpen'];
                if (obj['isLiquidated'])
                    formattedObj['isLiquidated'] = obj['isLiquidated'];
                if (obj['trades'])
                    formattedObj['trades'] = wei(obj['trades'], 0);
                if (obj['totalVolume'])
                    formattedObj['totalVolume'] = wei(obj['totalVolume'], 0);
                if (obj['size'])
                    formattedObj['size'] = wei(obj['size'], 0);
                if (obj['initialMargin'])
                    formattedObj['initialMargin'] = wei(obj['initialMargin'], 0);
                if (obj['margin'])
                    formattedObj['margin'] = wei(obj['margin'], 0);
                if (obj['pnl'])
                    formattedObj['pnl'] = wei(obj['pnl'], 0);
                if (obj['feesPaid'])
                    formattedObj['feesPaid'] = wei(obj['feesPaid'], 0);
                if (obj['netFunding'])
                    formattedObj['netFunding'] = wei(obj['netFunding'], 0);
                if (obj['pnlWithFeesPaid'])
                    formattedObj['pnlWithFeesPaid'] = wei(obj['pnlWithFeesPaid'], 0);
                if (obj['netTransfers'])
                    formattedObj['netTransfers'] = wei(obj['netTransfers'], 0);
                if (obj['totalDeposits'])
                    formattedObj['totalDeposits'] = wei(obj['totalDeposits'], 0);
                if (obj['fundingIndex'])
                    formattedObj['fundingIndex'] = wei(obj['fundingIndex'], 0);
                if (obj['entryPrice'])
                    formattedObj['entryPrice'] = wei(obj['entryPrice'], 0);
                if (obj['avgEntryPrice'])
                    formattedObj['avgEntryPrice'] = wei(obj['avgEntryPrice'], 0);
                if (obj['lastPrice'])
                    formattedObj['lastPrice'] = wei(obj['lastPrice'], 0);
                if (obj['exitPrice'])
                    formattedObj['exitPrice'] = wei(obj['exitPrice'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesStatById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresStat', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['feesPaid'])
            formattedObj['feesPaid'] = wei(obj['feesPaid'], 0);
        if (obj['pnl'])
            formattedObj['pnl'] = wei(obj['pnl'], 0);
        if (obj['pnlWithFeesPaid'])
            formattedObj['pnlWithFeesPaid'] = wei(obj['pnlWithFeesPaid'], 0);
        if (obj['liquidations'])
            formattedObj['liquidations'] = wei(obj['liquidations'], 0);
        if (obj['totalTrades'])
            formattedObj['totalTrades'] = wei(obj['totalTrades'], 0);
        if (obj['totalVolume'])
            formattedObj['totalVolume'] = wei(obj['totalVolume'], 0);
        if (obj['smartMarginVolume'])
            formattedObj['smartMarginVolume'] = wei(obj['smartMarginVolume'], 0);
        return formattedObj;
    });
};
export const getFuturesStats = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresStats', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['feesPaid'])
                    formattedObj['feesPaid'] = wei(obj['feesPaid'], 0);
                if (obj['pnl'])
                    formattedObj['pnl'] = wei(obj['pnl'], 0);
                if (obj['pnlWithFeesPaid'])
                    formattedObj['pnlWithFeesPaid'] = wei(obj['pnlWithFeesPaid'], 0);
                if (obj['liquidations'])
                    formattedObj['liquidations'] = wei(obj['liquidations'], 0);
                if (obj['totalTrades'])
                    formattedObj['totalTrades'] = wei(obj['totalTrades'], 0);
                if (obj['totalVolume'])
                    formattedObj['totalVolume'] = wei(obj['totalVolume'], 0);
                if (obj['smartMarginVolume'])
                    formattedObj['smartMarginVolume'] = wei(obj['smartMarginVolume'], 0);
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getFuturesTradeById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('futuresTrade', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['timestamp'])
            formattedObj['timestamp'] = wei(obj['timestamp'], 0);
        if (obj['account'])
            formattedObj['account'] = obj['account'];
        if (obj['abstractAccount'])
            formattedObj['abstractAccount'] = obj['abstractAccount'];
        if (obj['accountType'])
            formattedObj['accountType'] = obj['accountType'];
        if (obj['margin'])
            formattedObj['margin'] = wei(obj['margin'], 0);
        if (obj['size'])
            formattedObj['size'] = wei(obj['size'], 0);
        if (obj['asset'])
            formattedObj['asset'] = obj['asset'];
        if (obj['marketKey'])
            formattedObj['marketKey'] = obj['marketKey'];
        if (obj['price'])
            formattedObj['price'] = wei(obj['price'], 0);
        if (obj['positionId'])
            formattedObj['positionId'] = obj['positionId'];
        if (obj['positionSize'])
            formattedObj['positionSize'] = wei(obj['positionSize'], 0);
        if (obj['positionClosed'])
            formattedObj['positionClosed'] = obj['positionClosed'];
        if (obj['pnl'])
            formattedObj['pnl'] = wei(obj['pnl'], 0);
        if (obj['feesPaid'])
            formattedObj['feesPaid'] = wei(obj['feesPaid'], 0);
        if (obj['fundingAccrued'])
            formattedObj['fundingAccrued'] = wei(obj['fundingAccrued'], 0);
        if (obj['keeperFeesPaid'])
            formattedObj['keeperFeesPaid'] = wei(obj['keeperFeesPaid'], 0);
        if (obj['orderType'])
            formattedObj['orderType'] = obj['orderType'];
        if (obj['trackingCode'])
            formattedObj['trackingCode'] = obj['trackingCode'];
        return formattedObj;
    });
};
export const getFuturesTrades = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('futuresTrades', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['timestamp'])
                    formattedObj['timestamp'] = wei(obj['timestamp'], 0);
                if (obj['account'])
                    formattedObj['account'] = obj['account'];
                if (obj['abstractAccount'])
                    formattedObj['abstractAccount'] = obj['abstractAccount'];
                if (obj['accountType'])
                    formattedObj['accountType'] = obj['accountType'];
                if (obj['margin'])
                    formattedObj['margin'] = wei(obj['margin'], 0);
                if (obj['size'])
                    formattedObj['size'] = wei(obj['size'], 0);
                if (obj['asset'])
                    formattedObj['asset'] = obj['asset'];
                if (obj['marketKey'])
                    formattedObj['marketKey'] = obj['marketKey'];
                if (obj['price'])
                    formattedObj['price'] = wei(obj['price'], 0);
                if (obj['positionId'])
                    formattedObj['positionId'] = obj['positionId'];
                if (obj['positionSize'])
                    formattedObj['positionSize'] = wei(obj['positionSize'], 0);
                if (obj['positionClosed'])
                    formattedObj['positionClosed'] = obj['positionClosed'];
                if (obj['pnl'])
                    formattedObj['pnl'] = wei(obj['pnl'], 0);
                if (obj['feesPaid'])
                    formattedObj['feesPaid'] = wei(obj['feesPaid'], 0);
                if (obj['fundingAccrued'])
                    formattedObj['fundingAccrued'] = wei(obj['fundingAccrued'], 0);
                if (obj['keeperFeesPaid'])
                    formattedObj['keeperFeesPaid'] = wei(obj['keeperFeesPaid'], 0);
                if (obj['orderType'])
                    formattedObj['orderType'] = obj['orderType'];
                if (obj['trackingCode'])
                    formattedObj['trackingCode'] = obj['trackingCode'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
export const getSmartMarginAccountById = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield axios.post(url, {
            query: generateGql('smartMarginAccount', options, args),
        });
        const r = res.data;
        if (r.errors && r.errors.length) {
            throw new Error(r.errors[0].message);
        }
        const obj = r.data[Object.keys(r.data)[0]];
        const formattedObj = {};
        if (obj['id'])
            formattedObj['id'] = obj['id'];
        if (obj['owner'])
            formattedObj['owner'] = obj['owner'];
        if (obj['version'])
            formattedObj['version'] = obj['version'];
        return formattedObj;
    });
};
export const getSmartMarginAccounts = function (url, options, args) {
    return __awaiter(this, void 0, void 0, function* () {
        const paginatedOptions = Object.assign({}, options);
        let paginationKey = null;
        let paginationValue = '';
        if (options.first && options.first > MAX_PAGE) {
            paginatedOptions.first = MAX_PAGE;
            paginatedOptions.orderBy = options.orderBy || 'id';
            paginatedOptions.orderDirection = options.orderDirection || 'asc';
            paginationKey = (paginatedOptions.orderBy +
                (paginatedOptions.orderDirection === 'asc' ? '_gt' : '_lt'));
            paginatedOptions.where = Object.assign({}, options.where);
        }
        let results = [];
        do {
            if (paginationKey && paginationValue)
                paginatedOptions.where[paginationKey] = paginationValue;
            const res = yield axios.post(url, {
                query: generateGql('smartMarginAccounts', paginatedOptions, args),
            });
            const r = res.data;
            if (r.errors && r.errors.length) {
                throw new Error(r.errors[0].message);
            }
            const rawResults = r.data[Object.keys(r.data)[0]];
            const newResults = rawResults.map((obj) => {
                const formattedObj = {};
                if (obj['id'])
                    formattedObj['id'] = obj['id'];
                if (obj['owner'])
                    formattedObj['owner'] = obj['owner'];
                if (obj['version'])
                    formattedObj['version'] = obj['version'];
                return formattedObj;
            });
            results = results.concat(newResults);
            if (newResults.length < 1000) {
                break;
            }
            if (paginationKey) {
                paginationValue = rawResults[rawResults.length - 1][paginatedOptions.orderBy];
            }
        } while (paginationKey && options.first && results.length < options.first);
        return options.first ? results.slice(0, options.first) : results;
    });
};
