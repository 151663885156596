import { createAsyncThunk } from '@reduxjs/toolkit'

import type { ThunkConfig } from 'state/store'
import { serializeBalances } from 'utils/balances'

import { ZERO_BALANCES } from './reducer'
import { BalancesActionReturn } from './types'
import Connector from 'containers/Connector'
import { wei } from '@synthetixio/wei'
import { notifyError } from 'components/ErrorNotifier'


export const fetchBalances = createAsyncThunk<BalancesActionReturn<string>, void, ThunkConfig>(
	'balances/fetchBalances',
	async (_, { getState, extra: { sdk } }) => {
		try {
			console.log("David => fetch balances")
			const { wallet } = getState()
			if (!wallet.walletAddress) return ZERO_BALANCES
			const [{ balancesMap, totalUSDBalance, susdWalletBalance }, tokenBalances] = await Promise.all([
				sdk.synths.getSynthBalances(wallet.walletAddress),
				sdk.exchange.getTokenBalances(wallet.walletAddress),
			])

			return serializeBalances(balancesMap, totalUSDBalance, tokenBalances, susdWalletBalance)
		} catch (err) {
			notifyError('Failed to fetch balances', err)
			throw err
		}
	}
)

export const fetchEthBalances = createAsyncThunk<string, any, ThunkConfig>(
	'balances/fetchEthBalances',
	async ({ provider, walletAddress}, { getState, extra: { sdk } }) => {
		const { wallet } = getState()
		if (!wallet.walletAddress && !walletAddress) return "0.0000"
		try {
			const bal = await provider.getBalance(wallet.walletAddress || walletAddress)
			return wei(bal).toString()
		} catch (err) {
			return "0.0000"
		}
	}
)
