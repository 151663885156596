import { createSlice } from "@reduxjs/toolkit";
import { MarketState } from "./types";

export const MARKET_INITIAL_STATE: MarketState = {
    showMarketBanner: true
}

const marketSlice = createSlice({
    name: 'market',
	initialState: MARKET_INITIAL_STATE,
	reducers: {
		setShowMarketBanner: (state, action) => {
            state.showMarketBanner = action.payload
        }
	},
})

export const {
    setShowMarketBanner
} = marketSlice.actions

export default marketSlice.reducer