import { cyanDark, blueDark, orange, blue, slateDark, tealDark, cyanDarkA, tomatoDark, amberDark, grassDark, yellowDark, redDark, orangeDark } from '@radix-ui/colors';

export const palette = {
	yellow: {
		y50: '#fff8e6',
		y100: '#ff39b0',
		y200: '#ffde8a',
		y300: '#ffcf54',
		y400: '#ffc633',
		y500: '#ffb800',
		y600: '#e8a700',
		y700: '#b58300',
		y800: '#8c6500',
		y900: '#6b4d00',
		y1000: '#3e2d00',
	},
	green: {
		g50: '#f2fbf3',
		g100: '#d7f2d8',
		g200: '#c4ebc6',
		g300: '#a9e2ab',
		g400: '#99dd9b',
		g500: '#7fd482',
		g600: '#74c176',
		g700: '#5a975c',
		g800: '#467548',
		g900: '#355937',
	},
	red: {
		r50: '#fde6e6',
		r100: '#f9b0b0',
		r200: '#f68a8a',
		r300: '#f25454',
		r400: '#ef3333',
		r500: '#eb0000',
		r600: '#d60000',
		r700: '#a70000',
		r800: '#810000',
		r900: '#630000',
	},
	orange: {
		o50: '#fff0e8',
		o100: '#ffd1b7',
		o200: '#ffbb95',
		o300: '#ff9c64',
		o400: '#ff8946',
		o500: '#ff6b18',
		o600: '#e86116',
		o700: '#b54c11',
		o800: '#8c3b0d',
		o900: '#6b2d0a',
		o1100: orange.orange11
	},
	cyan: {
		c100: cyanDark.cyan1,
		c200: cyanDark.cyan2,
		c300: cyanDark.cyan3,
		c400: cyanDark.cyan4,
		c500: cyanDark.cyan5,
		c600: cyanDark.cyan6,
		c700: cyanDark.cyan7,
		c800: cyanDark.cyan8,
		c900: cyanDark.cyan9,
		c1000: cyanDark.cyan10,
		c1100: cyanDark.cyan11,
		c1200: cyanDark.cyan12,
	},
	cyanAlpha: {
		cA1: cyanDarkA.cyanA1,
		cA2: cyanDarkA.cyanA2,
		cA3: cyanDarkA.cyanA3,
		cA4: cyanDarkA.cyanA4,
		cA5: cyanDarkA.cyanA5,
		cA6: cyanDarkA.cyanA6,
		cA7: cyanDarkA.cyanA7,
		cA8: cyanDarkA.cyanA8,
		cA9: cyanDarkA.cyanA9,
		cA10: cyanDarkA.cyanA10,
		cA11: cyanDarkA.cyanA11,
		cA12: cyanDarkA.cyanA12,
	},
	neutral: {
		n0: '#ffffff',
		n10: '#fafafa',
		n20: '#f5f5f5',
		n30: '#ececeb',
		n40: '#e0e0df',
		n50: '#c4c3c2',
		n60: '#b5b4b3',
		n70: '#a9a8a6',
		n80: '#9b9a98',
		n90: '#8d8b89',
		n100: '#7e7d7a',
		n200: '#706e6b',
		n300: '#62605d',
		n400: '#565450',
		n500: '#484542',
		n600: '#3c3935',
		n700: '#2B2A28',
		n800: '#1d1a15',
		n900: '#14120F',
		n1000: '#110E09',
		n1100: '#0D0B08',
	},
	blueDark: {
		blue1: blueDark.blue1,
		blue2: blueDark.blue2,
		blue3: blueDark.blue3,
		blue4: blueDark.blue4,
		blue5: blueDark.blue5,
		blue6: blueDark.blue6,
		blue7: blueDark.blue7,
		blue8: blueDark.blue8,
		blue9: blueDark.blue9,
		blue10: blueDark.blue10,
		blue11: blueDark.blue11,
		blue12: blueDark.blue12,
	},
	slateDark: {
		slate1: slateDark.slate1,
		slate2: slateDark.slate2,
		slate3: slateDark.slate3,
		slate4: slateDark.slate4,
		slate5: slateDark.slate5,
		slate6: slateDark.slate6,
		slate7: slateDark.slate7,
		slate8: slateDark.slate8,
		slate9: slateDark.slate9,
		slate10: slateDark.slate10,
		slate11: slateDark.slate11,
		slate12: slateDark.slate12,
	},
	tealDark: {
		teal1: tealDark.teal1,
		teal2: tealDark.teal2,
		teal3: tealDark.teal3,
		teal4: tealDark.teal4,
		teal5: tealDark.teal5,
		teal6: tealDark.teal6,
		teal7: tealDark.teal7,
		teal8: tealDark.teal8,
		teal9: tealDark.teal9,
		teal10: tealDark.teal10,
		teal11: tealDark.teal11,
		teal12: tealDark.teal12,
	},
	tomatoDark: {
		tomato1: tomatoDark.tomato1,
		tomato2: tomatoDark.tomato2,
		tomato3: tomatoDark.tomato3,
		tomato4: tomatoDark.tomato4,
		tomato5: tomatoDark.tomato5,
		tomato6: tomatoDark.tomato6,
		tomato7: tomatoDark.tomato7,
		tomato8: tomatoDark.tomato8,
		tomato9: tomatoDark.tomato9,
		tomato10: tomatoDark.tomato10,
	
	},
	amberDark: {
		amber1: amberDark.amber1,
		amber2: amberDark.amber2,
		amber3: amberDark.amber3,
		amber4: amberDark.amber4,
		amber5: amberDark.amber5,
		amber6: amberDark.amber6,
		amber7: amberDark.amber7,
		amber8: amberDark.amber8,
		amber9: amberDark.amber9,
		amber10: amberDark.amber10,
	},
	grassDark: {
		grass1: grassDark.grass1,
		grass2: grassDark.grass2,
		grass3: grassDark.grass3,
		grass4: grassDark.grass4,
		grass5: grassDark.grass5,
		grass6: grassDark.grass6,
		grass7: grassDark.grass7,
		grass8: grassDark.grass8,
		grass9: grassDark.grass9,
		grass10: grassDark.grass10
	},
	yellowDark: {
		yellow1: yellowDark.yellow1,
		yellow2: yellowDark.yellow2,
		yellow3: yellowDark.yellow3,
		yellow4: yellowDark.yellow4,
		yellow5: yellowDark.yellow5,
		yellow6: yellowDark.yellow6,
		yellow7: yellowDark.yellow7,
		yellow8: yellowDark.yellow8,
		yellow9: yellowDark.yellow9,
		yellow10: yellowDark.yellow10,
	},
	redDark: {
		red1: redDark.red1,
		red2: redDark.red2,
		red3: redDark.red3,
		red4: redDark.red4,
		red5: redDark.red5,
		red6: redDark.red6,
		red7: redDark.red7,
		red8: redDark.red8,
		red9: redDark.red9,
		red10: redDark.red10,
		red11: redDark.red11,
		red12: redDark.red12,
	},
	orangeDark: {
		orange1: orangeDark.orange1,
		orange2: orangeDark.orange2,
		orange3: orangeDark.orange3,
		orange4: orangeDark.orange4,
		orange5: orangeDark.orange5,
		orange6: orangeDark.orange6,
		orange7: orangeDark.orange7,
		orange8: orangeDark.orange8,
		orange9: orangeDark.orange9,
		orange10: orangeDark.orange10,
		orange11: orangeDark.orange11,
		orange12: orangeDark.orange12,	
	},
	gradient: {
		gold: 'linear-gradient(180deg, #BE9461 0%, #9C6C3C 100%)',
	},
	alpha: {
		white1: 'rgb(255,255,255,0.01)',
		white5: 'rgb(255,255,255,0.5)',
		white10: 'rgb(255,255,255,0.1)',
		white25: 'rgba(255, 255, 255, 0.25)',
		red10: 'rgb(241,43,43,0.1)',
		red15: 'rgb(241,43,43,0.15)',
		red5: 'rgb(241,43,43,0.05)',
		green20: 'rgba(127,212,130,0.2)',
		green10: 'rgba(127,212,130,0.1)',
		green5: 'rgba(127,212,130,0.05)',
		lightButton: 'linear-gradient(180deg, #EEEEEE 0%, #E0E0E0 100%)',
		lightButtonHover: 'linear-gradient(180deg, #E6E6E6 0%, #CCCCCC 100%)',
		darkButton: 'linear-gradient(180deg, #282727 0%, #191818 100%)',
		darkButtonHover: 'linear-gradient(180deg, #383838 0%, #1E1E1E 100%)',
	},
}

const common = {
	primaryWhite: '#ECE8E3',
	primaryGold: '#C9975B',
	primaryRed: '#EF6868',
	primaryGreen: '#7FD482',
	primaryGray: '#B1B1B1',
	secondaryGray: '#515151',
	neautralGray: '#A9A8A6',
	tertiaryGray: '#999999',
	secondaryGold: '#E4B378',
	primaryYellow: '#FFB800',
	primaryCyan: cyanDark.cyan9,
	primaryBlue: blueDark.blue3,
	black: '#171002',
	darkYellow: '#3E2D00',
	overlayAlpha: 'rgba(0,0,0,0.5)',
	dark: {
		white: '#FFFFFF',
		yellow: '#FFB800',
		cyan: cyanDark.cyan9,
		cyanA3: cyanDarkA.cyanA3,
		red: '#EF6868',
		green: '#7FD482',
		background: blueDark.blue2,
		surfaceFill: '#181818',
		title: '#B1B1B1',
		border: '1px solid rgba(255, 255, 255, 0.12)',
		border2: '1px solid rgba(255, 255, 255, 0.03)',
		cyanBorder: `1px solid ${cyanDark.cyan9}`,
		borderColor: '#D9D9D9',
		outlineBorder: '1px solid rgba(255, 255, 255, 0.12)',
		orange: '#FF6B19',
		orange2: orange.orange10,
		blue: blue.blue10,
		black: '#171002',
		gray: '#787878',
		gray2: '#3F3F3F',
		rewardTitle: '#ECE8E3',
	},
	light: {
		white: '#F2F2F2',
		yellow: '#6A3300',
		cyan: cyanDark.cyan8,
		background: '#F2F2F2',
		surfaceFill: '#F2F2F2',
		title: '#171002',
		border: '1px solid rgba(0,0,0,0.1)',
		border2: '1px solid rgba(0,0,0,0.05)',
		cyanBorder: `1px solid ${cyanDark.cyan9}`,
		borderColor: '#D9D9D9',
		outlineBorder: '1px solid rgba(0,0,0,0.1)',
		red: '#A80300',
		green: '#1D5D1F',
		orange: '#FF6B19',
		orange2: orange.orange10,
		blue: blue.blue10,
		black: '#171002',
		gray: '#515151',
		gray2: '#D2D2D2', 
		rewardTitle: '#171002',
	},
	palette,
}

export default common
