import { batch } from "@preact/signals-react";
import { LastBlockStat } from "sections/market/types";
import { blockSignal, lastBlockSignal } from "signals/lastBlockSignals";

let initialBlocks: LastBlockStat[] | null = []

const messageHandler = (data: LastBlockStat) => {
    batch(() => {
        if (initialBlocks !== null){
            if (initialBlocks.length < 15) {
            
                initialBlocks!.push(data as LastBlockStat)
                if (initialBlocks!.length === 15) {
                    blockSignal.value = initialBlocks!;
                    lastBlockSignal.value = data as LastBlockStat;
                    initialBlocks = null;
                }
            }
        } else {
            batch(() => {
                blockSignal.value = [...(blockSignal.value.slice(1)), data as LastBlockStat]
                lastBlockSignal.value = data as LastBlockStat;
            });
        }
    })
}

const reset = () => {
    initialBlocks = []
}

export default {
    messageHandler,
    reset
}