export const ADDRESSES = {
    Exchanger: {
        300: '0x2CE84118442e3d31Be086FBA713700b0544E617f',
    },
    SystemStatus: {
        300: '0x5Aa607740F274c193135B07199a4d045919d8Bc9',
    },
    ExchangeRates: {
        300: '0xC32605E444BF95d9bE78FaC3DE9B5420CCb6C0e3',
    },
    SynthUtil: {
        300: '0x8Ba5a37d7c2bFD80BC47404a3ECAAD40DbFc7eC7',
    },
    SystemSettings: {
        300: '0xd1F65C6A44C24e42f885eF4583fbA41409a5841C',
    },
    SynthRedeemer: {
        300: '0x02dC250cff75C364A6acC76c2fca7bce4C6CdF47',
    },
    FuturesMarketData: {
        300: '0xBf540C813c4a4f780f132487BF73dBA868628b0A',
    },
    FuturesMarketSettings: {
        300: '0x41C5Fe39e6c3C6CF173EDA776630F09C0E699880',
    },
    PerpsV2MarketData: {
        300: '0xBf540C813c4a4f780f132487BF73dBA868628b0A',
    },
    PerpsV2MarketSettings: {
        300: '0x41C5Fe39e6c3C6CF173EDA776630F09C0E699880',
    },
    Pyth: {
        300: '0x7f844a5748161Db6025D8888423d5558E6CC420D',
    },
    SUSD: {
        300: '0x7db5DC85E3f4D2779474275f7635077a7B0dcE07',
    },
    Synthetix: {
        300: '0x1ccBd94a1E9784314B514Cc5F1a055Bc067aD34C',
    },
    SmartMarginAccount: {
        300: '0xDBddF05C1418549D40237e9cCB549887180c71CF',
    },
    SmartMarginAccountFactory: {
        300: '0x0B09b325c24eE8c8Cf839c4cB294439faC418B76',
    },
    SmartMarginEvents: {
        300: '0x96B1d59Bb971266d49fBAA7C1995c282e69D59b9',
    }
};
