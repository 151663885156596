import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String, parseBytes32String } from '@ethersproject/strings';
import Wei, { wei } from '@synthetixio/wei';
import { defaultAbiCoder } from 'ethers/lib/utils.js';
import { APP_MAX_LEVERAGE, FUTURES_ENDPOINTS, MAINNET_MARKETS, TESTNET_MARKETS, AGGREGATE_ASSET_KEY, MAIN_ENDPOINTS, SL_TP_MAX_SIZE, KWENTA_PYTH_SERVER, PUBLIC_PYTH_SERVER, DEFAULT_PRICE_IMPACT_DELTA_PERCENT, DISPLAY_ASSET_MAP, } from '../constants/futures';
import { ZERO_WEI } from '../constants/number';
import { SECONDS_PER_DAY } from '../constants/period';
import { ETH_UNIT } from '../constants/transactions';
import { FuturesMarketAsset, FuturesMarketKey, PositionSide, PotentialTradeStatus, ConditionalOrderTypeEnum, } from '../types/futures';
import { formatCurrency, formatDollars } from '../utils/number';
export const getFuturesEndpoint = (networkId) => {
    return FUTURES_ENDPOINTS[networkId] || FUTURES_ENDPOINTS[10];
};
export const getMainEndpoint = (networkId) => {
    return MAIN_ENDPOINTS[networkId] || MAIN_ENDPOINTS[10];
};
export const calculateFundingRate = (minTimestamp, periodLength, fundingRates, assetPrice, currentFundingRate) => {
    const numUpdates = fundingRates.length;
    if (numUpdates < 2)
        return null;
    // variables to keep track
    let fundingPaid = wei(0);
    let timeTotal = 0;
    let lastTimestamp = minTimestamp;
    // iterate through funding updates
    for (let ind = 0; ind < numUpdates - 1; ind++) {
        const minFunding = fundingRates[ind];
        const maxFunding = fundingRates[ind + 1];
        const fundingStart = new Wei(minFunding.funding, 18, true);
        const fundingEnd = new Wei(maxFunding.funding, 18, true);
        const fundingDiff = fundingStart.sub(fundingEnd);
        const timeDiff = maxFunding.timestamp - Math.max(minFunding.timestamp, lastTimestamp);
        const timeMax = maxFunding.timestamp - minFunding.timestamp;
        if (timeMax > 0) {
            fundingPaid = fundingPaid.add(fundingDiff.mul(timeDiff).div(timeMax));
            timeTotal += timeDiff;
        }
        lastTimestamp = maxFunding.timestamp;
    }
    // add funding from current rate
    const timeLeft = Math.max(periodLength - timeTotal, 0);
    if (timeLeft > 0) {
        fundingPaid = fundingPaid.add(wei(currentFundingRate).mul(timeLeft).div(SECONDS_PER_DAY).mul(assetPrice));
    }
    const fundingRate = fundingPaid.div(assetPrice);
    return fundingRate;
};
export const marketsForNetwork = (networkId, logError) => {
    switch (networkId) {
        case 10:
            return MAINNET_MARKETS;
        case 420:
            return TESTNET_MARKETS;
        case 31338:
            return TESTNET_MARKETS;
        case 270:
            return TESTNET_MARKETS;
        case 280:
            return TESTNET_MARKETS;
        case 300:
            return TESTNET_MARKETS;
        default:
            logError === null || logError === void 0 ? void 0 : logError(new Error('Futures is not supported on this network.'));
            return [];
    }
};
export const getMarketName = (asset) => {
    return `${getDisplayAsset(asset)}/sUSD`;
};
export const getDisplayAsset = (asset) => {
    if (!asset)
        return null;
    if (asset === 'STETH')
        return 'stETH';
    if (DISPLAY_ASSET_MAP[asset] !== undefined)
        return DISPLAY_ASSET_MAP[asset];
    return asset[0] === 's' ? asset.slice(1) : asset;
};
export const calculateVolumes = (futuresHourlyStats) => {
    const volumes = futuresHourlyStats.reduce((acc, { marketKey, volume, trades }) => {
        var _a, _b, _c, _d;
        const cleanMarketKey = marketKey !== AGGREGATE_ASSET_KEY ? parseBytes32String(marketKey) : marketKey;
        return Object.assign(Object.assign({}, acc), { [cleanMarketKey]: {
                volume: volume.div(ETH_UNIT).add((_b = (_a = acc[cleanMarketKey]) === null || _a === void 0 ? void 0 : _a.volume) !== null && _b !== void 0 ? _b : 0),
                trades: trades.add((_d = (_c = acc[cleanMarketKey]) === null || _c === void 0 ? void 0 : _c.trades) !== null && _d !== void 0 ? _d : 0),
            } });
    }, {});
    return volumes;
};
export const mapFuturesPosition = (positionDetail, canLiquidatePosition, asset, marketKey) => {
    const { remainingMargin, accessibleMargin, position: { fundingIndex, lastPrice, size, margin }, accruedFunding, notionalValue, liquidationPrice, profitLoss, } = positionDetail;
    const initialMargin = wei(margin);
    const pnl = wei(profitLoss).add(wei(accruedFunding));
    const pnlPct = initialMargin.gt(0) ? pnl.div(wei(initialMargin)) : wei(0);
    return {
        asset,
        marketKey,
        remainingMargin: wei(remainingMargin),
        accessibleMargin: wei(accessibleMargin),
        position: wei(size).eq(ZERO_WEI)
            ? null
            : {
                canLiquidatePosition: !!canLiquidatePosition,
                side: wei(size).gt(ZERO_WEI) ? PositionSide.LONG : PositionSide.SHORT,
                notionalValue: wei(notionalValue).abs(),
                accruedFunding: wei(accruedFunding),
                initialMargin,
                profitLoss: wei(profitLoss),
                fundingIndex: Number(fundingIndex),
                lastPrice: wei(lastPrice),
                size: wei(size).abs(),
                liquidationPrice: wei(liquidationPrice),
                initialLeverage: initialMargin.gt(0)
                    ? wei(size).mul(wei(lastPrice)).div(initialMargin).abs()
                    : wei(0),
                pnl,
                pnlPct,
                marginRatio: wei(notionalValue).eq(ZERO_WEI)
                    ? ZERO_WEI
                    : wei(remainingMargin).div(wei(notionalValue).abs()),
                leverage: wei(remainingMargin).eq(ZERO_WEI)
                    ? ZERO_WEI
                    : wei(notionalValue).div(wei(remainingMargin)).abs(),
            },
    };
};
export const mapFuturesPositions = (futuresPositions) => {
    return futuresPositions.map(({ id, lastTxHash, openTimestamp, closeTimestamp, timestamp, market, asset, marketKey, account, abstractAccount, accountType, isOpen, isLiquidated, trades, totalVolume, size, initialMargin, margin, pnl, feesPaid, netFunding, pnlWithFeesPaid, netTransfers, totalDeposits, entryPrice, avgEntryPrice, exitPrice, }) => {
        const entryPriceWei = wei(entryPrice).div(ETH_UNIT);
        const feesWei = wei(feesPaid || 0).div(ETH_UNIT);
        const sizeWei = wei(size).div(ETH_UNIT);
        const marginWei = wei(margin).div(ETH_UNIT);
        return {
            id: Number(id.split('-')[1].toString()),
            transactionHash: lastTxHash,
            timestamp: timestamp.mul(1000).toNumber(),
            openTimestamp: openTimestamp.mul(1000).toNumber(),
            closeTimestamp: closeTimestamp === null || closeTimestamp === void 0 ? void 0 : closeTimestamp.mul(1000).toNumber(),
            market,
            asset: parseBytes32String(asset),
            marketKey: parseBytes32String(marketKey),
            account,
            abstractAccount,
            accountType,
            isOpen,
            isLiquidated,
            size: sizeWei.abs(),
            feesPaid: feesWei,
            netFunding: wei(netFunding || 0).div(ETH_UNIT),
            netTransfers: wei(netTransfers || 0).div(ETH_UNIT),
            totalDeposits: wei(totalDeposits || 0).div(ETH_UNIT),
            initialMargin: wei(initialMargin).div(ETH_UNIT),
            margin: marginWei,
            entryPrice: entryPriceWei,
            exitPrice: wei(exitPrice || 0).div(ETH_UNIT),
            pnl: wei(pnl).div(ETH_UNIT),
            pnlWithFeesPaid: wei(pnlWithFeesPaid).div(ETH_UNIT),
            totalVolume: wei(totalVolume).div(ETH_UNIT),
            trades: trades.toNumber(),
            avgEntryPrice: wei(avgEntryPrice).div(ETH_UNIT),
            leverage: marginWei.eq(wei(0)) ? wei(0) : sizeWei.mul(entryPriceWei).div(marginWei).abs(),
            side: sizeWei.gte(wei(0)) ? PositionSide.LONG : PositionSide.SHORT,
        };
    });
};
// TODO: Move to app
export const serializePotentialTrade = (preview) => (Object.assign(Object.assign({}, preview), { size: preview.size.toString(), sizeDelta: preview.sizeDelta.toString(), liqPrice: preview.liqPrice.toString(), margin: preview.margin.toString(), price: preview.price.toString(), fee: preview.fee.toString(), leverage: preview.leverage.toString(), notionalValue: preview.notionalValue.toString(), priceImpact: preview.priceImpact.toString() }));
// TODO: Move to app
export const unserializePotentialTrade = (preview) => (Object.assign(Object.assign({}, preview), { size: wei(preview.size), sizeDelta: wei(preview.sizeDelta), liqPrice: wei(preview.liqPrice), margin: wei(preview.margin), price: wei(preview.price), fee: wei(preview.fee), leverage: wei(preview.leverage), notionalValue: wei(preview.notionalValue), priceImpact: wei(preview.priceImpact) }));
export const formatDelayedOrder = (account, marketAddress, order) => {
    const { isOffchain, sizeDelta, desiredFillPrice, targetRoundId, commitDeposit, keeperDeposit, executableAtTime, intentionTime, } = order;
    return {
        account: account,
        marketAddress: marketAddress,
        size: wei(sizeDelta),
        commitDeposit: wei(commitDeposit),
        keeperDeposit: wei(keeperDeposit),
        submittedAtTimestamp: intentionTime.toNumber() * 1000,
        executableAtTimestamp: executableAtTime.toNumber() * 1000,
        isOffchain: isOffchain,
        desiredFillPrice: wei(desiredFillPrice),
        targetRoundId: wei(targetRoundId),
        orderType: isOffchain ? 'Delayed Market' : 'Delayed',
        side: wei(sizeDelta).gt(0) ? PositionSide.LONG : PositionSide.SHORT,
    };
};
export const formatPotentialTrade = (preview, skewAdjustedPrice, nativeSizeDelta, leverageSide) => {
    const { fee, liqPrice, margin, price, size, status } = preview;
    console.log("formatPotentialTrade ==> price");
    console.log(Number(price));
    console.log("formatPotentialTrade ==> skewAdjustedPrice");
    console.log(Number(skewAdjustedPrice));
    //skewAdjustedPrice = skewAdjustedPrice.mul(1e27);
    console.log("formatPotentialTrade ==> skewAdjustedPrice");
    console.log(Number(skewAdjustedPrice));
    const notionalValue = wei(size).mul(wei(price));
    console.log("formatPotentialTrade ==> notionalValue");
    console.log(notionalValue);
    const leverage = margin.gt(0) ? notionalValue.div(wei(margin)) : ZERO_WEI;
    console.log("formatPotentialTrade ==> leverage");
    console.log(leverage);
    console.log("formatPotentialTrade ==> size");
    console.log(size);
    let priceMinusSkew = wei(price).sub(skewAdjustedPrice);
    console.log("formatPotentialTrade ==> priceMinusSkew");
    console.log(Number(priceMinusSkew));
    const priceImpact = priceMinusSkew.div(skewAdjustedPrice).abs().div(1e18); //TODO: review price Impact
    console.log("formatPotentialTrade ==> priceImpact");
    console.log(priceImpact);
    return {
        fee: wei(fee),
        liqPrice: wei(liqPrice),
        margin: wei(margin),
        price: wei(price),
        size: wei(size),
        sizeDelta: nativeSizeDelta,
        side: leverageSide,
        leverage: leverage,
        notionalValue: notionalValue,
        status,
        showStatus: status > 0,
        statusMessage: getTradeStatusMessage(status),
        priceImpact: priceImpact,
        exceedsPriceProtection: priceImpact.mul(100).gt(getDefaultPriceImpact('market')),
    };
};
const SUCCESS = 'Success';
const UNKNOWN = 'Unknown';
export const getTradeStatusMessage = (status) => {
    if (typeof status !== 'number') {
        return UNKNOWN;
    }
    if (status === 0) {
        return SUCCESS;
    }
    else if (PotentialTradeStatus[status]) {
        return POTENTIAL_TRADE_STATUS_TO_MESSAGE[PotentialTradeStatus[status]];
    }
    else {
        return UNKNOWN;
    }
};
// https://github.com/Synthetixio/synthetix/blob/4d2add4f74c68ac4f1106f6e7be4c31d4f1ccc76/contracts/PerpsV2MarketBase.sol#L130-L141
export const POTENTIAL_TRADE_STATUS_TO_MESSAGE = {
    OK: 'Ok',
    INVALID_PRICE: 'Invalid price',
    INVALID_ORDER_PRICE: 'Invalid order price',
    PRICE_OUT_OF_BOUNDS: 'Price out of acceptable range',
    CAN_LIQUIDATE: 'Position can be liquidated',
    CANNOT_LIQUIDATE: 'Position cannot be liquidated',
    MAX_MARKET_SIZE_EXCEEDED: 'Open interest limit exceeded',
    MAX_LEVERAGE_EXCEEDED: 'Max leverage exceeded (larger positions have lower max leverage)',
    INSUFFICIENT_MARGIN: 'Insufficient margin',
    NOT_PERMITTED: 'Not permitted by this address',
    NO_POSITION_OPEN: 'No position open',
    PRICE_TOO_VOLATILE: 'Price too volatile',
    PRICE_IMPACT_TOLERANCE_EXCEEDED: 'Price impact tolerance exceeded',
    INSUFFICIENT_FREE_MARGIN: `You don't have enough sETH for this trade`,
};
export const getPythNetworkUrl = (networkId, server = 'KWENTA') => {
    //TODO: check this logic
    const defaultPythServer = server === 'KWENTA' ? KWENTA_PYTH_SERVER : PUBLIC_PYTH_SERVER;
    return networkId === 420 ? 'https://xc-testnet.pyth.network' : defaultPythServer;
};
export const normalizePythId = (id) => (id.startsWith('0x') ? id : '0x' + id);
export const mapConditionalOrderFromContract = (orderDetails, account) => {
    var _a;
    const marketKey = parseBytes32String(orderDetails.marketKey);
    const asset = MarketAssetByKey[marketKey];
    const sizeDelta = wei(orderDetails.sizeDelta);
    const size = sizeDelta.abs();
    return {
        id: orderDetails.id,
        subgraphId: `CM-${account}-${orderDetails.id}`,
        account: account,
        size: sizeDelta,
        marginDelta: wei(orderDetails.marginDelta),
        orderType: orderDetails.conditionalOrderType,
        orderTypeDisplay: formatOrderDisplayType(orderDetails.conditionalOrderType, orderDetails.reduceOnly),
        // TODO: Rename when ABI is updated
        desiredFillPrice: wei(orderDetails.desiredFillPrice),
        targetPrice: wei(orderDetails.targetPrice),
        reduceOnly: orderDetails.reduceOnly,
        sizeTxt: size.abs().eq(SL_TP_MAX_SIZE)
            ? 'Close'
            : formatCurrency(asset, size, {
                currencyKey: (_a = getDisplayAsset(asset)) !== null && _a !== void 0 ? _a : '',
                minDecimals: size.lt(0.01) ? 4 : 2,
            }),
        targetPriceTxt: formatDollars(wei(orderDetails.targetPrice)),
        marketKey: marketKey,
        market: getMarketName(asset),
        asset: asset,
        side: sizeDelta.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
        isStale: false,
        isExecutable: false,
        isSlTp: size.eq(SL_TP_MAX_SIZE),
    };
};
export const OrderNameByType = {
    market: 'Market',
    stop_market: 'Stop',
    limit: 'Limit',
};
const mapOrderType = (orderType) => {
    return orderType === 'NextPrice'
        ? 'Next Price'
        : orderType === 'StopMarket'
            ? 'Stop'
            : orderType === 'DelayedOffchain'
                ? 'Delayed Market'
                : orderType;
};
export const mapTrades = (futuresTrades) => {
    return futuresTrades.map(({ id, timestamp, account, margin, size, price, asset, positionId, positionSize, positionClosed, pnl, feesPaid, keeperFeesPaid, orderType, accountType, fundingAccrued }) => {
        return {
            asset: parseBytes32String(asset),
            account,
            accountType,
            margin: new Wei(margin, 18, true),
            size: new Wei(size, 18, true),
            price: new Wei(price, 18, true),
            txnHash: id.split('-')[0].toString(),
            timestamp: timestamp.toNumber(),
            positionId,
            positionSize: new Wei(positionSize, 18, true),
            positionClosed,
            side: size.gt(0) ? PositionSide.LONG : PositionSide.SHORT,
            pnl: new Wei(pnl, 18, true),
            feesPaid: new Wei(feesPaid, 18, true),
            keeperFeesPaid: new Wei(keeperFeesPaid, 18, true),
            orderType: mapOrderType(orderType),
            fundingAccrued: new Wei(fundingAccrued, 18, true),
        };
    });
};
export const mapMarginTransfers = (marginTransfers) => {
    return marginTransfers.map(({ timestamp, account, market, size, asset, txHash }) => {
        const sizeWei = new Wei(size);
        const numTimestamp = wei(timestamp).toNumber();
        return {
            timestamp: numTimestamp,
            account,
            market,
            size: sizeWei.div(ETH_UNIT).toNumber(),
            action: sizeWei.gt(0) ? 'deposit' : 'withdraw',
            asset: parseBytes32String(asset),
            txHash,
        };
    });
};
export const mapSmartMarginTransfers = (marginTransfers) => {
    return marginTransfers.map(({ timestamp, account, size, txHash }) => {
        const sizeWei = new Wei(size);
        const numTimestamp = wei(timestamp).toNumber();
        return {
            timestamp: numTimestamp,
            account,
            size: sizeWei.div(ETH_UNIT).toNumber(),
            action: sizeWei.gt(0) ? 'deposit' : 'withdraw',
            txHash,
        };
    });
};
export const encodeConditionalOrderParams = (marketKey, tradeInputs, type, reduceOnly) => {
    return defaultAbiCoder.encode(['bytes32', 'int256', 'int256', 'uint256', 'uint256', 'uint256', 'bool'], [
        formatBytes32String(marketKey),
        tradeInputs.marginDelta.toBN(),
        tradeInputs.sizeDelta.toBN(),
        tradeInputs.price.toBN(),
        type,
        tradeInputs.desiredFillPrice.toBN(),
        reduceOnly,
    ]);
};
export const encodeSubmitOffchainOrderParams = (marketAddress, sizeDelta, desiredFillPrice) => {
    return defaultAbiCoder.encode(['address', 'int256', 'uint256'], [marketAddress, sizeDelta.toBN(), desiredFillPrice.toBN()]);
};
export const encodeCloseOffchainOrderParams = (marketAddress, desiredFillPrice) => {
    return defaultAbiCoder.encode(['address', 'uint256'], [marketAddress, desiredFillPrice.toBN()]);
};
export const encodeSubmitOrderParams = (marketAddress, sizeDelta, desiredTimeDelta, desiredFillPrice) => {
    //let parsedFillPrice = parseUnits(desiredFillPrice.toString(),18);
    console.log("encodeSubmitOrderParams ==> parsedFillPrice3");
    //console.log(parsedFillPrice);
    //let modifiedDelta = parseUnits(sizeDelta.toString(),18);
    console.log("encodeSubmitOrderParams ==> sizeDelta");
    console.log(sizeDelta);
    console.log("encodeSubmitOrderParams ==> desiredFillPrice");
    console.log(desiredFillPrice);
    //desiredFillPrice = desiredFillPrice.mul(1e9);
    //desiredFillPrice = desiredFillPrice.mul(1e10);
    return defaultAbiCoder.encode(['address', 'int256', 'uint256', 'uint256'], 
    //[marketAddress, sizeDelta.toBN(), BigNumber.from(desiredTimeDelta), BigNumber.from("10000000000000")]
    [marketAddress, sizeDelta.toBN(), BigNumber.from(desiredTimeDelta), desiredFillPrice.toBN()]);
};
export const encodeCloseOrderParams = (marketAddress, desiredTimeDelta, desiredFillPrice) => {
    /*
    desiredFillPrice = desiredFillPrice.mul(1e9);
    let parsedFillPrice = parseUnits(desiredFillPrice.toString(),26);
    //return defaultAbiCoder.encode(['address', 'uint256', 'uint256'], [marketAddress, BigNumber.from(desiredTimeDelta), BigNumber.from("1998003101636393808717")])
    return defaultAbiCoder.encode(['address', 'uint256', 'uint256'], [marketAddress, BigNumber.from(desiredTimeDelta), parsedFillPrice.toBigInt()])
    */
    desiredFillPrice = desiredFillPrice.mul(1e9).mul(10);
    return defaultAbiCoder.encode(['address', 'uint256', 'uint256'], [marketAddress, BigNumber.from(desiredTimeDelta), desiredFillPrice.toBN()]);
};
export const encodeModidyMarketMarginParams = (marketAddress, marginDelta) => {
    console.log("encodeModidyMarketMarginParams ==> marginDelta");
    console.log(marginDelta);
    let encodedString = defaultAbiCoder.encode(['address', 'int256'], [marketAddress, marginDelta.toBN()]);
    console.log("encodeModidyMarketMarginParams ==> encodedString");
    console.log(encodedString);
    return encodedString;
};
export const formatOrderDisplayType = (orderType, reduceOnly) => {
    if (reduceOnly) {
        return orderType === ConditionalOrderTypeEnum.LIMIT ? 'Take Profit' : 'Stop Loss';
    }
    return orderType === ConditionalOrderTypeEnum.LIMIT ? 'Limit' : 'Stop';
};
export const calculateDesiredFillPrice = (sizeDelta, marketPrice, priceImpactPercent) => {
    console.log("calculateDesiredFillPrice ==> sizeDelta");
    console.log(sizeDelta);
    let priceImpactDecimalPct = priceImpactPercent.div(100);
    priceImpactDecimalPct = priceImpactDecimalPct.mul(10);
    //const priceImpactDecimalPct = priceImpactPercent.div(10)
    return sizeDelta.lt(0)
        ? marketPrice.mul(wei(1).sub(priceImpactDecimalPct))
        : marketPrice.mul(priceImpactDecimalPct.add(1));
};
export const getDefaultPriceImpact = (orderType) => {
    switch (orderType) {
        case 'market':
            return wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.MARKET);
        case 'limit':
            return wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.LIMIT);
        case 'stop_market':
            return wei(DEFAULT_PRICE_IMPACT_DELTA_PERCENT.STOP);
    }
};
// Returns the max leverage without buffer
export const appAdjustedLeverage = (marketLeverage) => {
    if (marketLeverage.gte(APP_MAX_LEVERAGE))
        return APP_MAX_LEVERAGE;
    return marketLeverage;
};
export const MarketAssetByKey = {
    [FuturesMarketKey.sETHPERP]: FuturesMarketAsset.sETH,
    [FuturesMarketKey.sGASPERP]: FuturesMarketAsset.sGAS,
    [FuturesMarketKey.sGAS1PERP]: FuturesMarketAsset.sGAS1,
    [FuturesMarketKey.sGAS2PERP]: FuturesMarketAsset.sGAS2,
    [FuturesMarketKey.sGAS3PERP]: FuturesMarketAsset.sGAS3,
    [FuturesMarketKey.sBTCPERP]: FuturesMarketAsset.sBTC,
    [FuturesMarketKey.sLINKPERP]: FuturesMarketAsset.LINK,
    [FuturesMarketKey.sSOLPERP]: FuturesMarketAsset.SOL,
    [FuturesMarketKey.sAVAXPERP]: FuturesMarketAsset.AVAX,
    [FuturesMarketKey.sAAVEPERP]: FuturesMarketAsset.AAVE,
    [FuturesMarketKey.sUNIPERP]: FuturesMarketAsset.UNI,
    [FuturesMarketKey.sMATICPERP]: FuturesMarketAsset.MATIC,
    [FuturesMarketKey.sXAUPERP]: FuturesMarketAsset.XAU,
    [FuturesMarketKey.sXAGPERP]: FuturesMarketAsset.XAG,
    [FuturesMarketKey.sEURPERP]: FuturesMarketAsset.EUR,
    [FuturesMarketKey.sAPEPERP]: FuturesMarketAsset.APE,
    [FuturesMarketKey.sDYDXPERP]: FuturesMarketAsset.DYDX,
    [FuturesMarketKey.sBNBPERP]: FuturesMarketAsset.BNB,
    [FuturesMarketKey.sDOGEPERP]: FuturesMarketAsset.DOGE,
    [FuturesMarketKey.sOPPERP]: FuturesMarketAsset.OP,
    [FuturesMarketKey.sARBPERP]: FuturesMarketAsset.ARB,
    [FuturesMarketKey.sATOMPERP]: FuturesMarketAsset.ATOM,
    [FuturesMarketKey.sFTMPERP]: FuturesMarketAsset.FTM,
    [FuturesMarketKey.sNEARPERP]: FuturesMarketAsset.NEAR,
    [FuturesMarketKey.sFLOWPERP]: FuturesMarketAsset.FLOW,
    [FuturesMarketKey.sAXSPERP]: FuturesMarketAsset.AXS,
    [FuturesMarketKey.sAUDPERP]: FuturesMarketAsset.AUD,
    [FuturesMarketKey.sGBPPERP]: FuturesMarketAsset.GBP,
    [FuturesMarketKey.sAPTPERP]: FuturesMarketAsset.APT,
    [FuturesMarketKey.sLDOPERP]: FuturesMarketAsset.LDO,
    [FuturesMarketKey.sADAPERP]: FuturesMarketAsset.ADA,
    [FuturesMarketKey.sGMXPERP]: FuturesMarketAsset.GMX,
    [FuturesMarketKey.sFILPERP]: FuturesMarketAsset.FIL,
    [FuturesMarketKey.sLTCPERP]: FuturesMarketAsset.LTC,
    [FuturesMarketKey.sBCHPERP]: FuturesMarketAsset.BCH,
    [FuturesMarketKey.sSHIBPERP]: FuturesMarketAsset.SHIB,
    [FuturesMarketKey.sCRVPERP]: FuturesMarketAsset.CRV,
    [FuturesMarketKey.sSUIPERP]: FuturesMarketAsset.SUI,
    [FuturesMarketKey.sPEPEPERP]: FuturesMarketAsset.PEPE,
    [FuturesMarketKey.sBLURPERP]: FuturesMarketAsset.BLUR,
    [FuturesMarketKey.sXRPPERP]: FuturesMarketAsset.XRP,
    [FuturesMarketKey.sDOTPERP]: FuturesMarketAsset.DOT,
    [FuturesMarketKey.sFLOKIPERP]: FuturesMarketAsset.FLOKI,
    [FuturesMarketKey.sINJPERP]: FuturesMarketAsset.INJ,
    [FuturesMarketKey.sTRXPERP]: FuturesMarketAsset.TRX,
    [FuturesMarketKey.sSTETHPERP]: FuturesMarketAsset.STETH,
};
export const MarketKeyByAsset = {
    [FuturesMarketAsset.sETH]: FuturesMarketKey.sETHPERP,
    [FuturesMarketAsset.sGAS]: FuturesMarketKey.sGASPERP,
    [FuturesMarketAsset.sGAS1]: FuturesMarketKey.sGAS1PERP,
    [FuturesMarketAsset.sGAS2]: FuturesMarketKey.sGAS2PERP,
    [FuturesMarketAsset.sGAS3]: FuturesMarketKey.sGAS3PERP,
    [FuturesMarketAsset.sBTC]: FuturesMarketKey.sBTCPERP,
    [FuturesMarketAsset.LINK]: FuturesMarketKey.sLINKPERP,
    [FuturesMarketAsset.SOL]: FuturesMarketKey.sSOLPERP,
    [FuturesMarketAsset.AVAX]: FuturesMarketKey.sAVAXPERP,
    [FuturesMarketAsset.AAVE]: FuturesMarketKey.sAAVEPERP,
    [FuturesMarketAsset.UNI]: FuturesMarketKey.sUNIPERP,
    [FuturesMarketAsset.MATIC]: FuturesMarketKey.sMATICPERP,
    [FuturesMarketAsset.XAU]: FuturesMarketKey.sXAUPERP,
    [FuturesMarketAsset.XAG]: FuturesMarketKey.sXAGPERP,
    [FuturesMarketAsset.EUR]: FuturesMarketKey.sEURPERP,
    [FuturesMarketAsset.APE]: FuturesMarketKey.sAPEPERP,
    [FuturesMarketAsset.DYDX]: FuturesMarketKey.sDYDXPERP,
    [FuturesMarketAsset.BNB]: FuturesMarketKey.sBNBPERP,
    [FuturesMarketAsset.DOGE]: FuturesMarketKey.sDOGEPERP,
    [FuturesMarketAsset.OP]: FuturesMarketKey.sOPPERP,
    [FuturesMarketAsset.ARB]: FuturesMarketKey.sARBPERP,
    [FuturesMarketAsset.ATOM]: FuturesMarketKey.sATOMPERP,
    [FuturesMarketAsset.FTM]: FuturesMarketKey.sFTMPERP,
    [FuturesMarketAsset.NEAR]: FuturesMarketKey.sNEARPERP,
    [FuturesMarketAsset.FLOW]: FuturesMarketKey.sFLOWPERP,
    [FuturesMarketAsset.AXS]: FuturesMarketKey.sAXSPERP,
    [FuturesMarketAsset.AUD]: FuturesMarketKey.sAUDPERP,
    [FuturesMarketAsset.GBP]: FuturesMarketKey.sGBPPERP,
    [FuturesMarketAsset.APT]: FuturesMarketKey.sAPTPERP,
    [FuturesMarketAsset.LDO]: FuturesMarketKey.sLDOPERP,
    [FuturesMarketAsset.ADA]: FuturesMarketKey.sADAPERP,
    [FuturesMarketAsset.GMX]: FuturesMarketKey.sGMXPERP,
    [FuturesMarketAsset.FIL]: FuturesMarketKey.sFILPERP,
    [FuturesMarketAsset.LTC]: FuturesMarketKey.sLTCPERP,
    [FuturesMarketAsset.BCH]: FuturesMarketKey.sBCHPERP,
    [FuturesMarketAsset.SHIB]: FuturesMarketKey.sSHIBPERP,
    [FuturesMarketAsset.CRV]: FuturesMarketKey.sCRVPERP,
    [FuturesMarketAsset.SUI]: FuturesMarketKey.sSUIPERP,
    [FuturesMarketAsset.PEPE]: FuturesMarketKey.sPEPEPERP,
    [FuturesMarketAsset.BLUR]: FuturesMarketKey.sBLURPERP,
    [FuturesMarketAsset.XRP]: FuturesMarketKey.sXRPPERP,
    [FuturesMarketAsset.DOT]: FuturesMarketKey.sDOTPERP,
    [FuturesMarketAsset.FLOKI]: FuturesMarketKey.sFLOKIPERP,
    [FuturesMarketAsset.INJ]: FuturesMarketKey.sINJPERP,
    [FuturesMarketAsset.TRX]: FuturesMarketKey.sTRXPERP,
    [FuturesMarketAsset.STETH]: FuturesMarketKey.sSTETHPERP,
};
export const AssetDisplayByAsset = {
    [FuturesMarketAsset.sETH]: 'Ether',
    [FuturesMarketAsset.sGAS]: '1 Block',
    [FuturesMarketAsset.sGAS1]: '1 Minute',
    [FuturesMarketAsset.sGAS2]: '1 Hour',
    [FuturesMarketAsset.sGAS3]: '3 Hours',
    [FuturesMarketAsset.sBTC]: 'Bitcoin',
    [FuturesMarketAsset.LINK]: 'Chainlink',
    [FuturesMarketAsset.SOL]: 'Solana',
    [FuturesMarketAsset.AVAX]: 'Avalanche',
    [FuturesMarketAsset.AAVE]: 'Aave',
    [FuturesMarketAsset.UNI]: 'Uniswap',
    [FuturesMarketAsset.MATIC]: 'Polygon',
    [FuturesMarketAsset.XAU]: 'Gold',
    [FuturesMarketAsset.XAG]: 'Silver',
    [FuturesMarketAsset.EUR]: 'Euro',
    [FuturesMarketAsset.APE]: 'ApeCoin',
    [FuturesMarketAsset.DYDX]: 'DYDX',
    [FuturesMarketAsset.BNB]: 'Binance Coin',
    [FuturesMarketAsset.DOGE]: 'Dogecoin',
    [FuturesMarketAsset.OP]: 'Optimism',
    [FuturesMarketAsset.ARB]: 'Arbitrum',
    [FuturesMarketAsset.ATOM]: 'Cosmos',
    [FuturesMarketAsset.FTM]: 'Fantom',
    [FuturesMarketAsset.NEAR]: 'Near',
    [FuturesMarketAsset.FLOW]: 'Flow',
    [FuturesMarketAsset.AXS]: 'Axie Infinity',
    [FuturesMarketAsset.AUD]: 'Australian Dollar',
    [FuturesMarketAsset.GBP]: 'Pound Sterling',
    [FuturesMarketAsset.APT]: 'Aptos',
    [FuturesMarketAsset.LDO]: 'Lido',
    [FuturesMarketAsset.ADA]: 'Cardano',
    [FuturesMarketAsset.GMX]: 'GMX',
    [FuturesMarketAsset.FIL]: 'Filecoin',
    [FuturesMarketAsset.LTC]: 'Litecoin',
    [FuturesMarketAsset.BCH]: 'Bitcoin Cash',
    [FuturesMarketAsset.SHIB]: 'Shiba Inu',
    [FuturesMarketAsset.CRV]: 'Curve DAO',
    [FuturesMarketAsset.SUI]: 'Sui',
    [FuturesMarketAsset.PEPE]: 'Pepe',
    [FuturesMarketAsset.BLUR]: 'Blur',
    [FuturesMarketAsset.XRP]: 'XRP',
    [FuturesMarketAsset.DOT]: 'Polkadot',
    [FuturesMarketAsset.FLOKI]: 'Floki',
    [FuturesMarketAsset.INJ]: 'Injective',
    [FuturesMarketAsset.TRX]: 'Tron',
    [FuturesMarketAsset.STETH]: 'Lido Staked ETH',
};
export const marketOverrides = {};
