import Context from './context';
import ExchangeService from './services/exchange';
import FuturesService from './services/futures';
import KwentaTokenService from './services/kwentaToken';
import PricesService from './services/prices';
import SynthsService from './services/synths';
import SystemService from './services/system';
import TransactionsService from './services/transactions';
export default class KwentaSDK {
    constructor(context) {
        this.context = new Context(context);
        this.exchange = new ExchangeService(this);
        this.futures = new FuturesService(this);
        this.prices = new PricesService(this);
        this.synths = new SynthsService(this);
        this.transactions = new TransactionsService(this);
        this.kwentaToken = new KwentaTokenService(this);
        this.system = new SystemService(this);
    }
    setProvider(provider) {
        return this.context.setProvider(provider);
    }
    setSigner(signer) {
        return this.context.setSigner(signer);
    }
}
