const letterSpacing = {
	regular: 'normal',
	bold: 'normal',
	black: 'normal',
	mono: `-0.04rem`,
	monoBold: `-0.04rem`,
	compressedBlack: 'normal',
	compressedMedium: 'normal',
}

export default letterSpacing