const fontStyles = {
	regular: 'Inter, Helvetica, Arial, sans-serif',
	bold: `Inter, Helvetica, Arial, sans-serif`,
	black: 'Inter, Helvetica, Arial, sans-serif',
	mono: `'Azeret Mono',monospace`,
	monoBold: `'Azeret Mono',monospace`,
	compressedBlack: `GT-America-Compressed-Black`,
	compressedMedium: `GT-America-Compressed-Medium`,
}

export default fontStyles
