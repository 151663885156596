import KwentaSDK from '@kwenta/sdk'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import {
	createMigrate,
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	createTransform,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appReducer from './app/reducer'
import balancesReducer from './balances/reducer'
import { sdk } from './config'
import dashboardReducer from './dashboard/reducer'
import explorerReducer, { EXPLORER_INITIAL_STATE } from './explorer/reducer';
import earnReducer from './earn/reducer'
import exchangeReducer from './exchange/reducer'
import futuresReducer from './futures/reducer'
import homeReducer from './home/reducer'
import migrations from './migrations'
import preferencesReducer from './preferences/reducer'
import pricesReducer from './prices/reducer'
import stakingReducer from './staking/reducer'
import statsReducer from './stats/reducer'
import walletReducer from './wallet/reducer'
import marketReducer from './market/reducer'

const LOG_REDUX = false

// const saveSubsetFilter = createTransform(
// 	(inboundState: { [key: string]: any }, key: string | number) => {
// 	  if (key === 'explorer') {
// 		return {
// 			...EXPLORER_INITIAL_STATE,
// 			featuredProtocolList: inboundState?.featuredProtocolList ?? [],
// 			ensNames: inboundState?.ensNames ?? {},
// 		};
// 	  }
// 	  return inboundState;
// 	},
// 	(outboundState, key) => outboundState
//   );

// const persistConfig = {
// 	key: 'root1',
// 	storage,
// 	version: 50,
// 	blacklist: ['app', 'wallet', 'dashboard', 'explorer'],
// 	// transforms: [saveSubsetFilter],
// 	migrate: createMigrate(migrations, { debug: true }),
// }

const combinedReducers = combineReducers({
	app: appReducer,
	wallet: walletReducer,
	balances: balancesReducer,
	dashboard: dashboardReducer,
	explorer: explorerReducer,
	exchange: exchangeReducer,
	futures: futuresReducer,
	home: homeReducer,
	earn: earnReducer,
	staking: stakingReducer,
	preferenes: preferencesReducer,
	prices: pricesReducer,
	stats: statsReducer,
	market: marketReducer,
})

// const persistedReducer = persistReducer(persistConfig, combinedReducers)

const store = configureStore({
	reducer: combinedReducers,
	devTools: {
		maxAge: 50, // Sets maximum allowed actions to be stored on the history tree, the default is 50
	  },
	middleware: (getDefaultMiddleware) => {
		const baseMiddleware = getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
			thunk: { extraArgument: { sdk } },
		})
		return LOG_REDUX ? baseMiddleware.concat(logger) : baseMiddleware
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type ThunkConfig = {
	dispatch: AppDispatch
	state: RootState
	extra: { sdk: KwentaSDK }
}
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	{ sdk: KwentaSDK },
	AnyAction
>

export const persistor = persistStore(store)
export default store
