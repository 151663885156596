export var FuturesMarketKey;
(function (FuturesMarketKey) {
    FuturesMarketKey["sETHPERP"] = "sETHPERP";
    FuturesMarketKey["sGASPERP"] = "sGASPERP";
    FuturesMarketKey["sGAS1PERP"] = "sGAS1PERP";
    FuturesMarketKey["sGAS2PERP"] = "sGAS2PERP";
    FuturesMarketKey["sGAS3PERP"] = "sGAS3PERP";
    FuturesMarketKey["sBTCPERP"] = "sBTCPERP";
    FuturesMarketKey["sLINKPERP"] = "sLINKPERP";
    FuturesMarketKey["sSOLPERP"] = "sSOLPERP";
    FuturesMarketKey["sAVAXPERP"] = "sAVAXPERP";
    FuturesMarketKey["sAAVEPERP"] = "sAAVEPERP";
    FuturesMarketKey["sUNIPERP"] = "sUNIPERP";
    FuturesMarketKey["sMATICPERP"] = "sMATICPERP";
    FuturesMarketKey["sXAUPERP"] = "sXAUPERP";
    FuturesMarketKey["sXAGPERP"] = "sXAGPERP";
    FuturesMarketKey["sEURPERP"] = "sEURPERP";
    FuturesMarketKey["sAPEPERP"] = "sAPEPERP";
    FuturesMarketKey["sDYDXPERP"] = "sDYDXPERP";
    FuturesMarketKey["sBNBPERP"] = "sBNBPERP";
    FuturesMarketKey["sDOGEPERP"] = "sDOGEPERP";
    FuturesMarketKey["sOPPERP"] = "sOPPERP";
    FuturesMarketKey["sARBPERP"] = "sARBPERP";
    FuturesMarketKey["sATOMPERP"] = "sATOMPERP";
    FuturesMarketKey["sFTMPERP"] = "sFTMPERP";
    FuturesMarketKey["sNEARPERP"] = "sNEARPERP";
    FuturesMarketKey["sFLOWPERP"] = "sFLOWPERP";
    FuturesMarketKey["sAXSPERP"] = "sAXSPERP";
    FuturesMarketKey["sAUDPERP"] = "sAUDPERP";
    FuturesMarketKey["sGBPPERP"] = "sGBPPERP";
    FuturesMarketKey["sADAPERP"] = "sADAPERP";
    FuturesMarketKey["sAPTPERP"] = "sAPTPERP";
    FuturesMarketKey["sBCHPERP"] = "sBCHPERP";
    FuturesMarketKey["sCRVPERP"] = "sCRVPERP";
    FuturesMarketKey["sFILPERP"] = "sFILPERP";
    FuturesMarketKey["sGMXPERP"] = "sGMXPERP";
    FuturesMarketKey["sLDOPERP"] = "sLDOPERP";
    FuturesMarketKey["sLTCPERP"] = "sLTCPERP";
    FuturesMarketKey["sSHIBPERP"] = "sSHIBPERP";
    FuturesMarketKey["sSUIPERP"] = "sSUIPERP";
    FuturesMarketKey["sPEPEPERP"] = "sPEPEPERP";
    FuturesMarketKey["sBLURPERP"] = "sBLURPERP";
    FuturesMarketKey["sXRPPERP"] = "sXRPPERP";
    FuturesMarketKey["sDOTPERP"] = "sDOTPERP";
    FuturesMarketKey["sTRXPERP"] = "sTRXPERP";
    FuturesMarketKey["sFLOKIPERP"] = "sFLOKIPERP";
    FuturesMarketKey["sINJPERP"] = "sINJPERP";
    FuturesMarketKey["sSTETHPERP"] = "sSTETHPERP";
})(FuturesMarketKey || (FuturesMarketKey = {}));
export var FuturesMarketAsset;
(function (FuturesMarketAsset) {
    FuturesMarketAsset["sETH"] = "sETH";
    FuturesMarketAsset["sGAS"] = "sGAS";
    FuturesMarketAsset["sGAS1"] = "sGAS1";
    FuturesMarketAsset["sGAS2"] = "sGAS2";
    FuturesMarketAsset["sGAS3"] = "sGAS3";
    FuturesMarketAsset["sBTC"] = "sBTC";
    FuturesMarketAsset["LINK"] = "LINK";
    FuturesMarketAsset["SOL"] = "SOL";
    FuturesMarketAsset["AVAX"] = "AVAX";
    FuturesMarketAsset["AAVE"] = "AAVE";
    FuturesMarketAsset["UNI"] = "UNI";
    FuturesMarketAsset["MATIC"] = "MATIC";
    FuturesMarketAsset["XAU"] = "XAU";
    FuturesMarketAsset["XAG"] = "XAG";
    FuturesMarketAsset["EUR"] = "EUR";
    FuturesMarketAsset["APE"] = "APE";
    FuturesMarketAsset["DYDX"] = "DYDX";
    FuturesMarketAsset["BNB"] = "BNB";
    FuturesMarketAsset["DOGE"] = "DOGE";
    FuturesMarketAsset["OP"] = "OP";
    FuturesMarketAsset["ARB"] = "ARB";
    FuturesMarketAsset["ATOM"] = "ATOM";
    FuturesMarketAsset["FTM"] = "FTM";
    FuturesMarketAsset["NEAR"] = "NEAR";
    FuturesMarketAsset["FLOW"] = "FLOW";
    FuturesMarketAsset["AXS"] = "AXS";
    FuturesMarketAsset["AUD"] = "AUD";
    FuturesMarketAsset["GBP"] = "GBP";
    FuturesMarketAsset["ADA"] = "ADA";
    FuturesMarketAsset["APT"] = "APT";
    FuturesMarketAsset["BCH"] = "BCH";
    FuturesMarketAsset["CRV"] = "CRV";
    FuturesMarketAsset["FIL"] = "FIL";
    FuturesMarketAsset["GMX"] = "GMX";
    FuturesMarketAsset["LDO"] = "LDO";
    FuturesMarketAsset["LTC"] = "LTC";
    FuturesMarketAsset["SHIB"] = "SHIB";
    FuturesMarketAsset["SUI"] = "SUI";
    FuturesMarketAsset["PEPE"] = "PEPE";
    FuturesMarketAsset["BLUR"] = "BLUR";
    FuturesMarketAsset["XRP"] = "XRP";
    FuturesMarketAsset["DOT"] = "DOT";
    FuturesMarketAsset["TRX"] = "TRX";
    FuturesMarketAsset["FLOKI"] = "FLOKI";
    FuturesMarketAsset["INJ"] = "INJ";
    FuturesMarketAsset["STETH"] = "STETH";
})(FuturesMarketAsset || (FuturesMarketAsset = {}));
export var PositionSide;
(function (PositionSide) {
    PositionSide["LONG"] = "long";
    PositionSide["SHORT"] = "short";
})(PositionSide || (PositionSide = {}));
export var ContractOrderType;
(function (ContractOrderType) {
    ContractOrderType[ContractOrderType["MARKET"] = 0] = "MARKET";
    ContractOrderType[ContractOrderType["DELAYED"] = 1] = "DELAYED";
    ContractOrderType[ContractOrderType["DELAYED_OFFCHAIN"] = 2] = "DELAYED_OFFCHAIN";
})(ContractOrderType || (ContractOrderType = {}));
export const OrderEnumByType = {
    market: ContractOrderType.MARKET,
    delayed: ContractOrderType.DELAYED,
    delayed_offchain: ContractOrderType.DELAYED_OFFCHAIN,
};
export var ConditionalOrderTypeEnum;
(function (ConditionalOrderTypeEnum) {
    ConditionalOrderTypeEnum[ConditionalOrderTypeEnum["LIMIT"] = 0] = "LIMIT";
    ConditionalOrderTypeEnum[ConditionalOrderTypeEnum["STOP"] = 1] = "STOP";
})(ConditionalOrderTypeEnum || (ConditionalOrderTypeEnum = {}));
// https://github.com/Synthetixio/synthetix/blob/4d2add4f74c68ac4f1106f6e7be4c31d4f1ccc76/contracts/interfaces/IFuturesMarketBaseTypes.sol#L6-L19
export var PotentialTradeStatus;
(function (PotentialTradeStatus) {
    // Contract status mapping
    PotentialTradeStatus[PotentialTradeStatus["OK"] = 0] = "OK";
    PotentialTradeStatus[PotentialTradeStatus["INVALID_PRICE"] = 1] = "INVALID_PRICE";
    PotentialTradeStatus[PotentialTradeStatus["INVALID_ORDER_PRICE"] = 2] = "INVALID_ORDER_PRICE";
    PotentialTradeStatus[PotentialTradeStatus["PRICE_OUT_OF_BOUNDS"] = 3] = "PRICE_OUT_OF_BOUNDS";
    PotentialTradeStatus[PotentialTradeStatus["CAN_LIQUIDATE"] = 4] = "CAN_LIQUIDATE";
    PotentialTradeStatus[PotentialTradeStatus["CANNOT_LIQUIDATE"] = 5] = "CANNOT_LIQUIDATE";
    PotentialTradeStatus[PotentialTradeStatus["MAX_MARKET_SIZE_EXCEEDED"] = 6] = "MAX_MARKET_SIZE_EXCEEDED";
    PotentialTradeStatus[PotentialTradeStatus["MAX_LEVERAGE_EXCEEDED"] = 7] = "MAX_LEVERAGE_EXCEEDED";
    PotentialTradeStatus[PotentialTradeStatus["INSUFFICIENT_MARGIN"] = 8] = "INSUFFICIENT_MARGIN";
    PotentialTradeStatus[PotentialTradeStatus["NOT_PERMITTED"] = 9] = "NOT_PERMITTED";
    PotentialTradeStatus[PotentialTradeStatus["NIL_ORDER"] = 10] = "NIL_ORDER";
    PotentialTradeStatus[PotentialTradeStatus["NO_POSITION_OPEN"] = 11] = "NO_POSITION_OPEN";
    PotentialTradeStatus[PotentialTradeStatus["PRICE_TOO_VOLATILE"] = 12] = "PRICE_TOO_VOLATILE";
    PotentialTradeStatus[PotentialTradeStatus["PRICE_IMPACT_TOLERANCE_EXCEEDED"] = 13] = "PRICE_IMPACT_TOLERANCE_EXCEEDED";
    // Our own local status
    PotentialTradeStatus[PotentialTradeStatus["INSUFFICIENT_FREE_MARGIN"] = 100] = "INSUFFICIENT_FREE_MARGIN";
})(PotentialTradeStatus || (PotentialTradeStatus = {}));
export var AccountExecuteFunctions;
(function (AccountExecuteFunctions) {
    AccountExecuteFunctions[AccountExecuteFunctions["ACCOUNT_MODIFY_MARGIN"] = 0] = "ACCOUNT_MODIFY_MARGIN";
    AccountExecuteFunctions[AccountExecuteFunctions["ACCOUNT_WITHDRAW_ETH"] = 1] = "ACCOUNT_WITHDRAW_ETH";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_MODIFY_MARGIN"] = 2] = "PERPS_V2_MODIFY_MARGIN";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_WITHDRAW_ALL_MARGIN"] = 3] = "PERPS_V2_WITHDRAW_ALL_MARGIN";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_SUBMIT_ATOMIC_ORDER"] = 4] = "PERPS_V2_SUBMIT_ATOMIC_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_SUBMIT_DELAYED_ORDER"] = 5] = "PERPS_V2_SUBMIT_DELAYED_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_SUBMIT_OFFCHAIN_DELAYED_ORDER"] = 6] = "PERPS_V2_SUBMIT_OFFCHAIN_DELAYED_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_CLOSE_POSITION"] = 7] = "PERPS_V2_CLOSE_POSITION";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_SUBMIT_CLOSE_DELAYED_ORDER"] = 8] = "PERPS_V2_SUBMIT_CLOSE_DELAYED_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_SUBMIT_CLOSE_OFFCHAIN_DELAYED_ORDER"] = 9] = "PERPS_V2_SUBMIT_CLOSE_OFFCHAIN_DELAYED_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_CANCEL_DELAYED_ORDER"] = 10] = "PERPS_V2_CANCEL_DELAYED_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["PERPS_V2_CANCEL_OFFCHAIN_DELAYED_ORDER"] = 11] = "PERPS_V2_CANCEL_OFFCHAIN_DELAYED_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["GELATO_PLACE_CONDITIONAL_ORDER"] = 12] = "GELATO_PLACE_CONDITIONAL_ORDER";
    AccountExecuteFunctions[AccountExecuteFunctions["GELATO_CANCEL_CONDITIONAL_ORDER"] = 13] = "GELATO_CANCEL_CONDITIONAL_ORDER";
})(AccountExecuteFunctions || (AccountExecuteFunctions = {}));
