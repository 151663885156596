import EthIcon from "assets/svg/providers/ethereum.svg";
import ZkSyncIcon from "assets/svg/providers/zksync.svg";
import OptimismIcon from "assets/svg/providers/optimism.svg";
import ArbitrumIcon from "assets/svg/providers/arbitrum.svg";

export const EXPLORER_NETWORK_SELECTOR_OPTIONS = [
    {
        value: "ethereum",
        label: "Ethereum",
        icon: EthIcon
    },
    {
        value: "zksync",
        label: "zkSync",
        icon: ZkSyncIcon,
        disabled: true
    },
    {
        value: "optimism",
        label: "Optimism",
        icon: OptimismIcon,
        disabled: true
    },
    {
        value: "arbitrum",
        label: "Arbitrum",
        icon: ArbitrumIcon,
        disabled: true
    }
]

export enum Environment {
    Beta = "beta",
    Local = "localhost",
    AppTest = "apptest",
    App = "app"
  }

export const CURRENT_ENV = process.env.NEXT_PUBLIC_ENVIRONMENT ?? Environment.Local