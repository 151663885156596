var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { parseBytes32String } from '@ethersproject/strings';
import { wei } from '@synthetixio/wei';
import { orderBy } from 'lodash';
import * as sdkErrors from '../common/errors';
import { ZERO_WEI } from '../constants/number';
import { notNill } from '../utils/general';
export default class SynthsService {
    constructor(sdk) {
        this.sdk = sdk;
    }
    getSynthBalances(walletAddress) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.sdk.context.contracts.SynthUtil) {
                throw new Error(sdkErrors.UNSUPPORTED_NETWORK);
            }
            const balancesMap = {};
            const [currencyKeys, synthsBalances, synthsUSDBalances,] = yield this.sdk.context.contracts.SynthUtil.synthsBalances(walletAddress);
            console.log("this.sdk.context.contracts.SynthUtil.address  ==> synthsUSDBalances : ");
            //console.log(synthsUSDBalances);
            let totalUSDBalance = wei(0);
            currencyKeys.forEach((currencyKeyBytes32, idx) => {
                const balance = wei(synthsBalances[idx]);
                if (balance.gt(0)) {
                    const synthName = parseBytes32String(currencyKeyBytes32);
                    const usdBalance = wei(synthsUSDBalances[idx]);
                    balancesMap[synthName] = { currencyKey: synthName, balance, usdBalance };
                    totalUSDBalance = totalUSDBalance.add(usdBalance);
                }
            });
            console.log("this.sdk.context.contracts.SynthUtil.address  ==> totalUSDBalance : ");
            console.log(totalUSDBalance);
            const balances = {
                balancesMap,
                balances: orderBy(Object.values(balancesMap).filter(notNill), (balance) => balance.usdBalance.toNumber(), 'desc'),
                totalUSDBalance,
                susdWalletBalance: (_b = (_a = balancesMap === null || balancesMap === void 0 ? void 0 : balancesMap['sUSD']) === null || _a === void 0 ? void 0 : _a.balance) !== null && _b !== void 0 ? _b : ZERO_WEI,
            };
            return balances;
        });
    }
}
