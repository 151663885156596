import { batch } from "@preact/signals-react";
import { LastMempoolData } from "sections/market/types";
import { lastMempoolDataSignal } from "signals/lastMempoolDataSignals";

let initialMempoolData: LastMempoolData[] | null = []

const messageHandler = (data: LastMempoolData) => {
    batch(() => {
        if (initialMempoolData !== null) {
            if (initialMempoolData.length < 12) {
                initialMempoolData.push(data as LastMempoolData)
                if (initialMempoolData.length === 12) {
                lastMempoolDataSignal.value = data as LastMempoolData;
                initialMempoolData = null;
                }
            }
        } else {
            lastMempoolDataSignal.value = data as LastMempoolData;
        }
    })
}

const reset = () => {
    initialMempoolData = []
}

export default {
    messageHandler,
    reset
}