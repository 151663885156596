export const ADDRESSES = {
    Exchanger: {
        300: '0x300a9Bbf9551612AAd30bf560ee4BF796cC7F49A',
    },
    SystemStatus: {
        300: '0xF7FB604F1F06849d00b1f868b75A3247FC71c99b',
    },
    ExchangeRates: {
        300: '0xD4b575Ba5bA5eb2C5Af1C657f03F29d0e982C4b2',
    },
    SynthUtil: {
        300: '0x9E2C5135E0E66458df5B8F3Bb29D9C0Cf346BE24',
    },
    SystemSettings: {
        300: '0x7540609a8903a7e3A77d9344cd0Fef99b24356c5',
    },
    SynthRedeemer: {
        300: '0x95c880D0b4A1961BB4A4b3ca9a50514c9861A096',
    },
    FuturesMarketData: {
        300: '0x2C753A79d09e78cc690A0C7F3a8207eF495d3a0D',
    },
    FuturesMarketSettings: {
        300: '0x08B3e12a994569b8a4aB1acAdd858484a36Da3b2',
    },
    PerpsV2MarketData: {
        300: '0x2C753A79d09e78cc690A0C7F3a8207eF495d3a0D',
    },
    PerpsV2MarketSettings: {
        300: '0x08B3e12a994569b8a4aB1acAdd858484a36Da3b2',
    },
    Pyth: {
        300: '0x7f844a5748161Db6025D8888423d5558E6CC420D',
    },
    SUSD: {
        300: '0x5E891212aA804427f74Bce4D226CBcd24b242f34',
    },
    Synthetix: {
        300: '0x0963e4899F2035e26D7Cb612EdD5D7c8C1eFE806',
    },
    SmartMarginAccount: {
        300: '0xFA468d48F5261Ac96F804e16e163921e13996233',
    },
    SmartMarginAccountFactory: {
        300: '0x5F3e422aDcF7B7427b237a62F0B3844907b1A5A7',
    },
    SmartMarginEvents: {
        300: '0xfd0EDeB6b130BfaE98D78b25E1fBdD375049D081',
    }
};
