import { API_ENDPOINT_PREFIX } from "../constants";
export const RATES_ENDPOINT_OP_MAINNET = `https://subgraph.satsuma-prod.com/${process.env.NEXT_PUBLIC_SATSUMA_API_KEY}/kwenta/optimism-latest-rates/api`;
export const RATES_ENDPOINT_OP_GOERLI = 'https://api.thegraph.com/thegraph/name/kwenta/optimism-goerli-latest-rates';
export const RATES_ENDPOINT_GOERLI = 'https://api.thegraph.com/subgraphs/name/kwenta/goerli-main';
export const RATES_ENDPOINT_ZKSYNC_DEV = 'http://localhost:8000/subgraphs/name/ethgas-team/infdev-zksync-main';
export const RATES_ENDPOINT_ZKSYNC_TESTNET = `https://${API_ENDPOINT_PREFIX}.ethgas.com/thegraph/subgraphs/name/ethgas-team/zksync-testnet-main`;
export const RATES_ENDPOINT_ZKSYNC_SEPOLIA = `https://${API_ENDPOINT_PREFIX}.ethgas.com/thegraph/subgraphs/name/ethgas-team/zksync-sepolia-main`;
export const RATES_ENDPOINTS = {
    5: RATES_ENDPOINT_GOERLI,
    10: RATES_ENDPOINT_OP_MAINNET,
    420: RATES_ENDPOINT_OP_GOERLI,
    31338: RATES_ENDPOINT_OP_GOERLI,
    270: RATES_ENDPOINT_ZKSYNC_DEV,
    280: RATES_ENDPOINT_ZKSYNC_TESTNET,
    300: RATES_ENDPOINT_ZKSYNC_SEPOLIA,
};
export const getRatesEndpoint = (networkId) => {
    return RATES_ENDPOINTS[networkId] || RATES_ENDPOINTS[300];
};
