import { Chain } from '@rainbow-me/rainbowkit';
import ZkSyncIcon from 'assets/png/rainbowkit/zksync.png'
 
export const inf_zksync_mainnet: Chain = {
  id: 31338,
  name: 'zkSync Mainnet (Coming Soon)',
  iconUrl: async () => ZkSyncIcon,
  network: 'infdev',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    // @ts-ignore
    public: { http: ['http://127.0.0.1:8545'] },
    default: { http: ['http://internal-dev-eth-node-kdfr9834.infinity.exchange:545'] satisfies string[]},
    //default: { http: ['http://127.0.0.1:8545'] satisfies string[]},
  },
  blockExplorers: {
    //etherscan: { name: 'Blockscout', url: 'http://localhost:4000' },
    default: { name: 'Blockscout', url: 'http://internal-dev-eth-node-kdfr9834.infinity.exchange:4000' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601,
    },
  },
  testnet: true
}