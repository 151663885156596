var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter } from 'events';
import { Provider as EthCallProvider } from 'ethcall';
import * as sdkErrors from './common/errors';
import { getContractsByNetwork, getMulticallContractsByNetwork, } from './contracts';
const DEFAULT_CONTEXT = {
    networkId: 10,
};
export default class Context {
    constructor(context) {
        this.multicallProvider = new EthCallProvider();
        this.events = new EventEmitter().setMaxListeners(100);
        this.context = Object.assign(Object.assign({}, DEFAULT_CONTEXT), context);
        if (context.provider) {
            this.multicallProvider.init(context.provider);
        }
        if (context.signer) {
            this.setSigner(context.signer);
        }
        this.contracts = getContractsByNetwork(context.networkId, context.provider);
        this.multicallContracts = getMulticallContractsByNetwork(context.networkId);
    }
    get networkId() {
        var _a;
        return (_a = this.context.networkId) !== null && _a !== void 0 ? _a : 10;
    }
    get provider() {
        return this.context.provider;
    }
    get signer() {
        if (!this.context.signer) {
            throw new Error(sdkErrors.NO_SIGNER);
        }
        return this.context.signer;
    }
    get walletAddress() {
        if (!this.context.walletAddress) {
            throw new Error(sdkErrors.NO_SIGNER);
        }
        return this.context.walletAddress;
    }
    get isL2() {
        return [10, 420, 31338, 270, 280, 300].includes(this.networkId);
    }
    get isMainnet() {
        return [1, 10, 31338, 270, 280, 300].includes(this.networkId);
    }
    setProvider(provider) {
        return __awaiter(this, void 0, void 0, function* () {
            this.context.provider = provider;
            this.multicallProvider.init(provider);
            const networkId = (yield provider.getNetwork()).chainId;
            this.setNetworkId(networkId);
            return networkId;
        });
    }
    setNetworkId(networkId) {
        console.log("david --> network id changed: " + networkId);
        this.context.networkId = networkId;
        this.contracts = getContractsByNetwork(networkId, this.provider);
        this.multicallContracts = getMulticallContractsByNetwork(networkId);
        this.events.emit('network_changed', { networkId: networkId });
    }
    setSigner(signer) {
        return __awaiter(this, void 0, void 0, function* () {
            this.context.walletAddress = yield signer.getAddress();
            this.context.signer = signer;
        });
    }
    logError(err, skipReport = false) {
        var _a, _b;
        return (_b = (_a = this.context).logError) === null || _b === void 0 ? void 0 : _b.call(_a, err, skipReport);
    }
}
