import { Chain } from '@rainbow-me/rainbowkit';
import ZkSyncIcon from 'assets/png/rainbowkit/zksync.png'

 
export const inf_zksync_testnet: Chain = {
  id: 270,
  name: 'zkSync Internal',
  network: 'infzksync',
  iconUrl: async () => ZkSyncIcon,
  iconBackground: '#000',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    //public: { http: ['http://127.0.0.1:8545'] },
    // @ts-ignore
    public: { http: ['http://43.198.154.126:3050'] },
    default: { http: ['http://43.198.154.126:3050'] satisfies string[]},
    //default: { http: ['http://127.0.0.1:8545'] satisfies string[]},
  },
  blockExplorers: {
    //etherscan: { name: 'Blockscout', url: 'http://localhost:4000' },
    default: { name: 'Blockscout', url: 'http://43.198.154.126:3050' },
  },
  contracts: {
    multicall3: {
      address: '0x6d6973Bf5054ce17EB3001914281190B7db1DB07',
      blockCreated: 751,
    },
  },
  testnet: true
}