import {
	handleWebsocketMessages,
	resetWebsocketStatus,
	TEventType,
} from './websocketMessageHandlers'

const WS_BASE = process.env.NEXT_PUBLIC_WEBSOCKET_ENDPOINT || 'wss://ws.analytics.ethgas.com'
// const WS_BASE = process.env.NEXT_PUBLIC_WEBSOCKET_ENDPOINT || "ws://localhost:36889";

const RECONNECT_INTERVAL = 10000 // 10 secs

let socket: WebSocket | undefined
const topicsCountMap: Map<string, number> = new Map()

export const startWebsocketService = () => {
	let reconnectInterval: NodeJS.Timer | undefined

	const connect = () => {
		socket = new WebSocket(WS_BASE)
		socket.onopen = () => {
			// console.log('[WebsocketContext] socket.onopen');
			resubscribeTopics()
			// reconnect attempts
			reconnectInterval = setInterval(() => {
				// console.log('reconnectInterval');
				if (!socket || socket?.readyState === socket?.CLOSED) {
					connect()
				}
			}, RECONNECT_INTERVAL)
		}
		socket.onclose = () => {
			// console.log('[WebsocketContext] socket.onclose')
			resetWebsocketStatus()
		}
		socket.onmessage = (event) => {
			handleWebsocketMessages([event.data])
		}
	}

	connect()

	// To close the connection at App dismount
	return {
		close: () => {
			if (socket) {
				socket.close()
			}
			if (reconnectInterval) {
				clearInterval(reconnectInterval)
			}
		},
	}
}

const getTopics = () =>
	Array.from(topicsCountMap.keys()).filter((k) => (topicsCountMap.get(k) || 0) > 0)

const resubscribeTopics = () => {
	const topics = getTopics()
	// console.log(`WebsocketProvider resubscribe: ${topics.length} topics`);
	if (topics.length > 0) {
		if (socket?.readyState === WebSocket.OPEN) {
			socket?.send(
				JSON.stringify({
					op: 'subscribe',
					args: topics.map((topic) => ({
						channel: topic,
					})),
				})
			)
		}
	}
}

export const subscribeToTopic = (topic: TEventType) => {
	topicsCountMap.set(topic, (topicsCountMap.get(topic) ?? 0) + 1)
	if (socket && socket?.readyState === socket.OPEN) {
		socket.send(JSON.stringify({ op: 'subscribe', args: [{ channel: topic }] }))
	}
}
